/* eslint-disable vue/no-unused-properties */

import { mapActions } from 'vuex'

export default {
  install (Vue) {
    Vue.mixin({
      methods: {
        ...mapActions('auth', [
          'EVALUATE_USER_ACTIONS',
        ]),
        async $evaluateUserActions (triggered = null, { routeName } = {}) {
          const meta = routeName
            ? this.$router.resolve({ name: routeName })?.route?.meta
            : this.$route?.meta
          const routeActions = Object.values(meta?.actions || {})
          const routeReqActions = meta?.requiredActions || []
          const actions = triggered || [
            ...routeActions,
            ...(_.isFunction(routeReqActions) ? routeReqActions(this.$store) : routeReqActions),
          ]

          if (_.$isEmpty(actions)) return
          await this.EVALUATE_USER_ACTIONS({ actions: _.isString(actions) ? [actions] : actions })
        },
      },
    })
  },
}
