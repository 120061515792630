export default {
  en: {
    documentationHelp: `Please refer to {0} to fill the following inputs. If you need help, please contact your system administrator.`,
    documentationHelpLink: 'Cycloid worker onprem documentation',
    troubleshoot: `If you encounter an issue during worker deployment, our {0} might help you.`,
    troubleshootLink: 'troubleshooting documentation',
    generatedCode: 'Generated code',
  },
  es: {
    documentationHelp: `Por favor, consulte {0} para completar las siguientes entradas. Si necesita ayuda, póngase en contacto con el administrador del sistema.`,
    documentationHelpLink: 'Documentación de Cycloid Worker onprem',
    troubleshoot: `Si encuentra un problema durante el despliegue del worker, nuestro {0} podría ayudarlo.`,
    troubleshootLink: 'documentación de solución de problemas',
    generatedCode: 'Código generado',
  },
  fr: {
    documentationHelp: `Veuillez vous référer à {0} pour remplir les champs suivants. Si vous avez besoin d'aide, contactez votre administrateur système.`,
    documentationHelpLink: 'Documentation Cycloid pour les workers onprem',
    troubleshoot: `Si vous rencontrez un problème lors du déploiement de vos workers, notre {0} peut vous aider.`,
    troubleshootLink: 'documentation de troubleshooting',
    generatedCode: 'Code généré',
  },
}
