export default {
  en: {
    addResource: 'Add @:resource',
    attribute: 'attribute',
    custom_resources: 'Custom resources',
    filterByKeyword: 'Filter by keyword',
    nItems: '<b>{n}</b> items | <b>{n}</b> item | <b>{n}</b> items',
    nResults: '<b>{n}</b> results | <b>{n}</b> result | <b>{n}</b> results',
    ofNItems: 'of <b>{n}</b> items | of <b>{n}</b> item | of <b>{n}</b> items',
    values: {
      attribute: {
        name: '@:name',
        id: 'ID',
        provider: '@:provider',
        category: 'Category',
        type: '@:forms.type',
        tags: '@:untranslated.tags',
        project_canonical: '@:Project',
        environment_canonical: '@:Environment',
        link: 'Link',
      },
    },
  },
  es: {
    addResource: 'Añadir @:resource',
    attribute: 'atributo',
    custom_resources: 'Recursos personalizados',
    filterByKeyword: 'Filtrar por palabra clave',
    nItems: '<b>{n}</b> elementos | <b>{n}</b> elemento | <b>{n}</b> elementos',
    nResults: '<b>{n}</b> resultados | <b>{n}</b> resultado | <b>{n}</b> resultados',
    ofNItems: 'de <b>{n}</b> elementos | de <b>{n}</b> elemento | de <b>{n}</b> elementos',
    values: {
      attribute: {
        name: '@:name',
        id: 'ID',
        provider: '@:provider',
        category: 'Categoría',
        type: '@:forms.type',
        tags: '@:untranslated.tags',
        project_canonical: '@:Project',
        environment_canonical: '@:Environment',
        link: 'Enlace',
      },
    },
  },
  fr: {
    addResource: 'Ajouter une @:resource',
    attribute: 'attribut',
    custom_resources: 'Ressources personnalisées',
    filterByKeyword: 'Filtrer par mot-clé',
    nItems: '<b>{n}</b> éléments | <b>{n}</b> élément | <b>{n}</b> éléments',
    nResults: '<b>{n}</b> résultats | <b>{n}</b> résultat | <b>{n}</b> résultats',
    ofNItems: 'de <b>{n}</b> éléments | de <b>{n}</b> élément | de <b>{n}</b> éléments',
    values: {
      attribute: {
        name: '@:name',
        id: 'ID',
        provider: '@:provider',
        category: 'Catégorie',
        type: '@:forms.type',
        tags: '@:untranslated.tags',
        project_canonical: '@:Project',
        environment_canonical: '@:Environment',
        link: 'Lien',
      },
    },
  },
}
