/* eslint-disable vue/no-unused-properties */

export default {
  install (Vue) {
    Vue.mixin({
      data: () => ({
        $originalData: {},
      }),
      created () {
        const { name = '' } = this.$options
        if (name.startsWith('Cy')) this.$setOriginalData()
      },
      methods: {
        $hasDataChanged (path) {
          const { $originalData, ...data } = this.$data
          return path
            ? !_.isEqual(_.$get(data, path), _.$get($originalData, path))
            : !_.isEqual(data, $originalData)
        },
        $setOriginalData (path) {
          const data = _.cloneDeep(this.$data)
          if (path) _.set(this.$data.$originalData, path, _.cloneDeep(_.cloneDeep(_.$get(data, path))))
          else {
            for (const key of _.without(_.keys(data), '$originalData')) {
              this.$data.$originalData[key] = _.cloneDeep(data[key])
            }
          }
        },
        $resetData (path) {
          const originalData = _.cloneDeep(_.$get(this.$data, '$originalData', this.$options.data.apply(this)))
          if (path) _.set(this.$data, path, _.cloneDeep(_.$get(originalData, path)))
          else {
            for (const key of _.without(_.keys(originalData), '$originalData')) {
              this.$data[key] = _.cloneDeep(originalData[key])
            }
          }
        },
      },
    })
  },
}
