export default {
  en: {
    ago: '{0} ago',
  },
  es: {
    ago: 'hace {0}',
  },
  fr: {
    ago: 'il y a {0}',
  },
}
