export default {
  organization: `Une organisation est l'entité racine de toutes les autres entités.`,
  'organization:api_key': `Les clés API sont utilisées pour accéder à l'organisation`,
  'organization:appearance': `Choisissez à quoi ressemblera Cycloid pour vos utilisateurs. Utilisez les couleurs et les logos de votre propre marque à afficher dans toute l'application.`,
  'organization:child': 'Autorisations pour accéder aux sous-organisations.',
  'organization:cloud_provider': 'Autorisations pour lister les fournisseurs de cloud.',
  'organization:config_repository': 'Un référentiel de configuration est un référentiel Git stockant la configuration des Stacks.',
  'organization:cloud_cost_management': `Autorisations d'accès à Cloud Cost Management pour explorer les coûts.`,
  'organization:cloud_cost_management_account': `Autorisations d'accès aux comptes Cloud Cost Management.`,
  'organization:cloud_cost_management_tag_mapping': `Autorisations d'accès au mappage de tags Cloud Cost Management.`,
  'organization:credential': `Autorisations pour gérer les informations d'identification de l'organisation.`,
  'organization:environment': 'Autorisations pour répertorier des informations sur les environnements à travers des projets.',
  'organization:external_backend': `Autorisations pour gérer l'accès aux services externes utilisés par Cycloid. Comme les logs des projets ou InfraView.`,
  'organization:infra_policy': `Les "InfraPolicies" sont des politiques d'entreprise applicables pour vos infrastructures cloud.`,
  'organization:inventory_resource': `Autorisations pour lister les ressources d'inventaire.`,
  'organization:invitation': `Autorisations pour gérer la possibilité d'inviter des utilisateurs dans votre organisation.`,
  'organization:kpi': 'Les KPI sont des métriques affichées par projet, utiles pour obtenir des informations sur vos environnements et les statuts des builds.',
  'organization:member': `Un membre est un utilisateur qui peut faire partie d'une ou plusieurs organisations.`,
  'organization:pipeline': 'Autorisations pour gérer les tâches de projets.',
  'organization:policy': 'Autorisations pour énumérer les politiques disponibles.',
  'organization:project': `Autorisations pour gérer l'accès à des projets. Un projet est une collection d'environnements créés autour d'une seule Stack.`,
  'organization:quota': `Les quotas sont un outil pour limiter l'utilisation des ressources (bare metal) lors du déploiement d'environnements.`,
  'organization:resource_pool': `Les pools de ressources sont des abstractions logiques utilisées pour regrouper les ressources par tag, vous n'avez donc pas à créer de quotas pour chaque serveur dont vous disposez.`,
  'organization:role': 'Autorisations pour gérer les rôles contenant un ensemble de politiques.',
  'organization:service_catalog_source': 'Les référentiels de catalogue les plus couramment nommés, ce sont des référentiels git qui contiennent des Stacks pour votre organisation.',
  'organization:service_catalog': 'Les Stacks les plus couramment nommées, ce sont des "modèles" utilisés pour décrire les processus et les infrastructures.',
  'organization:team:member': `Autorisations pour gérer les utilisateurs à l'intérieur des @:(teams).`,
  'organization:team': `Les @:teams sont des groupes d'utilisateurs où des autorisations spécifiques peuvent être accordés.`,
}
