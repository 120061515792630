const assignOwner = {
  readMembers: 'GetOrgMembers',
  readOwner: 'GetOrgMember',
}

export const actionsWithResources = {
  organization: {
    dashboard: {
      requiredActions: (store) => {
        return store.getters['customers/isInCustomerScope']
          ? ['GetChildren']
          : ['GetOrgs']
      },
      actions: {
        readCCM: 'GetCloudCostManagement',
        readCredentials: 'ListCredentials',
        readEvents: 'GetEvents',
        readKPIs: 'GetKPIs',
        readPipelines: 'GetPipelines',
        readProjects: 'GetProjects',
        readStacks: 'GetServiceCatalogSources',
      },
    },
    settings: {
      index: {
        requiredActions: ['GetOrg'],
        actions: {
          activateLicence: 'ActivateLicence',
          create: 'CreateOrg',
          createAppearance: 'CreateAppearance',
          delete: 'DeleteOrg',
          read: 'GetOrg',
          readAppearances: 'ListAppearances',
          readChildOrgs: 'GetChildren',
          readExternalBackends: 'GetExternalBackends',
          readLicence: 'GetLicence',
          readWorkers: 'GetWorkers',
          update: 'UpdateOrg',
          updateAppearance: 'UpdateAppearance',
        },
      },
      appearance: {
        create: {
          requiredActions: ['CreateAppearance'],
          actions: {
            create: 'CreateAppearance',
            delete: 'DeleteAppearance',
            read: 'GetAppearance',
            update: 'UpdateAppearance',
          },
        },
        details: {
          requiredActions: ['GetAppearance'],
          actions: {
            create: 'CreateAppearance',
            delete: 'DeleteAppearance',
            read: 'GetAppearance',
            readList: 'ListAppearances',
            update: 'UpdateAppearance',
          },
        },
      },
      terraform: {
        requiredActions: ['GetExternalBackends'],
        actions: {
          create: 'CreateExternalBackend',
          update: 'UpdateExternalBackend',
        },
      },
    },
    workers: {
      actions: {
        read: 'GetWorkers',
      },
    },
  },
  organizations: {
    index: {
      requiredActions: ['GetOrgs'],
      actions: {
        createOrg: 'CreateOrg',
        createOrgChild: 'CreateChild',
      },
    },
  },
  customers: {
    actions: {
      read: 'GetChildren',
    },
  },
  inventory: {
    index: {
      requiredActions: ['ListInventoryResources'],
      actions: {
        create: 'CreateInventoryResource',
      },
    },
    details: {
      actions: {
        update: 'UpdateInventoryResource',
        delete: 'DeleteInventoryResource',
        read: 'GetInventoryResource',
      },
    },
    create: {
      requiredActions: ['CreateInventoryResource'],
      actions: {
        create: 'CreateInventoryResource',
      },
    },
  },
  projects: {
    index: {
      requiredActions: ['GetProjects'],
      actions: {
        ...assignOwner,
        create: 'CreateProject',
        delete: 'DeleteProject',
        read: 'GetProjects',
        readPipeline: 'GetPipeline',
        update: 'UpdateProject',
      },
    },
  },
  project: {
    details: {
      requiredActions: ['GetProject'],
      actions: {
        ...assignOwner,
        create: 'CreateProject',
        delete: 'DeleteProject',
        listConfigRepos: 'ListConfigRepositories',
        readConfig: 'GetProjectConfig',
        readConfigRepo: 'GetConfigRepository',
        readEnvironments: 'GetEnvironments',
        readInfra: 'GetProjInfrastructure',
        readLogs: 'GetProjEnvLogStreams',
        readMetrics: 'GetKPIs',
        readPipeline: 'GetPipeline',
        update: 'UpdateProject',
        readCR: 'GetServiceCatalogSource',
      },
    },
    metrics: {
      requiredActions: ['GetKPIs'],
      actions: {
        create: 'CreateKPI',
        update: 'UpdateKPI',
        delete: 'DeleteKPI',
      },
    },
    create: {
      requiredActions: ['CreateProject'],
      actions: {
        create: 'CreateProject',
        editStack: 'UpdateServiceCatalog',
        readCatalogRepos: 'GetServiceCatalogSources',
        listStacks: 'ListServiceCatalogs',
        useStack: 'GetServiceCatalog',
      },
    },
    environments: {
      update: {
        requiredActions: ['UpdateProject'],
        actions: {
          read: 'GetServiceCatalog',
          readKPIs: 'GetKPIs',
        },
      },
      index: {
        actions: {
          create: 'CreateBuild',
          listStacks: 'ListServiceCatalogs',
        },
      },
    },
    pipelines: {
      index: {
        requiredActions: ['GetPipelines'],
        actions: {
          read: 'GetPipeline',
          readJob: 'GetJob',
          update: 'UpdatePipeline',
          readKPIs: 'GetKPIs',
        },
      },
      builds: {
        actions: {
          pause: 'PauseJob',
          unpause: 'UnpauseJob',
          abort: 'AbortBuild',
          create: 'CreateBuild',
          readAll: 'GetBuilds',
          read: 'GetBuild',
          readResource: 'GetBuildResources',
          reRunBuild: 'RerunBuild',
        },
      },
      resources: {
        actions: {
          update: 'UnpinResource',
          set: 'ResourceSetPinComment',
          read: 'GetResource',
          readVersion: 'GetResourceVersions',
        },
      },
    },
    configuration: {
      actions: {
        ...assignOwner,
        createExternalBackend: 'CreateExternalBackend',
        deleteExternalBackend: 'DeleteExternalBackend',
        listConfigRepositories: 'ListConfigRepositories',
        listCredentials: 'ListCredentials',
        readCloudProvider: 'GetCloudProviders',
        readCredentials: 'GetCredential',
        readExternalBackends: 'GetExternalBackends',
        readKPIs: 'GetKPIs',
        updateExternalBackend: 'UpdateExternalBackend',
      },
    },
    logs: {
      index: {
        actions: {
          read: 'GetProjEnvLogStreams',
          readKPIs: 'GetKPIs',
        },
      },
      elasticsearch: {
        create: {
          requiredActions: ['CreateExternalBackend'],
          actions: {
            create: 'CreateExternalBackend',
            read: 'GetExternalBackends',
            readCloudProvider: 'GetCloudProviders',
            listConfigRepositories: 'ListConfigRepositories',
          },
        },
        details: {
          requiredActions: ['GetExternalBackend'],
          actions: {
            delete: 'DeleteExternalBackend',
            read: 'GetExternalBackends',
            readCloudProvider: 'GetCloudProviders',
            listConfigRepositories: 'ListConfigRepositories',
            update: 'UpdateExternalBackend',
          },
        },
      },
    },
    infraview: {
      index: {
        requiredActions: ['GetExternalBackend'],
        actions: {
          read: 'GetProjInfrastructure',
          update: 'UpdateExternalBackend',
          readKPIs: 'GetKPIs',
          create: 'CreateExternalBackend',
        },
      },
      create: {
        requiredActions: ['CreateExternalBackend'],
        actions: {
          create: 'CreateExternalBackend',
        },
      },
      details: {
        requiredActions: ['GetExternalBackend'],
        actions: {
          read: 'GetProjInfrastructure',
          update: 'UpdateExternalBackend',
        },
      },
    },
  },
  events: {
    index: {
      requiredActions: ['GetEvents'],
      actions: {
        read: 'GetEvents',
      },
    },
  },
  pipelines: {
    index: {
      requiredActions: ['GetPipelines'],
      actions: {
        read: 'GetPipeline',
        readJob: 'GetJob',
        update: 'UpdatePipeline',
      },
    },
  },
  resourcePools: {
    index: {
      requiredActions: ['ListResourcePools'],
      actions: {
        create: 'CreateResourcePool',
      },
    },
    create: {
      requiredActions: ['CreateResourcePool'],
      actions: {
        create: 'CreateResourcePool',
      },
    },
    details: {
      actions: {
        update: 'UpdateResourcePool',
        delete: 'DeleteResourcePool',
        read: 'GetResourcePool',
      },
    },
  },
  catalogRepositories: {
    index: {
      requiredActions: ['GetServiceCatalogSources'],
      actions: {
        create: 'CreateServiceCatalogSource',
        delete: 'DeleteServiceCatalogSource',
        listCredential: 'ListCredentials',
        read: 'GetServiceCatalogSources',
        refresh: 'RefreshServiceCatalogSource',
        readCredential: 'GetCredential',
      },
    },
    create: {
      requiredActions: ['CreateServiceCatalogSource'],
      actions: {
        create: 'CreateServiceCatalogSource',
        listCredential: 'ListCredentials',
        readCredential: 'GetCredential',
      },
    },
    details: {
      requiredActions: ['GetServiceCatalogSource'],
      actions: {
        create: 'CreateServiceCatalogSource',
        delete: 'DeleteServiceCatalogSource',
        refresh: 'RefreshServiceCatalogSource',
        update: 'UpdateServiceCatalogSource',
      },
    },
  },
  configRepositories: {
    index: {
      requiredActions: ['ListConfigRepositories'],
      actions: {
        create: 'CreateConfigRepository',
        delete: 'DeleteConfigRepository',
        listCredential: 'ListCredentials',
        read: 'GetConfigRepository',
        readCredential: 'GetCredential',
      },
    },
    create: {
      requiredActions: ['CreateConfigRepository'],
      actions: {
        create: 'CreateConfigRepository',
        listCredential: 'ListCredentials',
        readCredential: 'GetCredential',
      },
    },
    details: {
      requiredActions: ['GetConfigRepository'],
      actions: {
        create: 'CreateConfigRepository',
        delete: 'DeleteConfigRepository',
        update: 'UpdateConfigRepository',
      },
    },
  },
  credentials: {
    index: {
      requiredActions: ['ListCredentials'],
      actions: {
        ...assignOwner,
        create: 'CreateCredential',
        delete: 'DeleteCredential',
        list: 'ListCredentials',
        read: 'GetCredential',
        readAll: 'GetCredential',
        update: 'UpdateCredential',
      },
    },
    create: {
      requiredActions: ['CreateCredential'],
      actions: {
        create: 'CreateCredential',
      },
    },
    details: {
      actions: {
        ...assignOwner,
        delete: 'DeleteCredential',
        list: 'ListCredentials',
        read: 'GetCredential',
        update: 'UpdateCredential',
      },
    },
  },
  members: {
    index: {
      requiredActions: ['GetOrgMembers'],
      actions: {
        create: 'InviteUserToOrgMember',
        delete: 'RemoveOrgMember',
        read: 'GetOrgMember',
        readPendingInvites: 'GetOrgMembers',
        readRole: 'GetRole',
        update: 'UpdateOrgMember',
      },
    },
    details: {
      requiredActions: ['GetOrgMember'],
      actions: {
        delete: 'RemoveOrgMember',
        read: 'GetOrgMember',
        update: 'UpdateOrgMember',
      },
    },
    invite: {
      requiredActions: ['InviteUserToOrgMember'],
      actions: {
        create: 'InviteUserToOrgMember',
      },
    },
    pendingInvites: {
      requiredActions: ['GetOrgMembers'],
      actions: {
        create: 'InviteUserToOrgMember',
        delete: 'RemoveOrgMember',
        // resend: 'ResendInvitation', // TODO: FE#6374 Implement permissions to resend
      },
    },
  },
  quotas: {
    index: {
      requiredActions: ['ListQuotas'],
      actions: {
        create: 'CreateQuota',
      },
    },
    create: {
      requiredActions: ['CreateQuota'],
      actions: {
        create: 'CreateQuota',
      },
    },
    details: {
      actions: {
        update: 'UpdateQuota',
        delete: 'DeleteQuota',
        read: 'GetQuota',
      },
    },
  },
  roles: {
    index: {
      requiredActions: ['GetRoles'],
      actions: {
        create: 'CreateRole',
        delete: 'DeleteRole',
        read: 'GetRole',
      },
    },
    create: {
      requiredActions: ['CreateRole'],
      actions: {
        create: 'CreateRole',
      },
    },
    details: {
      actions: {
        create: 'CreateRole',
        delete: 'DeleteRole',
        read: 'GetRole',
        readPolicies: 'GetPolicies',
        update: 'UpdateRole',
      },
    },
  },
  apiKeys: {
    index: {
      requiredActions: ['GetAPIKeys'],
      actions: {
        ...assignOwner,
        create: 'CreateAPIKey',
        delete: 'DeleteAPIKey',
        update: 'UpdateAPIKey',
      },
    },
    create: {
      requiredActions: ['CreateAPIKey'],
      actions: {
        create: 'CreateAPIKey',
      },
    },
    details: {
      actions: {
        ...assignOwner,
        create: 'CreateAPIKey',
        delete: 'DeleteAPIKey',
        read: 'GetAPIKeys',
        update: 'UpdateAPIKey',
      },
    },
  },
  stacks: {
    index: {
      requiredActions: ['ListServiceCatalogs'],
      actions: {
        create: 'CreateServiceCatalog',
        createProject: 'CreateProject',
        edit: 'UpdateServiceCatalog',
        readCatalogRepos: 'GetServiceCatalogSources',
        readStack: 'GetServiceCatalog',
        readCR: 'GetServiceCatalogSource',
      },
    },
    create: {
      requiredActions: ['CreateServiceCatalog'],
      actions: {
        create: 'CreateServiceCatalog',
      },
    },
    details: {
      actions: {
        read: 'GetServiceCatalog',
        readCR: 'GetServiceCatalogSource',
        update: 'UpdateServiceCatalog',
        delete: 'DeleteServiceCatalog',
        create: 'CreateServiceCatalog',
        createProject: 'CreateProject',
      },
    },
    edit: {
      requiredActions: [
        'GetServiceCatalog',
        'UpdateServiceCatalog',
        'GetServiceCatalogConfig',
      ],
    },
    tools: {
      requiredActions: [
        'CreateProject',
        'UpdateProject',
      ],
    },
  },
  teams: {
    index: {
      requiredActions: ['GetTeams'],
      actions: {
        ...assignOwner,
        create: 'CreateTeam',
        delete: 'DeleteTeam',
        read: 'GetTeam',
        update: 'UpdateTeam',
      },
    },
    create: {
      requiredActions: ['CreateTeam'],
      actions: {
        create: 'CreateTeam',
      },
    },
    details: {
      requiredActions: ['GetTeam'],
      actions: {
        ...assignOwner,
        addMembers: 'AssignMemberToTeam',
        delete: 'DeleteTeam',
        readTeamMember: 'GetTeamMember',
        readTeamMembers: 'GetTeamMembers',
        unassignTeamMember: 'UnassignMemberFromTeam',
        update: 'UpdateTeam',
      },
    },
  },
  user: {
    /** User routes do not need permission checks.
     *
     * At least for CRUD of their own profile/account,
     * they ALWAYS have permission to do those things.
     */
    actions: {},
  },
  infraPolicies: {
    index: {
      requiredActions: ['GetInfraPolicies'],
      actions: {
        ...assignOwner,
        create: 'CreateInfraPolicy',
        delete: 'DeleteInfraPolicy',
        update: 'UpdateInfraPolicy',
      },
    },
    create: {
      requiredActions: ['CreateInfraPolicy'],
      actions: {
        create: 'CreateInfraPolicy',
      },
    },
    details: {
      requiredActions: ['GetInfraPolicy'],
      actions: {
        ...assignOwner,
        create: 'CreateInfraPolicy',
        delete: 'DeleteInfraPolicy',
        read: 'GetInfraPolicy',
        update: 'UpdateInfraPolicy',
      },
    },
  },
  infraImport: {
    index: {
      actions: {
        create: 'CreateServiceCatalog',
      },
    },
  },
  cloudCostManagement: {
    index: {
      requiredActions: ['GetCloudCostManagement'],
      actions: {
        read: 'GetCloudCostManagement',
        create: 'GetCloudCostManagementAccounts',
        update: 'GetCloudCostManagementTagMapping',
      },
    },
    accounts: {
      create: {
        requiredActions: ['CreateCloudCostManagementAccount'],
        actions: {
          read: 'GetCloudCostManagement',
          readAccount: 'GetCloudCostManagementAccount',
          createAccount: 'CreateCloudCostManagementAccount',
        },
      },
      details: {
        requiredActions: ['GetCloudCostManagementAccount'],
        actions: {
          delete: 'DeleteCloudCostManagementAccount',
          listCredential: 'ListCredentials',
          readCredential: 'GetCredential',
          update: 'UpdateCloudCostManagementAccount',
        },
      },
      list: {
        requiredActions: ['GetCloudCostManagementAccounts'],
        actions: {
          create: 'CreateCloudCostManagementAccount',
          read: 'GetCloudCostManagementAccount',
        },
      },
    },
    tagMapping: {
      requiredActions: ['GetCloudCostManagementTagMapping'],
      actions: {
        update: 'PutCloudCostManagementTagMapping',
      },
    },
  },
}
