export default {
  en: {
    en: {
      name: 'English',
    },
    es: {
      name: 'Spanish',
    },
    fr: {
      name: 'French',
    },
  },
  es: {
    en: {
      name: 'Inglés',
    },
    es: {
      name: 'Español',
    },
    fr: {
      name: 'Francés',
    },
  },
  fr: {
    en: {
      name: 'Anglais',
    },
    es: {
      name: 'Espagnol',
    },
    fr: {
      name: 'Français',
    },
  },

}
