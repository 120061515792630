export default {
  install (Vue) {
    Vue.prototype.$isDevMode = process.env.NODE_ENV === 'development'
    Vue.prototype.$isStaging = process.env.NODE_ENV === 'staging' && !process.env.VUE_APP_BASE_URL.includes('console.beta')
    Vue.prototype.$isProduction = process.env.NODE_ENV === 'production'
    Vue.prototype.$isBeta = process.env.NODE_ENV === 'staging' && process.env.VUE_APP_BASE_URL.includes('console.beta')
    Vue.prototype.$isEEnv = location.host.startsWith('console-pr-')
    Vue.prototype.$isStagingOrDev = ['development', 'staging'].includes(process.env.NODE_ENV)
    Vue.prototype.$isLocalBE = process.env.VUE_APP_API_URL.includes('localhost')
  },
}
