<template>
  <router-link
    ref="router-link-wrapper"
    :to="to"
    :tabindex="tabindexGetter(parentIndex)"
    class="router-link-wrapper unstyled-link"/>
</template>

<script>
export default {
  name: 'CyRouterLinkWrapper',
  props: {
    to: {
      type: Object,
      required: true,
    },
    parentIndex: {
      type: Number,
      default: 0,
    },
    tabindexGetter: {
      type: Function,
      default: () => {
        return () => 0
      },
    },
  },
}
</script>
