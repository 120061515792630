export default {
  en: {
    paused: 'paused',
    start: 'Start',
    stop: 'Stop',
    viewStartStop: 'View start-stop pipeline',
  },
  es: {
    paused: 'pausada',
    start: 'Iniciar',
    stop: 'Parar',
    viewStartStop: 'Ver start-stop pipeline',
  },
  fr: {
    paused: 'en pause',
    start: 'Démarrer',
    stop: 'Arrêter',
    viewStartStop: 'Afficher la pipeline start-stop',
  },
}
