const blackList = ['CyPageTabs']

export default {
  install (Vue) {
    Vue.mixin({
      watch: {
        '$store.getters.appearance': {
          handler () {
            const { name: componentName = '' } = this.$options
            const isPage = componentName.startsWith('CyPage') && !blackList.includes(componentName)
            if (isPage) this._updateBrowserTabTitle()
          },
          immediate: true,
          deep: true,
        },
      },
      methods: {
        _updateBrowserTabTitle () {
          const { appearance } = this.$store.getters
          const i18nKey = _.isPlainObject(this.$t('title'))
            ? `title.${this.$route.name}`
            : 'title'
          document.title = this.$showFeature.whiteLabel && appearance
            ? `${this.$t(i18nKey)} | ${appearance.tab_title}`
            : `${this.$t(i18nKey)} | Cycloid`
        },
      },
    })
  },
}
