/* eslint-disable vue/no-unused-properties */

import shortid from 'shortid'

shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@')

export default {
  install (Vue) {
    Vue.mixin({
      methods: {
        $shortid () {
          return shortid.generate()
        },
      },
    })
  },
}
