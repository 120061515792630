/* eslint-disable vue/no-unused-properties */

export default {
  install (Vue) {
    Vue.mixin({
      computed: {
        /** Will either toggle the current state of a boolean prop, or set it to true/false param passed.
         *
         * @returns {Object}    With key paths for every data prop that is a Boolean
         *
         * @example $toggle.show.deleteDialog()     // will toggle the current state of show.deleteDialog
         * @example $toggle.show.deleteDialog(true) // will set show.deleteDialog to true
         * @example $toggle.show.deleteDialog(123)  // will toggle the current state of show.deleteDialog and throw a console.warn
         */
        $toggle () {
          const toggle = {}
          const dataPropPaths = _.keys(_.$flattenObject(this.$options.data.apply(this)))
            .filter((path) => _.isBoolean(_.get(this, path)))

          for (const path of dataPropPaths) {
            _.set(toggle, path, (value) => {
              if (!_.isBoolean(value)) value = undefined
              const newValue = _.isNil(value)
                ? !_.get(this, path)
                : value
              _.set(this, path, newValue)
            })
          }

          return { ...toggle }
        },
      },
    })
  },
}
