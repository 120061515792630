import Vue from 'vue'
import { vuexMixin } from '@/utils/helpers'
import i18n from '@/utils/plugins/i18n'

export const initialState = {
  detail: null,
  errors: {
    resource: [],
    delete: [],
  },
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {}

const {
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  async CREATE_RESOURCE ({ commit, dispatch, rootGetters: { orgCanonical } }, { $router, resource }) {
    commit('CLEAR_RESOURCE_ERRORS', 'resource')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createResource(orgCanonical, resource) || {}
    if (data) {
      if ($router) $router.push({ name: 'resourcePools' })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.resource.created') }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { key: 'resource', errors })
  },

  async GET_RESOURCE ({ commit, rootGetters: { orgCanonical } }, { resourceId }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getResource(orgCanonical, resourceId) || {}
    if (data) commit('SET_RESOURCE', data)
    if (errors) commit('SET_ERRORS', { key: 'resource', errors })
  },

  async DELETE_RESOURCE ({ commit, dispatch, rootGetters: { orgCanonical } }, { resourceId }) {
    commit('CLEAR_RESOURCE_ERRORS', 'delete')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteResource(orgCanonical, resourceId) || {}
    if (errors) {
      commit('SET_ERRORS', { key: 'delete', errors })
    } else {
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.resource.deleted') }, { root: true })
      commit('RESET_RESOURCE_STATE')
    }
  },

  async UPDATE_RESOURCE ({ commit, dispatch, rootGetters: { orgCanonical } }, { resource, $router }) {
    commit('CLEAR_RESOURCE_ERRORS', 'resource')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.updateResource(orgCanonical, resource) || {}
    if (errors) commit(`SET_ERRORS`, { key: 'resource', errors })
    else {
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.resource.updated') }, { root: true })
      if ($router) $router.push({ name: 'resourcePools' })
    }
  },
}

export const mutations = {
  CLEAR_RESOURCE_ERRORS: CLEAR_ERRORS,
  RESET_RESOURCE_STATE: RESET_STATE,
  SET_ERRORS,

  SET_RESOURCE (state, resource) {
    Vue.set(state, 'detail', resource)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
