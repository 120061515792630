/* eslint-disable vue/no-unused-properties */

import speakingurl from 'speakingurl'

export const SLUG_LIMIT = 50

export default {
  install (Vue) {
    Vue.mixin({
      methods: {
        $getSlug (stringToSlug) {
          const getSlug = speakingurl.createSlug({ truncate: SLUG_LIMIT })
          return getSlug(stringToSlug)
        },
      },
    })
  },
}
