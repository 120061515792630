import { checkUserPermissions } from '@/utils/plugins/permissions'

export const handleDevLayerChanges = ({ commit }) => (_to, _from, next) => {
  commit('dev/RESET_DEV_STATE', 'mockedEndpointsCalled')
  commit('dev/RESET_DEV_STATE', 'permissionsChecked')
  next()
}

/** Determines the organization relationship when the user copy/pastes the url or visits the page fresh */
export const checkOrgOrigin = ({ state, getters, dispatch }) => async (to, from, next) => {
  if (isLoggingOut(to) || isNotFound(to)) return next()

  const orgCanonical = to?.params?.orgCanonical ?? getters['auth/jwtDecoded']?.organization?.canonical
  const isPastedUrl = from.name === null
  const isOrgDependentRoute = to.path.includes('organizations')
  const hasOrgCanonical = isOrgDependentRoute && !_.isEmpty(orgCanonical)

  if (isPastedUrl && hasOrgCanonical) {
    await dispatch('FETCH_ORGS')
    const isOrgInOrgsList = _.some(state.organizations, ['canonical', orgCanonical])

    if (isOrgInOrgsList) {
      await dispatch('organization/CHANGE_ORGANIZATION', { nextCanonical: orgCanonical })
      return next()
    }
  }

  next()
}

export const trackNextPrivatePageToRedirectAfterLogIn = ({ commit }) => (to, { orgCanonical }, next) => {
  if (isLoggingOut(to)) return next()

  if (!to.meta.isPublic) {
    const { path, name, hash, params, query } = to
    commit('auth/SET_DEFAULT_ROUTE', { path, name, hash, query, params: { ...params, orgCanonical } })
  }

  next()
}

export const checkOrgBillingState = ({ getters }) => (to, _from, next) => {
  if (isLoggingOut(to)) return next()

  const { orgCanonical, hasActiveBillingPlan } = getters

  if (!hasActiveBillingPlan && !isAccessingAllowedPage({ to })) return next({ name: 'orgSettings', params: { orgCanonical } })
  next()
}

export const checkAuthBeforeEach = ({ getters, dispatch }) => async (to, from, next) => {
  if (to.name === 'logout' || getters.isAuthenticated) return next()

  await dispatch('RESTORE_SESSION')
  const { isAuthenticated, orgCanonical } = getters
  const { isPublic = false } = to.meta

  // Authenticated
  if (isAuthenticated) {
    return isPublic && ['login', 'signUp'].includes(to.name)
      ? next({ name: 'dashboard', params: { orgCanonical } })
      : next()
  }

  // Not Authenticated
  if (isPublic) {
    if (isNotFound(to)) return next({ name: 'login' })
    // ! The 'login' page is an exception
    // It's a public page but we want to redirect the user if they are already authenticated.
    // Also, make sure we don't go to the notFound page if we are not authenticated.
    if (to.name !== 'login') return next()
  }

  // If unauthorized and trying to access private page, redirect to login
  if (to.name !== 'login') return next({ name: 'login' })

  // If the user's home page is login, then we need to prevent an infinite loop by aborting navigation
  if (from.name === 'login') return next(false)

  // ? Another edge case where to.name is 'login', but from.name isn't 'login'.
  // ? Which is how the 'logout' route is implemented.
  next()
}

export const resetDataTableSettings = ({ state, commit }) => (to, from) => {
  const { name } = from || {}
  const hasSettingsStored = _.has(state.layout.dataTables, name)
  const isRefreshing = sessionStorage.isRefreshing || false
  const isPageLoad = _.isEmpty(name)
  const isChangingScope = !isPageLoad && _.get(from, 'meta.appSection') !== _.get(to, 'meta.appSection')

  if (hasSettingsStored && isChangingScope && !isRefreshing) {
    commit('layout/RESET_DATA_TABLE_SEARCH', { name })
    commit('layout/RESET_DATA_TABLE_PAGE', { name })
    commit('layout/RESET_DATA_TABLE_FILTERS', { name })
  }
}

export const evaluateUserActions = (store) => async (to, from, next) => {
  const { actions = {}, requiredActions = [], isAlpha } = to.meta || {}
  const computedRequiredActions = _.isFunction(requiredActions)
    ? requiredActions(store)
    : requiredActions

  if (isLoggingOut(to) || to.meta.isPublic) return next()

  if (_.some([actions, computedRequiredActions], (acts) => !_.isEmpty(acts))) {
    const uniqueActions = _.uniq([...Object.values(actions), ...computedRequiredActions])
    const canonicals = to.params ?? {}
    await store.dispatch('auth/EVALUATE_USER_ACTIONS', { actions: uniqueActions, canonicals })
  }

  if (to.name === 'organizations') return next()

  const { backRouteTo, permissionsKey } = _.cloneDeep(to.meta)
  const succeededReqActionCheck = isAlpha || _(computedRequiredActions)
    .map((requiredAction) => checkUserPermissions(store, requiredAction))
    .every()
  succeededReqActionCheck
    ? next()
    : next({
      name: 'forbidden',
      params: { 0: to.path, backRouteTo, permissionsKey },
      replace: true,
    })
}

export const clearIsRefreshing = () => (to, _from, next) => {
  if (isLoggingOut(to)) return next()

  sessionStorage.removeItem('isRefreshing')
  next()
}

export const getLatestStatus = ({ dispatch }) => async (to, _from, next) => {
  if (isLoggingOut(to)) return next()

  await dispatch('FETCH_SERVICES_STATUS')
  next()
}

export const handleOrganizationLicenceAuthorization = ({ state, getters, dispatch }) => async (to, _from, next) => {
  if (isLoggingOut(to) || !getters.isOrgSelected) return next()

  if (!state.organization.licence.isFetched) await dispatch('organization/licence/GET_LICENCE')

  const { orgCanonical } = getters
  const hasValidOrgLicence = getters['organization/licence/hasLicence'] && !getters['organization/licence/isExpired']

  if (hasValidOrgLicence || _.isEmpty(orgCanonical) || isAccessingAllowedPage({ to })) return next()

  const isRootOrg = state.customers.currentScopeDepth === 0
  const shouldRedirect = to.name !== 'orgSettings' && (getters['organization/licence/isExpired'] || (isRootOrg && !getters['organization/licence/hasLicence']))

  shouldRedirect ? next({ name: 'orgSettings', params: { orgCanonical } }) : next()
}

export function isLoggingOut (to) {
  return ['logout', 'login'].includes(to.name)
}

export function isNotFound (to) {
  return ['notFound'].includes(to.name)
}

/** Returns true if accessing an allowed page (not limited by licence or plan subscription) */
export function isAccessingAllowedPage ({ to }) {
  const isRoutePublic = to?.meta?.isPublic ?? false
  const isAllowedPage = ['organizations', 'profile', 'orgSettings', 'newOrganization'].includes(to.name)
  const isAccessingForbidden = to.name === 'forbidden'
  return (isRoutePublic && !isAccessingForbidden) || isAllowedPage
}

export default function globalGuards (store) {
  return {
    beforeEach: [
      handleDevLayerChanges(store),
      trackNextPrivatePageToRedirectAfterLogIn(store),
      checkOrgBillingState(store),
      checkAuthBeforeEach(store),
      evaluateUserActions(store),
      checkOrgOrigin(store),
      clearIsRefreshing(),
      getLatestStatus(store),
      handleOrganizationLicenceAuthorization(store),
    ],
    afterEach: [
      resetDataTableSettings(store),
    ],
  }
}
