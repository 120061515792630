const parseProviders = ({
  provider,
  resources: totalResources,
  new_resources: totalNewResources,
  cost,
  co2e,
  kwh,
  new_resources_cost: _newResourcesCost,
}) => ({
  // TODO: FE#6428 remove amazon to aws conversion once BE response allows
  canonical: provider === 'amazon' ? 'aws' : provider,
  cost,
  co2e: co2e * 1000,
  kwh,
  resource_count: totalResources,
  new_resources_count: totalNewResources,
})

const parseTopProjects = (data, histogramDates) => {
  const dateFormat = _.get(_.head(data.projects.buckets), 'value')?.length === 7 ? 'yyyy-MM' : 'yyyy-MM-dd'
  const flattenedBuckets = _.flatMap(data.projects.buckets, ({ value, buckets }) => {
    return buckets.map(({ cost, co2e, kwh, value: project }) => ({
      cost,
      co2e: co2e * 1000,
      kwh,
      project,
      date: $date.parse(value, dateFormat, new Date()).getTime(),
    }))
  })
  const histograms = _.groupBy(flattenedBuckets, 'project')

  const resources = data.project_resources.map(({ project, providers, cost }) => {
    const firstBucket = _.find(data.projects.buckets, ({ value }) => $date.parse(value, dateFormat, new Date()).getTime() === _.head(histogramDates))
    const lastBucket = _.find(data.projects.buckets, ({ value }) => $date.parse(value, dateFormat, new Date()).getTime() === _.last(histogramDates))
    const firstProjectBucket = _.find((firstBucket?.buckets || []), ['value', project])
    const lastProjectBucket = _.find((lastBucket?.buckets || []), ['value', project])
    return {
      canonical: project,
      resource_count: _.sumBy(providers, 'resources'),
      new_resources_count: _.sumBy(providers, 'new_resources'),
      cost: {
        first: firstProjectBucket?.cost || 0,
        last: lastProjectBucket?.cost || 0,
        total: cost,
      },
      co2e: {
        first: (firstProjectBucket?.co2e || 0) * 1000,
        last: (lastProjectBucket?.co2e || 0) * 1000,
        total: _.sumBy(providers, 'co2e') * 1000,
      },
      kwh: {
        first: firstProjectBucket?.kwh || 0,
        last: lastProjectBucket?.kwh || 0,
        total: _.sumBy(providers, 'kwh'),
      },
      providers: providers.map(parseProviders),
    }
  })

  return { resources, histograms }
}

const parseToGraphFormat = (projectsData, histogramDates) => {
  return _.transform(projectsData, (result, populatedValues, project) => {
    result[project || 'undefined'] = _.map(histogramDates, (date) => ({
      date,
      cost: _.find(populatedValues, ['date', date])?.cost || 0,
      co2e: _.find(populatedValues, ['date', date])?.co2e || 0,
      kwh: _.find(populatedValues, ['date', date])?.kwh || 0,
      project,
    }))
  })
}

export {
  parseTopProjects,
  parseToGraphFormat,
}
