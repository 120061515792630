/** Create comment node which will replace the original one with a html comment
 *
 * @private
 * @author https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs#43543814
 */
function commentNode (el, vnode) {
  const comment = document.createComment(' ')

  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined,
  })

  vnode.text = ' '
  vnode.elm = comment
  vnode.isComment = true
  vnode.context = undefined
  vnode.tag = undefined
  vnode.data.directives = undefined

  if (vnode.componentInstance) {
    vnode.componentInstance.$el = comment
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el)
  }
}

const warning = `[v-has-rights-to] was passed an Array of 1 String.

Arrays are only needed when you need to pass 2 values:
- A permission key
- An identifier

e.g. ['UpdateItem', item.id]`

export default {
  install (Vue) {
    Vue.directive('has-rights-to', {
      /** The bind function fires when the element with v-has-rights-to is bound to the element.
       *
       * See https://vuejs.org/v2/guide/custom-directive.html for more details
       *
       * @param {object} el - The element the directive is bound to.
       *                      This can be used to directly manipulate the DOM.
       * @param {object} binding - An object containing the custom directive properties
       * @param {object} vnode - The virtual node produced by Vue’s compiler
       */
      bind (el, { value }, vnode) {
        el.style.visibility = 'hidden'

        const { $cycloid: { permissions } } = vnode.context

        if (_.isArray(value) && _.isEmpty(value)) {
          el.style.visibility = 'visible'
          return
        }
        if (_.isArray(value) && _.size(value) === 1) console.warn(warning)

        const allowed = permissions.canDisplay(..._.castArray(value))

        // If the user has no rights to see the element, replace it with a html comment
        if (!allowed) commentNode(el, vnode)
        else el.style.visibility = 'visible'
      },
    })
  },
}
