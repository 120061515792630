export default {
  install (app) {
    app.mixin({
      watch: {
        $header: {
          handler (newValue) {
            this.$store.commit('layout/SET_HEADER', newValue)
          },
          deep: true,
        },
      },
      async beforeCreate () {
        if (typeof this.$options.header !== 'function') return

        if (typeof this.$options.computed === 'undefined') {
          this.$options.computed = {}
        }
        app.set(this.$options.computed, '$header', this.$options.header.bind(this))
        await this.$nextTick()
        this.$store.commit('layout/SET_HEADER', this.$header)
      },
      destroyed () {
        if (typeof this.$options.header === 'function') {
          this.$store.commit('layout/SET_HEADER', null)
        }
      },
    })
  },
}
