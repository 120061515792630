export default {
  organization: 'An organization is the root entity of all other entities.',
  'organization:api_key': '@:APIKeys are used to get access to the organization.',
  'organization:appearance': 'Use your own brand colors and logos to display throughout the application.',
  'organization:child': 'Permissions to access sub-organizations.',
  'organization:cloud_provider': 'Permissions to list cloud providers.',
  'organization:config_repository': 'A Config repository is a git repository where Stacks configuration is stored.',
  'organization:cloud_cost_management': 'Permissions to access the Cloud Cost Management to explore costs.',
  'organization:cloud_cost_management_account': 'Permissions to access the Cloud Cost Management accounts.',
  'organization:cloud_cost_management_tag_mapping': 'Permissions to access the Cloud Cost Management tag mapping.',
  'organization:credential': 'Permissions to manage the credentials of the organization.',
  'organization:environment': 'Permissions to list environments information across projects.',
  'organization:external_backend': 'Permissions to manage configuration access to external services used by Cycloid, such as project logs or InfraView.',
  'organization:infra_policy': 'InfraPolicies are enterprise policy checks for your cloud infrastructure.',
  'organization:inventory_resource': 'Permissions to list inventory resources.',
  'organization:invitation': 'Permissions to manage the ability to invite users to your organization.',
  'organization:kpi': 'KPIs are metrics displayed per project, useful to get insights on your environments and builds statuses.',
  'organization:member': 'A member is a user that can be part of one or more organizations.',
  'organization:pipeline': 'Permissions to manage project tasks.',
  'organization:policy': 'Permissions to list available policies.',
  'organization:project': 'Permissions to manage access to projects. Project is a collection of environments created around a single Stack.',
  'organization:quota': 'Quotas are a tool to limit resource usage (bare metal ones) when deploying environments.',
  'organization:resource_pool': `Resource pools are logical abstractions used to group resources by label, so you don't have to create quotas for each server you have.`,
  'organization:role': 'Permissions to manage roles containing a set of policies.',
  'organization:service_catalog_source': 'Most commonly named Catalog Repositories, these are git repositories that contain Stacks for your organization.',
  'organization:service_catalog': 'Most commonly named Stacks, these are "templates" used to describe processes and infrastructures.',
  'organization:team:member': 'Permissions to manage users inside @:(teams).',
  'organization:team': '@:Teams are group of users where specific permissions can be given.',
}
