import Vue from 'vue'
import { vuexMixin } from '@/utils/helpers'
import i18n from '@/utils/plugins/i18n'

export const initialState = {
  detail: null,
  errors: [],
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  resourcePool: (state) => state.detail,
}

const {
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  async CREATE_RESOURCE_POOL ({ commit, dispatch, rootGetters: { orgCanonical } }, { resourcePool, $router }) {
    commit('CLEAR_RESOURCE_POOL_ERRORS', 'resourcePool')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createResourcePool(orgCanonical, resourcePool) || {}
    if (data) {
      commit('SET_RESOURCE_POOL', data)
      if ($router) $router.push({ name: 'resourcePools' })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.resourcePool.created') }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { errors })
  },

  async GET_RESOURCE_POOL ({ commit, rootGetters: { orgCanonical } }, { resourcePoolCanonical }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getResourcePool(orgCanonical, resourcePoolCanonical) || {}
    if (data) commit('SET_RESOURCE_POOL', data)
    if (errors) commit('SET_ERRORS', { errors })
  },

  async DELETE_RESOURCE_POOL ({ commit, dispatch, rootGetters: { orgCanonical } }, { resourcePool, $router }) {
    commit('CLEAR_RESOURCE_POOL_ERRORS', 'resourcePool')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteResourcePool(orgCanonical, resourcePool.canonical) || {}
    if (errors) commit('SET_ERRORS', { errors })
    else {
      if ($router) $router.push({ name: 'resourcePools' })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.resourcePool.deleted') }, { root: true })
      commit('RESET_RESOURCE_POOL_STATE')
    }
  },

  async UPDATE_RESOURCE_POOL ({ commit, dispatch, rootGetters: { orgCanonical } }, { resourcePoolCanonical, resourcePool, $router }) {
    commit('CLEAR_RESOURCE_POOL_ERRORS', 'resourcePool')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateResourcePool(orgCanonical, resourcePoolCanonical, resourcePool) || {}
    if (data) {
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.resourcePool.updated') }, { root: true })
      if ($router) $router.push({ name: 'resourcePools' })
    }
    if (errors) commit(`SET_ERRORS`, { errors })
  },
}

export const mutations = {
  CLEAR_RESOURCE_POOL_ERRORS: CLEAR_ERRORS,
  RESET_RESOURCE_POOL_STATE: RESET_STATE,
  SET_ERRORS,

  SET_RESOURCE_POOL (state, resourcePool) {
    Vue.set(state, 'detail', resourcePool)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
