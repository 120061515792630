import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from '@/locales'
import _ from '@/utils/lodash'

// ! needed in order for storybook to access process.env (buggy AF)
const isStoryBookRun = !!process.env.VUE_CLI_STORYBOOK
if (isStoryBookRun) console.info(process.env)

const { VUE_APP_DEFAULT_LANG, NODE_ENV } = process.env
const isProduction = NODE_ENV === 'production'

export const navigatorLocale = _.get(navigator, 'languages', [])
  .find((_locale) => _.keys(locales).includes(_locale))
export const locale = navigatorLocale || VUE_APP_DEFAULT_LANG

Vue.use(VueI18n)

export default new VueI18n({
  locale,
  fallbackLocale: VUE_APP_DEFAULT_LANG,
  fallbackRoot: false,
  sync: true,
  silentTranslationWarn: true,
  messages: locales,
  missing (_locale, key) {
    if (!isProduction) {
      console.warn(`error: missing locale ${_locale}.${key}`)
    }
  },
})
