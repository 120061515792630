export default {
  install (Vue) {
    Vue.directive('clickaway', {
      bind (el, { value: callback }, { context: component }) {
        function onClickaway ($event) {
          if (!el.contains($event.target)) callback()
        }
        document.addEventListener('click', onClickaway)

        component.$once('hook:beforeDestroy', () => {
          document.removeEventListener('click', onClickaway)
        })
      },
    })
  },
}
