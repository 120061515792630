import Vue from 'vue'
import { vuexMixin, formatAmount } from '@/utils/helpers'

export const initialState = {
  estimates: {},
  stackFormsValues: {},
  currency: 'USD',
  loading: {},
  errors: {},
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  getAmountWithCurrency: (state) => (amount) => {
    return formatAmount(amount, { currency: state.currency })
  },
}

const { mutations: { RESET_STATE, SET_ERRORS, CLEAR_ERRORS } } = vuexMixin(initialState)

export const actions = {
  async GET_ESTIMATE ({ commit, rootGetters: { orgCanonical, projectCanonical } }, stackFormsValues) {
    const env = _.get(stackFormsValues, 'inputs[0].environment_canonical')
    const usecase = _.get(stackFormsValues, 'inputs[0].use_case')

    commit('SET_LOADING', { env, value: true })
    commit('SET_ESTIMATE', { env, data: {} })
    commit('SET_STACK_FORMS_VALUES', { env, values: _.cloneDeep(stackFormsValues) })
    commit('CLEAR_TERRACOST_ERRORS', env)

    const { errors, data } = await Vue.prototype.$cycloid.ydAPI.costEstimateForms(orgCanonical, projectCanonical, stackFormsValues) || {}

    if (errors) commit('SET_ERRORS', { key: env, errors })
    if (data) commit('SET_ESTIMATE', { env, data: upgradeResponseData(data[env][usecase]) })
    commit('SET_LOADING', { env, value: false })
  },
}

export const mutations = {
  RESET_STATE,
  SET_ERRORS,
  CLEAR_TERRACOST_ERRORS: CLEAR_ERRORS,

  SET_ESTIMATE (state, { env, data }) {
    Vue.set(state.estimates, env, data)
  },
  SET_LOADING (state, { env, value }) {
    Vue.set(state.loading, env, value)
  },
  SET_STACK_FORMS_VALUES (state, { env, values }) {
    Vue.set(state.stackFormsValues, env, values)
  },
}

export function upgradeResponseData (data = []) {
  return data.map((entry) => ({ ...entry, planned_yearly_cost: entry.planned_yearly_cost || `${Number(entry.planned_cost) * 12}` }))
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
