/** Function to have a standardised way of determining the type of passed value.
 * It also makes a distinction between the built-in types, i.e.:
 * Array, Arguments, Date, JSON, RegExp, Math, Error,
 * and the primitive wrapper objects Number, Boolean and String
 *
 * https://javascriptweblog.wordpress.com/2011/08/08/fixing-the-javascript-typeof-operator/
 *
 * @param {value} `anything you want`
 *
 * @returns `string`
 *                          @example
 *                            {a: 4} returns => "object"
 *                            [1, 2, 3] returns => "array"
 *                            new ReferenceError returns => "error"
 *                            new Date returns => "date"
 *                            /a-z/ returns => "regexp"
 *                            Math returns => "math"
 *                            JSON returns => "json"
 *                            4 => returns "number"
 *                            "abc" returns => "string"
 *                            true returns => "boolean"
 */
function typeOf (value) {
  return ({}).toString.call(value).match(/\s([A-Za-z]+)/)[1].toLowerCase()
}

module.exports = typeOf
