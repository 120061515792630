export default {
  en: {
    severity: 'severity',
    advisory: 'advisory',
    crit: '@:severities.critical',
    critical: 'critical',
    err: '@:severities.error',
    error: 'error',
    info: 'info',
    information: 'information',
    warn: '@:severities.warning',
    warning: 'warning',
  },
  es: {
    severity: 'severidad',
    advisory: 'consultiva',
    crit: '@:severities.critical',
    critical: 'crítico',
    err: '@:severities.error',
    error: 'error',
    info: '@:severities.information',
    information: 'infomación',
    warn: '@:severities.warning',
    warning: 'advertencia',
  },
  fr: {
    severity: 'sévérité',
    advisory: 'consultative',
    crit: '@:severities.critical',
    critical: 'critique',
    err: '@:severities.error',
    error: 'erreur',
    info: '@:severities.information',
    information: 'information',
    warn: '@:severities.warning',
    warning: 'avertissement',
  },
}
