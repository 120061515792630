export default {
  en: {
    cannotInviteMembers: 'Inviting members is unavailable as the licence maximum users has been reached',
    contactYourAdmin: 'Contact your administrator',
    reachedMaxUsersCapacity: 'You reached maximum users capacity',
    cannotCreateOrganization: 'Creating organization is unavailable as the licence maximum users has been reached',
  },
  es: {
    cannotInviteMembers: 'No se pueden invitar nuevos miembros ya que se llegó a los usuarios máximos de la licencia.',
    contactYourAdmin: 'Póngase en contacto con su administrador',
    reachedMaxUsersCapacity: 'Llegaste a la máxima capacidad de los usuarios.',
    cannotCreateOrganization: 'La creación de una organización no está disponible ya que se llegó a los usuarios máximos de la licencia.',
  },
  fr: {
    cannotInviteMembers: `L'invitation de membres est indisponible car le maximum de licences utilisateurs a été atteint`,
    contactYourAdmin: 'Contactez votre administrateur',
    reachedMaxUsersCapacity: `Vous avez atteint le nombre d'utilisateurs maximal`,
    cannotCreateOrganization: `La création d'une organisation est indisponible car le maximum de licences utilisateurs a été atteint`,
  },
}
