import Vue from 'vue'
import { ObjWithPrivateProps, RepoUrl } from '@/utils/classes'
import { vuexMixin } from '@/utils/helpers'
import i18n from '@/utils/plugins/i18n'

export const initialState = {
  detail: null,
  branches: null,
  errors: {
    branches: [],
    configRepository: [],
  },
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  configRepository: (state) => state.detail,
  hasBranches: (state) => !_.isEmpty(state.branches),
  hasBranchesError: (state) => !_.isEmpty(state.errors.branches),
}

const {
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  async CREATE_CONFIG_REPOSITORY ({ commit, dispatch, rootGetters: { orgCanonical } }, { configRepository, $router }) {
    commit('CLEAR_CR_ERRORS', 'configRepository')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createConfigRepository(orgCanonical, configRepository) || {}
    if (data) {
      commit('SET_CONFIG_REPOSITORY', _.merge(data, configRepository))
      const content = i18n.t('alerts.success.configRepository.created', { configRepoName: data.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
      if ($router) $router.replace({ name: 'configRepository', params: { configRepositoryCanonical: configRepository.canonical } })
    }
    if (errors) commit('SET_ERRORS', { key: 'configRepository', errors })
  },

  async CREATE_CONFIG_REPOSITORY_FILES ({ commit, rootGetters: { orgCanonical } }, { configRepositoryCanonical, configObject }) {
    commit('CLEAR_CR_ERRORS', 'configRepository')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.createConfigRepositoryConfig(orgCanonical, configRepositoryCanonical, configObject) || {}
    if (errors) commit('SET_ERRORS', { key: 'configRepository', errors })
  },

  async DELETE_CONFIG_REPOSITORY ({ commit, dispatch, rootGetters: { orgCanonical } }, { configRepository, $router }) {
    commit('CLEAR_CR_ERRORS', 'configRepository')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteConfigRepository(orgCanonical, configRepository.canonical) || {}
    if (errors) commit('SET_ERRORS', { key: 'configRepository', errors })
    else {
      commit('RESET_CR_STATE')
      const content = i18n.t('alerts.success.configRepository.deleted', { configRepoName: configRepository.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
      if ($router) $router.replace({ name: 'configRepositories' })
    }
  },

  async GET_BRANCHES ({ commit, rootGetters: { orgCanonical } }, { credentialCanonical, url }) {
    const code = 'InvalidConfigRepositoryNotFound'
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getRepoBranches(orgCanonical, credentialCanonical, url) || {}
    if (data) {
      commit('CLEAR_CR_ERRORS', 'configRepository')
      commit('CLEAR_CR_ERRORS', 'branches')
      commit('SET_BRANCHES', data.sort())
    }
    if (_.every([data, errors], _.isEmpty)) commit('SET_ERRORS', { key: 'configRepository', errors: [{ code, message: i18n.t(`apiErrors.${code}`) }] })
    if (errors) commit('SET_ERRORS', { key: 'branches', errors })
  },

  async GET_CONFIG_REPOSITORY ({ commit, rootGetters: { orgCanonical } }, { configRepositoryCanonical }) {
    commit('CLEAR_CR_ERRORS', 'configRepository')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getConfigRepository(orgCanonical, configRepositoryCanonical) || {}
    if (data) commit('SET_CONFIG_REPOSITORY', data)
    if (errors) commit('SET_ERRORS', { key: 'configRepository', errors })
  },

  async UPDATE_CONFIG_REPOSITORY ({ commit, dispatch, rootGetters: { orgCanonical } }, { configRepository }) {
    commit('CLEAR_CR_ERRORS', 'configRepository')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateConfigRepository(orgCanonical, configRepository) || {}
    if (data) {
      commit('SET_CONFIG_REPOSITORY', _.merge(data, configRepository))
      const content = i18n.t('alerts.success.configRepository.updated', { configRepoName: configRepository.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { key: 'configRepository', errors })
  },
}

export const mutations = {
  CLEAR_CR_ERRORS: CLEAR_ERRORS,
  RESET_CR_STATE: RESET_STATE,
  SET_ERRORS,

  SET_BRANCHES (state, branches) {
    Vue.set(state, 'branches', branches)
  },

  SET_CONFIG_REPOSITORY (state, configRepository) {
    Vue.set(state, 'detail', new ObjWithPrivateProps(configRepository, {
      _repoURL: new RepoUrl(configRepository),
    }))
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
