export default {
  en: {
    account: 'account',
    emailVerificationBeforeLogin: 'Please, verify your email before logging in',
    authorizationError: 'Authorization failed, please try again and if the error persist, contact to the customer service, thank you',
  },
  es: {
    account: 'cuenta',
    emailVerificationBeforeLogin: 'Por favor, verifica tu email antes de iniciar ssessión',
    authorizationError: 'Fallo the autorizacion, por favor vuelve a provar de nuevo y si el error persiste, contacta con atención al cliente, gracias',
  },
  fr: {
    account: 'compte',
    emailVerificationBeforeLogin: 'Veuillez vérifier votre adresse email avant de vous authentifier',
    authorizationError: `L'authorization a échoué, veuillez recommencer et si l'erreur persiste, merci de contacter le service client`,
  },
}
