export default {
  en: {
    carbonEmissions: 'Carbon emissions',
    importingAccounts: 'Importing 1 account | Importing {nb} accounts',
    emissions: 'Emissions',
    energy: 'Energy',
    energyConsumption: 'Energy consumption',
    erroredAccounts: '1 account import failure | {nb} accounts import failure',
    exclTax: 'Excl. tax',
    explore: 'Explore',
    exploreCosts: 'Explore costs',
    namingConventions: {
      title: 'Naming conventions',
      text: `Cycloid is using a unified naming convention approach for grouping. @:untranslated.gcp.alias's "@:untranslated.labels" are renamed to "@:untranslated.tags".`,
    },
    totalCost: 'Total cost',
    totalFor: 'Total for',
    compare: 'Compare',
    cost: 'Cost',
    credentialCanonical: 'Credential canonical',
    credentialName: 'Credential name',
    status: {
      enabled: 'enabled',
      disabled: 'disabled',
      errored: 'errored',
    },
    groupBy: {
      noGrouping: 'No grouping',
      service: 'Service',
      project: '@:Project',
      environment: '@:Environment',
      location: '@:untranslated.region',
      resource: '@:Resource',
      resourceType: '@:terracost.resource.type',
      instanceType: '@:terracost.resource.type',
      team: '@:Team',
      linkedAccount: 'Linked account',
      category: 'Category',
    },
    noGroupingCriteria: 'No {criteria}',
    noProject: 'No project',
    noEnvironments: 'No environments',
    prefix: 'Prefix',
    providerCredential: 'Provider credential',
    readonlyContent: {
      title: 'Readonly content',
      message: 'You can change for other existing credentials from the provider dropdown.',
    },
  },
  es: {
    carbonEmissions: 'Emisiones de carbono',
    importingAccounts: 'Importando 1 cuenta | Importando {nb} cuentas',
    emissions: 'Emisiones',
    energy: 'Energia',
    energyConsumption: 'Consumo energético',
    erroredAccounts: 'Error de importación de 1 cuenta | Error de importación de {nb} cuentas',
    exclTax: 'Sin impuestos',
    explore: 'Explorar',
    exploreCosts: 'Explorar costos',
    namingConventions: {
      title: 'Convenciones de nombres',
      text: `Cycloid está utilizando un enfoque de convención de nomenclatura unificada para agrupar. Las "@:untranslated.labels" de @:untranslated.gcp.alias se renombran como "@:untranslated.tags" (Etiquetas).`,
    },
    totalCost: 'Coste total',
    totalFor: 'Totales de',
    compare: 'Comparar',
    cost: 'Coste',
    credentialCanonical: 'Canonical de la credencial',
    credentialName: 'Nombre de la credencial',
    status: {
      enabled: 'habilitado',
      disabled: 'no habilitado',
      errored: 'error',
    },
    groupBy: {
      noGrouping: 'Sin agrupamiento',
      service: 'Servicio',
      project: '@:Project',
      environment: '@:Environment',
      location: '@:untranslated.region',
      resource: '@:Resource',
      resourceType: '@:terracost.resource.type',
      instanceType: '@:terracost.resource.type',
      team: '@:Team',
      linkedAccount: 'Cuenta vinculada',
      category: 'Categoría',
    },
    noGroupingCriteria: 'Sin {criteria}',
    noProject: 'Ningún proyecto',
    noEnvironments: 'Sin entornos',
    prefix: 'Prefijo',
    providerCredential: 'Credencial de proveedor',
    readonlyContent: {
      title: 'Contenido de solo lectura',
      message: 'Puede cambiar por otras credenciales existentes en el menú desplegable del proveedor.',
    },
  },
  fr: {
    carbonEmissions: 'Émissions de carbone',
    importingAccounts: `Importation d'un compte | Importation de {nb} comptes`,
    emissions: 'Émissions',
    energy: 'Énergie',
    energyConsumption: `Consommation d'énergie`,
    erroredAccounts: `Echec de l'importation d'un compte | Echec de l'importation de {nb} comptes`,
    exclTax: 'Hors taxes',
    explore: 'Explorer',
    exploreCosts: 'Explorer les coûts',
    namingConventions: {
      title: 'Conventions de nommage',
      text: `Cycloid utilise une convention de dénomination unifiée pour le regroupement. Les "@:untranslated.labels" @:untranslated.gcp.alias sont renommées "@:untranslated.tags".`,
    },
    totalCost: 'Coût total',
    totalFor: 'Total pour',
    compare: 'Comparer',
    cost: 'Coût',
    credentialCanonical: `Canonical de l'identifiant`,
    credentialName: `Nom d'identification`,
    status: {
      enabled: 'activé',
      disabled: 'désactivé',
      errored: 'erroné',
    },
    groupBy: {
      noGrouping: 'Pas de regroupement',
      service: 'Service',
      project: '@:Project',
      environment: '@:Environment',
      location: '@:untranslated.region',
      resource: '@:Resource',
      resourceType: '@:terracost.resource.type',
      instanceType: '@:terracost.resource.type',
      team: '@:Team',
      linkedAccount: 'Compte associé',
      category: 'Catégorie',
    },
    noGroupingCriteria: 'Sans {criteria}',
    noProject: 'Pas de projet',
    noEnvironments: `Aucun environnement`,
    prefix: 'Préfixe',
    providerCredential: 'Identifiant du fournisseur',
    readonlyContent: {
      title: 'Contenu en lecture seule',
      message: `Vous pouvez choisir d'autres informations d'identification existantes à partir de la liste déroulante du fournisseur.`,
    },
  },
}
