export default {
  remap (eb) {
    const engine = eb.configuration?.engine
    if (!engine) return eb
    eb.configuration.engine = {
      AWSRemoteTFState: 'AWSStorage',
      SwiftRemoteTFState: 'SwiftStorage',
      AzureRemoteTFState: 'AzureStorage',
      GCPRemoteTFState: 'GCPStorage',
      HTTPRemoteTFState: 'HTTPStorage',
      GitLabRemoteTFState: 'GitLabHTTPStorage',
    }[engine] || engine
    return eb
  },
}
