export default {
  start: {
    content: [
      'Hi {name}, welcome aboard!',
      `Today we’d like to present some basic concepts around Cycloid.io. It will take no more than 2 minutes.`,
    ],
    button: 'Let’s go',
  },
  organizations: {
    title: 'Organizations',
    content: [
      'Everything on Cycloid happens within an organization. You can invite people, and organize members in @:teams.',
      `You can define roles and granular policies to ensure that everyone has the right visibility. And you can set events to trace what's happening in real-time.`,
      'Organizations also provide centralized credential management and let you maintain your own Catalog repositories.',
    ],
  },
  catalogRepositories: {
    title: 'Catalog Repositories',
    content: [
      'A Catalog repository is a collection of Stacks, saved to a git repository. You can think of a stack as a template for several layers - Infrastructure, provisioning, and monitoring for instance.',
      `We maintain a curated collection of stacks, based on popular open-source technologies like Terraform, Ansible and Concourse. It's called the Cycloid public Catalog.`,
    ],
  },
  configRepository: {
    title: 'Config Repository',
    content: [
      `If a catalog repository is a place to store your stack, a config repository is a place to store your stack configuration: it's just a git repository where you can push the configuration of your stack for storage.`,
      `Now your stack repositories and configurations are in one place, so it's even easier to maintain and customize them.`,
    ],
  },
  privateCatalogs: {
    title: 'Private Catalogs',
    content: [
      `You can also maintain your own private collections of stacks for your company's specific needs. You write your own code and push it to your own catalog repository.`,
    ],
  },
  projects: {
    title: 'Projects',
    content: [
      'To create a project, start by choosing a Stack from the public catalog and defining the environments you want to deploy. Common choices would be Demo, Pre-prod or Prod, but you can name them whatever you want.',
      'We use variables to make stacks configurable for each environment. Just override the ones you need to change.',
    ],
  },
  pipelines: {
    title: 'Always know the state of your application',
    content: [
      `Use pipelines to monitor what's going on. A new commit to a repository, a Github release, a periodic trigger. You name it, the pipeline will monitor it.`,
      'Easily follow each commit across your workflow, and access details for each step. Jobs can be triggered automatically or manually through the interface. You can also disable automation or mix both approaches to have, for instance, an automated workflow that requires human approval for some steps.',
    ],
  },
  monitoring: {
    title: 'And much more',
    content: [
      'You can schedule environments for cost optimization, inspect your infrastructure details, get a visual display of all your project components or get a centralized view of all logging. The events timeline traces actions and the status of your projects so you always know what’s going on. And all within the same interface.',
      'Other features:',
    ],
    list: ['Centralized cost view', 'DevOps as a Service', 'Handle customer organizations'],
  },
  end: {
    content: [
      'Ok, that’s all for the overview. You should be able to reach for the clouds now (ahem, sorry about that)',
      'If you want more detail about what’s under the hood, take a look at the documentation:',
    ],
    links: [
      'Visit the documentation',
    ],
    button: 'Go to Cycloid.io',
  },
}
