export default {
  en: {
    logInNoticeAgreement: `By signing up, your agree to Cycloid's @:legal.termsOfService and @:legal.privacyPolicy`,
    termsOfService: 'terms of service',
    privacyPolicy: 'privacy policy',
  },
  es: {
    logInNoticeAgreement: 'Al registrarte, aceptas los @:legal.termsOfService y la @:legal.privacyPolicy de Cycloid',
    termsOfService: 'términos del servicio',
    privacyPolicy: 'política de privacidad',
  },
  fr: {
    logInNoticeAgreement: 'En vous enregistrant, vous acceptez les @:legal.termsOfService et la @:legal.privacyPolicy de Cycloid',
    termsOfService: `conditions d'utilisation`,
    privacyPolicy: 'politique de confidentialité',
  },
}
