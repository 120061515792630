import Vue from 'vue'
import { stripInvalidProperties, vuexMixin } from '@/utils/helpers'

export const initialState = {
  sidebar: {
    visible: true,
    collapsed: false,
  },
  isFullScreen: false,
  header: null,
  dataTables: {},
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  getDataTableProps: (state) => (pageName) => state.dataTables?.[pageName] ?? {},
  getDataTableFilters: (state) => (pageName) => state.dataTables?.[pageName]?.filters ?? {},
}

const {
  mutations: { RESET_STATE },
} = vuexMixin(initialState)

export const actions = {
  REMOVE_DATA_TABLE_FILTER ({ state, commit }, { name, filterType, filterValue }) {
    const filters = _.cloneDeep(state.dataTables[name].filters)

    if (filterType.includes('[in]')) {
      const filteredList = _.$get(state.dataTables[name].filters, filterType, '')
        .split(',')
        .filter((id) => id !== filterValue)
        .toString()

      _.$isEmpty(filteredList)
        ? delete filters[filterType]
        : filters[filterType] = filteredList
    } else {
      delete filters[filterType]
    }

    commit('SET_DATA_TABLE_FILTERS', { name, filters })
  },
}

export const mutations = {
  RESET_LAYOUT_STATE: RESET_STATE,

  TOGGLE_SIDEBAR_VISIBILITY (state) {
    Vue.set(state.sidebar, 'visible', !state.sidebar.visible)
  },

  TOGGLE_SIDEBAR_MODE (state) {
    Vue.set(state.sidebar, 'collapsed', !state.sidebar.collapsed)
  },

  TOGGLE_FULL_SCREEN (state) {
    Vue.set(state, 'isFullScreen', !state.isFullScreen)
  },

  // time-range-filter.vue subscribes to this dummy mutation to update the date range
  // it's needed to have the values reset to count from "now"
  UPDATE_FILTERS_TIME_RANGE (state, { name, time }) {
    Vue.set(state.dataTables[name], 'filters', { ...state.dataTables[name].filters, end: time })
  },

  RESET_DATA_TABLE_SEARCH (state, { name }) {
    Vue.set(state.dataTables[name], 'searchTerm', '')
  },

  RESET_DATA_TABLE_PAGE (state, { name }) {
    Vue.set(state.dataTables[name], 'page', 1)
  },

  RESET_DATA_TABLE_FILTERS (state, { name }) {
    Vue.set(state.dataTables[name], 'filters', {})
  },

  SET_DATA_TABLE_PROPS (state, { name, props }) {
    Vue.set(state.dataTables, name, props)
  },

  SET_DATA_TABLE_FILTERS (state, { name, filters }) {
    const processedFilters = stripInvalidProperties(filters)
    const dataTableProps = {
      ...state.dataTables[name],
      filters: _.$isEmpty(processedFilters) ? null : processedFilters,
    }
    Vue.set(state.dataTables, name, dataTableProps)
  },

  SET_HEADER (state, headerContent) {
    Vue.set(state, 'header', headerContent)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
