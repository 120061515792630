export default {
  en: {
    customPath: 'Custom Path',
    fieldPathHint: 'The path is the location where vault will store this credential. You can override it to scope the credential to a specific pipeline',
    fieldPathReadonly: 'This field is readonly and will be automatically populated by other fields. If you still need to edit it, please click on the lock',
    fieldTypeRawHint: 'You must provide at least 1 key pair',
    pathInvalid: 'Only -_/. and alphanumeric characters allowed',
  },
  es: {
    customPath: 'Ruta Personalizada',
    fieldPathHint: 'La ruta es la ubicación donde vault almacenará esta credencial. Puede anularla para extender la credencial a una canalización específica',
    fieldPathReadonly: 'Este campo es de solo lectura y se completará automáticamente con otros campos. Si aún necesita editarlo, haga clic en el candado',
    fieldTypeRawHint: 'Debes proporcionar al menos 1 par de llaves',
    pathInvalid: 'Solamente -_/. y caracteres alfanuméricos permitidos',
  },
  fr: {
    customPath: 'Chemin Personnalisé',
    fieldPathHint: `Le chemin désigne l'emplacement où seront stockées les données dans Vault. Vous pouvez le remplacer afin de rendre ces données accessibles à une pipeline seulement`,
    fieldPathReadonly: `Ce champ est en lecture seule et sera automatiquement rempli en fonction des autres champs. Si vous avez besoin de l'éditer, veuillez cliquer sur le cadenas`,
    fieldTypeRawHint: 'Vous devez fournir au moins 1 paire de clés',
    pathInvalid: 'Seulement -_/. et caractères alphanumériques autorisés',
  },
}
