import Vue from 'vue'
import { parseAggregateData } from '@/utils/api/parsers/cloud-cost-management/aggregateData'
import { parseProviderHistograms } from '@/utils/api/parsers/cloud-cost-management/providerHistograms'
import { parseTopProjects } from '@/utils/api/parsers/cloud-cost-management/topProjects'
import { vuexMixin } from '@/utils/helpers'
import { providersExtraInfo, buildFilterQueryString, parseHistogramDates, getAccountStatus } from '@/utils/helpers/cloud-cost-management'
import { $date } from '@/utils/plugins/date-fns'
import i18n from '@/utils/plugins/i18n'

export const persistedQueryFields = {
  dashboard: ['begin', 'end', 'currency', 'granularity'],
  providerDetail: ['begin', 'end', 'currency', 'granularity', 'group_by', 'projects'],
}

export const initialState = {
  account: null,
  accounts: [],
  aggregateData: {},
  credential: null,
  errors: {
    account: null,
    accounts: null,
    dashboard: null,
    gcpBillingDataset: null,
    parentAccount: null,
    providerData: null,
    tagMapping: null,
  },
  filterValues: {},
  graphOrderingParam: 'cost',
  graphTypes: {
    providerDetail: 'line',
    topProjects: 'line',
  },
  histogramDates: [],
  parentAccount: null,
  providerData: [],
  queryBody: {
    begin: $date.format($date.subDays(Date.now(), 7), 'yyyy-MM-dd'),
    end: $date.format(Date.now(), 'yyyy-MM-dd'),
    currency: 'EUR',
    granularity: 'day',
    group_by: [null, null],
  },
  tagMapping: null,
  topProjects: {
    histograms: {},
    resources: [],
  },
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  getProviderExtraInfo: () => providersExtraInfo,
  getProviderExtraInfoByCanonical: () => _.mapKeys(providersExtraInfo, ({ canonical }) => canonical),
  getProviderExtraInfoByCredType: () => _.mapKeys(providersExtraInfo, ({ credentialType }) => credentialType),
  getImportingAccounts: (state) => _.filter(state.accounts, ({ enabled, status }) => getAccountStatus(enabled, status) === 'importing'),
  getErroredAccounts: (state) => _.filter(state.accounts, ({ enabled, status }) => getAccountStatus(enabled, status) === 'errored'),
  hasTagMapping: (state) => {
    const {
      environment_tags: environmentTags,
      project_tags: projectTags,
      environment_regex: environmentRegex,
      project_regex: projectRegex,
    } = state.tagMapping || {}
    const environmentTagMappingIsDefined = !_.isEmpty(environmentTags) || environmentRegex
    const projectTagMappingIsDefined = !_.isEmpty(projectTags) || projectRegex
    return !!(environmentTagMappingIsDefined && projectTagMappingIsDefined)
  },
  isLinkedAccount: (state) => !_.isEmpty(state.parentAccount) || !_.isEmpty(state.account?.credential),
}

const {
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  async CREATE_ACCOUNT ({ commit, dispatch, rootGetters: { orgCanonical } }, { $router, account }) {
    commit('CLEAR_CE_ERRORS', 'account')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.createCloudCostManagementAccount(orgCanonical, account) || {}
    if (errors) commit('SET_ERRORS', { key: 'account', errors })
    else {
      if ($router) $router.push({ name: 'cloudCostManagementAccounts' })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.cloudCostManagement.account.created') }, { root: true })
      commit('RESET_CE_STATE', 'account')
    }
  },

  async CREATE_CHILD_ACCOUNT ({ commit, dispatch, rootGetters: { orgCanonical } }, { accountChild, $router }) {
    commit('CLEAR_CE_ERRORS', 'parentAccount')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.createCloudCostManagementAccountChild(orgCanonical, accountChild) || {}
    if (errors) commit('SET_ERRORS', { key: 'parentAccount', errors })
    else {
      if ($router) $router.push({ name: 'cloudCostManagementAccounts' })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.cloudCostManagement.account.linked') }, { root: true })
      commit('RESET_CE_STATE', 'parentAccount')
    }
  },

  async DELETE_ACCOUNT ({ commit, dispatch, rootGetters: { orgCanonical } }, { accountCanonical, $router }) {
    commit('CLEAR_CE_ERRORS', 'account')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteCloudCostManagementAccount(orgCanonical, accountCanonical) || {}
    if (errors) commit('SET_ERRORS', { key: 'account', errors })
    else {
      if ($router) $router.push({ name: 'cloudCostManagementAccounts' })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.cloudCostManagement.account.deleted', { accountCanonical }) }, { root: true })
      commit('RESET_CE_STATE', 'account')
    }
  },

  async GET_ACCOUNT ({ commit, rootGetters: { orgCanonical } }, { accountCanonical }) {
    commit('CLEAR_CE_ERRORS', 'account')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getCloudCostManagementAccount(orgCanonical, accountCanonical) || {}
    if (data) commit('SET_CE_DATA', { key: 'account', data })
    if (errors) commit('SET_ERRORS', { key: 'account', errors })
  },

  async GET_ACCOUNTS ({ commit, rootGetters: { orgCanonical } }, { reqPage = {} } = {}) {
    commit('CLEAR_CE_ERRORS', 'accounts')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getCloudCostManagementAccounts(orgCanonical, reqPage) || {}
    if (data) commit('SET_CE_DATA', { key: 'accounts', data })
    if (errors) commit('SET_ERRORS', { key: 'accounts', errors })
  },

  async GET_TAG_MAPPING ({ commit, rootGetters: { orgCanonical } }) {
    commit('CLEAR_CE_ERRORS', 'tagMapping')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getCloudCostManagementTagMapping(orgCanonical) || {}
    if (data) {
      commit('SET_CE_DATA', { key: 'tagMapping', data })
      commit('SET_CE_DATA', { key: 'tagMappingTags', data: data.all_tags })
    }
    if (errors) commit('SET_ERRORS', { key: 'tagMapping', errors })
  },

  async GET_PARENT_ACCOUNT ({ commit, rootGetters: { orgCanonical } }, { credentialCanonical }) {
    commit('SET_CE_DATA', { key: 'parentAccount', data: null })
    commit('CLEAR_CE_ERRORS', 'parentAccount')
    const queryString = `credential_canonical=${credentialCanonical}`
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getCloudCostManagementAccountsHasParent(orgCanonical, queryString) || {}
    if (data) commit('SET_CE_DATA', { key: 'parentAccount', data })
    if (errors) commit('SET_ERRORS', { key: 'parentAccount', errors })
  },

  async FETCH_DASHBOARD_DATA ({ state: { queryBody }, commit, rootGetters: { orgCanonical } }, { router, persistQuery = true }) {
    if (persistQuery) commit('UPDATE_URL_QUERY', router)
    commit('CLEAR_CE_ERRORS', 'dashboard')
    const queryString = buildFilterQueryString(queryBody)
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getCloudCostManagementDashboard(orgCanonical, queryString) || {}
    if (data) {
      const histogramDates = parseHistogramDates(queryBody)
      commit('SET_CE_DATA', { key: 'topProjects', data: parseTopProjects(data, histogramDates) })
      commit('SET_CE_DATA', { key: 'aggregateData', data: parseAggregateData(data, queryBody.granularity) })
      commit('SET_CE_DATA', { key: 'histogramDates', data: histogramDates })
      commit('SET_CE_DATA', { key: 'filterValues', data: data?.filter_values || {} })
    }
    if (errors) commit('SET_ERRORS', { key: 'dashboard', errors })
  },

  async FETCH_PROVIDER_DATA ({ state: { queryBody }, commit, rootGetters: { orgCanonical } }, { providerCanonical, router }) {
    commit('UPDATE_URL_QUERY', router)
    commit('CLEAR_CE_ERRORS', 'providerData')
    const queryString = buildFilterQueryString(queryBody)
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getCloudCostManagementProvider(orgCanonical, providerCanonical, queryString) || {}
    if (data) {
      commit('SET_CE_DATA', { key: 'providerData', data: parseProviderHistograms(data.cost_histogram) })
      commit('SET_CE_DATA', { key: 'histogramDates', data: parseHistogramDates(queryBody) })
      commit('SET_CE_DATA', { key: 'filterValues', data: data?.filter_values || {} })
    }
    if (errors) commit('SET_ERRORS', { key: 'providerData', errors })
  },

  async UPDATE_ACCOUNT ({ commit, dispatch, rootGetters: { orgCanonical } }, { $router, account, accountCanonical }) {
    commit('CLEAR_CE_ERRORS', 'account')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.updateCloudCostManagementAccount(orgCanonical, accountCanonical, account) || {}
    if (errors) commit('SET_ERRORS', { key: 'account', errors })
    else {
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.cloudCostManagement.account.updated') }, { root: true })
      if ($router) $router.push({ name: 'cloudCostManagementAccounts' })
      commit('RESET_CE_STATE', 'account')
    }
  },

  async UPDATE_TAG_MAPPING ({ commit, dispatch, rootGetters: { orgCanonical } }, { tagMapping }) {
    commit('CLEAR_CE_ERRORS', 'tagMapping')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateCloudCostManagementTagMapping(orgCanonical, tagMapping) || {}
    if (errors) commit('SET_ERRORS', { key: 'tagMapping', errors })
    if (data) dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.cloudCostManagement.tagMapping.updated') }, { root: true })
  },
}

export const mutations = {
  CLEAR_CE_ERRORS: CLEAR_ERRORS,
  RESET_CE_STATE: RESET_STATE,
  SET_ERRORS,

  READ_URL_QUERY (state) {
    const urlQueryParams = new URLSearchParams(window.location.search)
    const queryString = urlQueryParams.get('filters')
    state.queryBody = JSON.parse(queryString) || initialState.queryBody
  },

  UPDATE_URL_QUERY (state, router) {
    const filters = JSON.stringify(state.queryBody)
    const urlQueryParams = new URLSearchParams(window.location.search)
    urlQueryParams.set('filters', filters)
    history.replaceState(null, null, `?${urlQueryParams.toString()}`)
    // this line is necessary for the router query to be synched with the URL params, in order to react to its changes
    // the catch is to avoid duplicate navigation errors - it's not the best, but it's what is recommended by the dev team
    router.replace({ query: { filters } }).catch(() => { /* silenced */ })
  },

  SET_CE_DATA (state, { key, data }) {
    Vue.set(state, key, data)
  },

  SET_ACCOUNT_DATA (state, { key, data }) {
    Vue.set(state.account, key, data)
  },

  SET_GRAPH_ORDERING_PARAM (state, value) {
    Vue.set(state, 'graphOrderingParam', value)
  },

  SET_GRAPH_TYPE (state, { type, value }) {
    Vue.set(state.graphTypes, type, value)
    localStorage.setItem(LSK.CCM_GRAPHS_STATE, JSON.stringify(state.graphTypes))
  },

  SET_FILTER_VALUES (state, values) {
    Vue.set(state, 'filterValues', values)
  },

  SET_TAG_MAPPING (state, { key, data }) {
    Vue.set(state.tagMapping, key, data)
  },

  SET_QUERY_FILTER (state, { key, value }) {
    let filterValue = value
    if (_.isEmpty(value)) {
      state.queryBody = _.omit(state.queryBody, key)
      return
    }
    // Correctly sends to BE "" when "No project" value is selected
    if (key === 'projects' && _.includes(value, i18n.t('cloudCostManagement.noProject'))) {
      filterValue = [..._.without(value, i18n.t('cloudCostManagement.noProject')), '']
    }
    Vue.set(state.queryBody, key, filterValue)
  },

  RESET_QUERY_BODY (state, page = 'dashboard') {
    const persistedValues = _.pick(state.queryBody, persistedQueryFields[page])
    Vue.set(state, 'queryBody', { ..._.cloneDeep(initialState).queryBody, ...persistedValues })
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
