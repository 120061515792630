export default {
  en: {
    allocated: 'Allocated',
    capacity: 'Capacity',
    cores: 'Cores',
    cpu: 'CPU',
    cpuCores: 'CPU cores',
    addResourcePool: 'Add @:resourcePool',
    createQuota: 'Create quota',
    integer: 'Value must be an integer',
    memory: 'Memory',
    positiveOrZero: 'Value must be equal or greater than zero',
    resourceType: 'Resource type',
    requiresQuota: 'Requires quota',
    storage: 'Storage',
    nCores: '{n} core | {n} cores',
    unallocated: 'Unallocated',
    usedOf: {
      bytes: '{used} used of {of}',
      cores: '{used} used of {of} cores',
      short: {
        bytes: '{used} of {of}',
        cores: '{used} of {of} cores',
      },
    },
    quotaItem: 'Quota item',
    usage: 'Usage',
  },
  es: {
    allocated: 'Asignado',
    capacity: 'Capacidad',
    cores: 'Núcleos',
    cpu: 'CPU',
    cpuCores: 'Núcleos de CPU',
    addResourcePool: 'Añadir un @:resourcePool',
    createQuota: 'Añadir quota',
    integer: 'El valor debe ser un número entero',
    memory: 'Memoria',
    positiveOrZero: 'El valor debe ser igual o mayor que cero',
    resourceType: 'Tipo de recurso',
    requiresQuota: 'Requiere quota',
    storage: 'Almacenamiento',
    nCores: '{n} núcleo | {n} núcleos',
    unallocated: 'No asignado',
    usedOf: {
      bytes: '{used} usados de {of}',
      cores: '{used} usados de {of} núcleos',
      short: {
        bytes: '{used} de {of}',
        cores: '{used} de {of} núcleos',
      },
    },
    quotaItem: 'Elemento de quota',
    usage: 'Uso',
  },
  fr: {
    allocated: 'Aalloué',
    capacity: 'Capacité',
    cores: 'Coeurs',
    cpu: 'CPU',
    cpuCores: 'Coeurs de CPU',
    addResourcePool: 'Créer un @:resourcePool',
    createQuota: 'Ajouter un quota',
    integer: 'La valeur doit être un entier',
    memory: 'Mémoire',
    positiveOrZero: 'La valeur doit être égale ou supérieure à zéro',
    resourceType: 'Type de ressource',
    requiresQuota: 'Requiert des quota',
    storage: 'Stockage',
    nCores: '{n} coeur | {n} coeurs',
    unallocated: 'Non alloué',
    usedOf: {
      bytes: '{used} utilisés sur {of}',
      cores: '{used} utilisés sur {of} coeurs',
      short: {
        bytes: '{used} sur {of}',
        cores: '{used} sur {of} coeurs',
      },
    },
    quotaItem: 'Elément de quota',
    usage: 'Uso',
  },
}
