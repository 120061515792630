import Vue from 'vue'
import { decodeJWT, vuexMixin } from '@/utils/helpers'

export const initialState = {
  currentScopeDepth: 0,
  scopes: {
    0: {
      canonical: '',
      jwt: null,
    },
  },
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  getParentList: (state) => {
    const orgsArray = Object.values(state.scopes)
    return _.without(orgsArray, _.last(orgsArray))
  },
  isInCustomerScope: (state) => state.currentScopeDepth !== 0,
  currentScope: (state) => ({
    ...state.scopes[state.currentScopeDepth],
    jwtOrgCanonical: state.scopes[state.currentScopeDepth].jwt
      ? decodeJWT(state.scopes[state.currentScopeDepth].jwt.split('.')[1])?.cycloid?.organization?.canonical
      : null,
  }),
  currentOrgScope: (state, _getters, rootState, rootGetters) => ({
    canonical: rootGetters.orgCanonical,
    jwt: rootState.auth.jwt,
    jwtOrgCanonical: rootGetters['auth/jwtDecoded']?.organization?.canonical ?? null,
    name: rootGetters.orgName,
    fullPath: Object.values(state.scopes).map(({ name }) => _.capitalize(name)).join(' / '),
  }),
  isJWTInSync: (_state, { currentScope, currentOrgScope }) => currentScope.jwtOrgCanonical === currentOrgScope.jwtOrgCanonical,
}

export const actions = {
  async LOGIN_AS_CUSTOMER ({ commit, dispatch, rootState }, { canonical, name }) {
    await dispatch('CHANGE_ORG_SCOPE', { nextCanonical: canonical })

    commit('INCREMENT_SCOPE_DEPTH')
    commit('SET_CURRENT_SCOPE', { canonical, name, jwt: rootState.auth.jwt })
  },

  async LOGIN_AS_PARENT ({ commit, dispatch, state }, { canonical }) {
    const scopeDepth = _.findKey(state.scopes, ['canonical', canonical])

    await dispatch('CHANGE_ORG_SCOPE', { nextCanonical: canonical })
    commit('SET_SCOPE_DEPTH', { scopeDepth })
    commit('REMOVE_CUSTOMER_SCOPES')
  },

  async LOGIN_AS_HIGHER_ORG ({ commit, dispatch, rootState }, { canonical, scopeDepth, name }) {
    await dispatch('CHANGE_ORG_SCOPE', { nextCanonical: canonical })
    commit('SET_SCOPE_DEPTH', { scopeDepth })
    commit('SET_CURRENT_SCOPE', { canonical, name, jwt: rootState.auth.jwt })
    commit('REMOVE_CUSTOMER_SCOPES')
  },

  async LOGIN_AS_SIBLING ({ commit, dispatch, rootState }, { canonical, name }) {
    await dispatch('CHANGE_ORG_SCOPE', { nextCanonical: canonical })

    commit('SET_CURRENT_SCOPE', { canonical, name, jwt: rootState.auth.jwt })
  },

  async GET_ANCESTORS ({ commit, dispatch }, { orgCanonical }) {
    const { data: ancestors = [] } = await Vue.prototype.$cycloid.ydAPI.getOrgAncestors(orgCanonical) || {}

    // the org we do the query with is also returned in the response
    if (ancestors.length > 1) {
      const [{ canonical: prevCanonical }] = ancestors
      await dispatch('CHANGE_ORG_SCOPE', { prevCanonical, nextCanonical: orgCanonical })
      commit('RECREATE_CUSTOMERS', ancestors)
    }
  },

  async CHANGE_ORG_SCOPE ({ dispatch, state, rootState }, { prevCanonical, nextCanonical }) {
    const query = {
      organization_canonical: prevCanonical || state.scopes[0].canonical,
      ...(nextCanonical ? { child_canonical: nextCanonical } : {}),
    }

    await dispatch('auth/REFRESH_TOKEN', { query }, { root: true })
    const jwt = rootState.auth.jwt
    if (jwt) await dispatch('UPDATE_USER_SESSION', jwt, { root: true })
    await dispatch('organization/GET_ORGANIZATION', nextCanonical, { root: true })
  },
}

const {
  mutations: { RESET_STATE },
} = vuexMixin(initialState)

export const mutations = {
  RESET_CUSTOMERS_STATE: RESET_STATE,

  RECREATE_CUSTOMERS (state, ancestors) {
    const scopes = Object.fromEntries(ancestors.map(({ canonical }, i) => [i, { canonical, jwt: null }]))

    Vue.set(state, 'scopes', scopes)
    Vue.set(state, 'currentScopeDepth', ancestors.length - 1)
  },

  SET_CURRENT_SCOPE (state, scope) {
    const alreadyExists = _.values(state.scopes).some(({ canonical, jwt }) => _.isEqual(scope.canonical, canonical) && _.isEqual(scope.jwt, jwt))
    if (!alreadyExists) Vue.set(state.scopes, state.currentScopeDepth, scope)
  },

  INCREMENT_SCOPE_DEPTH (state) {
    state.currentScopeDepth++
  },

  SET_SCOPE_DEPTH (state, { scopeDepth = 0 } = {}) {
    if (isNaN(Number(scopeDepth))) throw new Error('[SET_SCOPE_DEPTH]: was passed a scopeDepth that is not a Number', scopeDepth)
    Vue.set(state, 'currentScopeDepth', Number(scopeDepth))
  },

  REMOVE_CUSTOMER_SCOPES (state) {
    for (const key in state.scopes) {
      if (Number(key) > state.currentScopeDepth && Number(key) !== 0) Vue.delete(state.scopes, key)
    }
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
