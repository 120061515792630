import * as dateFns from 'date-fns' // eslint-disable-line no-restricted-imports
import * as fpDateFns from 'date-fns/fp' // eslint-disable-line no-restricted-imports
import { enGB as en, es, fr } from 'date-fns/locale' // eslint-disable-line no-restricted-imports
import i18n from './i18n'

export const $date = {
  ...dateFns,
  // https://github.com/date-fns/date-fns/issues/1532#issuecomment-599940816
  fp: fpDateFns,
  /** Returns String noting how long ago a timestamp was.
   * Uses i18n.locale to populate the correct locale phrasing.
   *
   * @example [strict=true] '11 hours ago'
   * @example [strict=false]'about 1 year ago'
  */
  $formatTimeAgo (value, { strict = true } = {}) {
    const locale = { en, es, fr }[i18n.locale]
    const date = $date.$getTimestamp(value)
    if (!date) return null
    return strict
      ? i18n.t('time.ago', [dateFns.formatDistanceToNowStrict(date, { locale })])
      : i18n.t('time.ago', [dateFns.formatDistanceToNow(date, { locale })])
  },

  /** Returns a timestamp in ms (13 chars) or s (10 chars).
   *
   * @example [returnSeconds=true]  1650651285      # returns s
   * @example [returnSeconds=false] 1650651285735   # returns ms
   */
  $getTimestamp (value, { returnSeconds = false } = {}) {
    if (_.$isEmpty(value)) return null
    const date = _.$isPrimitive(value) ? new Date(Number(`${value}`.padEnd(13, '0'))) : value
    return Number(dateFns.format(date, returnSeconds ? 't' : 'T'))
  },
}

window.$date = $date

export default {
  install (Vue) {
    Vue.prototype.$date = $date
    Vue.mixin({
      // So these will be available to each template too.
      computed: {
        /* eslint-disable vue/no-unused-properties */
        $date: () => $date,
        $dateLocale: () => ({ en, es, fr }[i18n.locale]), // ? needed for some date-fns that use { locale } option
        /* eslint-enable vue/no-unused-properties */
      },
    })
  },
}
