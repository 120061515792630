const propagateDateToChildBuckets = ({ cost, co2e, kwh, value, date, buckets: childBuckets }) => {
  // add date: Timestamp to all children of current bucket
  const childBucketsWithDates = _.map(childBuckets, (bucket) => ({ ...bucket, date }))

  // call current method recursively on all children of current bucket
  const newBuckets = _.map(childBucketsWithDates, propagateDateToChildBuckets)

  // return bucket with its cost, co2e, kwh and date, and add value and/or buckets if they exist
  return { cost, co2e: co2e * 1000, kwh, ...(value ? { value } : {}), date, ...(newBuckets.length ? { buckets: newBuckets } : {}) }
}

const mergeBucketsByCanonical = (buckets) => {
  // group buckets by canonical so they are all grouped according to value
  const bucketsGroupedByCanonical = _.groupBy(buckets, 'value')

  // method called on each group of buckets, merging their costs and dates into a histogram array, and merging their children arrays together
  const mergeToCanonicalHistograms = (buckets) => {
    const reducedBuckets = _.reduce(buckets, (acc, { cost, co2e, kwh, value, date, buckets }) => {
      return {
        // value property is converted to canonical
        canonical: value,

        // the cost and date of each bucket are mapped to an object inside the values array
        values: [...acc.values, { cost, co2e, kwh, date }],

        children: buckets ? [...acc.children, buckets] : [...acc.children],
      }
    }, { values: [], children: [] })

    // if there are no children, remove key from result
    return !_.isEmpty(reducedBuckets.children) ? reducedBuckets : _.omit(reducedBuckets, ['children'])
  }

  // calls the merge method on object grouped by canonicals
  const mergedBuckets = _.map(_.values(bucketsGroupedByCanonical), mergeToCanonicalHistograms)

  // if the current bucket level does not have any children, we can end the recursion
  if (_.isEmpty(mergedBuckets) || !_.head(mergedBuckets).children) return mergedBuckets

  // otherwise we flatten the children of the current bucket, to be the correct format to pass to the recursion
  const mergedBucketsWithFlattenedChildren = _.map(mergedBuckets, (bucket) => ({
    ...bucket,
    ...(bucket.children ? { children: _.flatten(bucket.children) } : {}),
  }))

  // we call the current function recursively on each of the current buckets, to merge them into the desired structure
  return _.map(mergedBucketsWithFlattenedChildren, (bucket) => ({
    ...bucket,
    ...(bucket.children ? { children: mergeBucketsByCanonical(bucket.children) } : {}),
  }))
}

const parseProviderHistograms = ({ buckets }) => {
  // for all the top level buckets converts value: String to date: Timestamp
  const treeWithTimestampDates = _.map(buckets, ({ cost, co2e, kwh, value, buckets }) => ({ cost, co2e: co2e * 1000, kwh, date: $date.parseISO(value.slice(0, 10), new Date()).getTime(), buckets }))

  // if top level buckets have no children (we are not grouping by anything), clean up bucket data and simply return it
  const allChildBucketsAreEmpty = !_.some(treeWithTimestampDates, ({ buckets = [] }) => !_.isEmpty(buckets))
  if (allChildBucketsAreEmpty) return [{ values: _.map(treeWithTimestampDates, ({ cost, co2e, kwh, date }) => ({ cost, co2e, kwh, date })) }]

  // go through bucket tree, and propagate date timestamp from top level parents to all their children
  const treeWithAddedDatesDeep = _.map(treeWithTimestampDates, propagateDateToChildBuckets)

  // create flattened array of all the second level buckets
  const firstLevelGroupingBuckets = _.flatten(_.reduce(treeWithAddedDatesDeep, (acc, { buckets }) => ([...acc, ...(buckets || [])]), []))

  // recursively traverses the flattened buckets array and its children, forming them into the desired data structure
  return mergeBucketsByCanonical(firstLevelGroupingBuckets)
}

const parseToGraphFormat = (providerData, histogramDates) => {
  const providerDataWithAllDates = _.map(providerData, ({ canonical, values }) => ({
    canonical,
    values: _.map(histogramDates, (date) => ({
      date,
      cost: _.get(_.find(values, ['date', date]), 'cost', 0),
      co2e: _.get(_.find(values, ['date', date]), 'co2e', 0),
      kwh: _.get(_.find(values, ['date', date]), 'kwh', 0),
    })),
  }))
  return _.mapValues(_.groupBy(providerDataWithAllDates, 'canonical'), ([{ values }]) => values)
}

export {
  parseProviderHistograms,
  parseToGraphFormat,
}
