import _ from '@/utils/lodash'
import account from './account'
import alerts from './alerts'
import apiErrors from './api-errors'
import cities from './cities'
import cloudCostManagement from './cloud-cost-management'
import credentials from './credentials'
import environment from './environment'
import errors from './errors'
import events from './events'
import externalBackends from './external-backends'
import forms from './forms'
import infra from './infra'
import inventory from './inventory'
import kpis from './kpis'
import lang from './lang'
import legal from './legal'
import licence from './licence'
import pipeline from './pipeline'
import pluralizations from './pluralizations'
import presentation from './presentation'
import quotas from './quotas'
import roles from './roles'
import routes from './routes'
import severities from './severities'
import sorts from './sorts'
import stacks from './stacks'
import terracost from './terracost'
import time from './time'
import workers from './workers'

const commonCycloidTerms = {
  Activity: 'Activité',
  APIKey: 'Clé API',
  APIKeys: 'Clés API',
  Appearance: 'Apparence',
  CatalogRepositories: 'Sources du catalogue',
  CatalogRepository: 'Source du catalogue',
  ConfigRepositories: 'Sources de configuration',
  ConfigRepository: 'Source de configuration',
  Credential: `Information d'identification`,
  Credentials: `Informations d'identification`,
  Dashboard: 'Tableau de bord',
  Environment: 'Environnement',
  Environments: 'Environnements',
  Events: 'Évènements',
  InfraImport: 'Infra Import',
  InfraPolicy: 'InfraPolicy',
  InfraPolicies: 'InfraPolicies',
  Inventory: 'Inventaire',
  Member: 'Membre',
  Members: 'Membres',
  Metrics: 'Métriques',
  Organization: 'Organisation',
  Organizations: 'Organisations',
  Project: 'Projet',
  Projects: 'Projets',
  Quota: 'Quota',
  Quotas: 'Quotas',
  Resource: 'Ressource',
  Resources: 'Ressources',
  ResourcePool: 'Pool de ressources',
  ResourcePools: 'Pools de ressources',
  Role: 'Rôle',
  Roles: 'Rôles',
  Stack: 'Stack',
  Stacks: 'Stacks',
  Team: 'Équipe',
  Teams: 'Équipes',
  TerraformBackend: 'Backend Terraform',
}

for (const key in commonCycloidTerms) {
  const camelCaseKey = _.camelCase(key)
  commonCycloidTerms[camelCaseKey] = commonCycloidTerms[key].toLowerCase()
}

const fr = {
  all: 'Tout',
  and: 'et',
  assignOwner: 'Attribuer un propriétaire',
  clone: 'Cloner',
  collapse: 'réduire',
  comingSoon: 'bientôt disponible',
  comparedToLastWeek: 'Par rapport à la semaine dernière',
  configuration: 'Configuration',
  configure: 'Configurer',
  date: 'Date',
  Details: 'Détails',
  Default: 'Défaut',
  development: 'développement',
  disabled: 'Désactivée',
  editor: 'Éditeur',
  enabled: 'Activée',
  from: 'de',
  fullScreen: 'Plein écran',
  general: 'Général',
  icon: 'Icône',
  importing: 'importation',
  invite: 'Invitez',
  learnMore: 'En savoir plus',
  name: 'Nom',
  noOwner: 'aucun propriétaire',
  or: 'ou',
  others: 'Autres',
  owner: 'Propriétaire',
  preview: 'Aperçu',
  provider: 'Fournisseur',
  ReadMore: 'Lire plus',
  seeMore: 'voir plus',
  Settings: 'Paramètres',
  Summary: 'Résumé',
  welcomeMsg: `Bienvenue sur l'App Cycloid`,
  youAreUsing: 'Vous utilisez',
  youDoNotHavePermissionsTo: 'Vous ne disposez pas des autorisations suffisantes pour',
  ...commonCycloidTerms,
  ...pluralizations,
}

export default {
  ...fr,
  account: account.fr,
  alerts: alerts.fr,
  apiErrors: apiErrors.fr,
  cities: cities.en,
  cloudCostManagement: cloudCostManagement.fr,
  credentials: credentials.fr,
  environment: environment.fr,
  errors: errors.fr,
  events: events.fr,
  externalBackends: externalBackends.fr,
  forms: forms.fr,
  infra: infra.fr,
  inventory: inventory.fr,
  kpis: kpis.fr,
  lang: lang.fr,
  legal: legal.fr,
  licence: licence.fr,
  pipeline: pipeline.fr,
  presentation: presentation.fr,
  quotas: quotas.fr,
  roles: roles.fr,
  routes: routes.fr,
  severities: severities.fr,
  sorts: sorts.fr,
  stacks: stacks.fr,
  terracost: terracost.fr,
  time: time.fr,
  workers: workers.fr,
}
