import Vue from 'vue'
import { vuexMixin } from '@/utils/helpers'

export const initialState = {
  error: [],
  warning: [],
  success: [],
  info: [],
}
const STATE = _.cloneDeep(initialState)

const {
  mutations: { RESET_STATE },
} = vuexMixin(initialState)

const GETTERS = {
  allAlertsOrderedByTimestamp: ({ error, warning, success, info }) => [
    ...getOrderedByTimestampAlerts({ error }),
    ...getOrderedByTimestampAlerts({ warning, success, info }),
  ],
}

export const actions = {
  async SHOW_ALERT ({ commit, dispatch }, { type, title, content, keepOpen = type === 'error' }) {
    const timestamp = Date.now()
    const { aborted = false } = commit('ADD_ALERT', { type, timestamp, title, content }) || {}

    if (keepOpen || aborted) return

    await _.$pause(getTimeout({ title, content }))
    dispatch('HIDE_ALERT', { type, timestamp })
  },

  async HIDE_ALERT ({ commit }, { type, timestamp }) {
    const types = _.keys(initialState)
    if (!types.includes(type)) return console.error(`HIDE_ALERT was passed an incorrect type`, { type })
    if (_.$isEmpty(timestamp)) return console.error(`HIDE_ALERT was passed an empty timestamp`, { timestamp })
    await _.$pause(300)
    commit('REMOVE_ALERT', { type, timestamp })
  },
}

export const mutations = {
  RESET_ALERT_STATE: RESET_STATE,

  /**
   * @param {Object} state
   * @param {(
   *   {
   *     type: 'error' | 'warning' | 'success' | 'info',
   *     timestamp: Number,
   *     title: String,
   *   }
   * |
   *   {
   *     type: 'error' | 'warning' | 'success' | 'info',
   *     timestamp: Number,
   *     content: String | Object<String, any> | Array<Object>,
   *   }
   * |
   *   {
   *     type: 'error' | 'warning' | 'success' | 'info',
   *     timestamp: Number,
   *     title: String,
   *     content: String | Object<String, any> | Array<Object>,
   *   }
   * )} [options={}]
   */
  ADD_ALERT (state, { type, timestamp, title, content }) {
    const missingRequiredFields = _.some([type, timestamp, title || content], _.$isEmpty)
    const problem = {
      missingRequiredFields: missingRequiredFields && {
        message: `Missing required fields: ${_.transform({ type, timestamp, title, content }, (acc, hasVal, key) => {
          if (hasVal) return
          acc.push(`\n- ${key}: ${hasVal}`)
        }, []).join('')}`,
      },
      duplicateAlert: _.find(state[type], { title, content }),
    }

    for (const aborted of _.values(problem)) {
      if (!aborted) continue
      if (aborted?.message) console.error(`[ADD_ALERT] Aborted: ${aborted.message}`)
      return { aborted: true }
    }

    state[type].push({ timestamp, title, content })
  },

  REMOVE_ALERT (state, { type, timestamp }) {
    const index = _.findIndex(state?.[type], ['timestamp', timestamp])
    if (index >= 0) Vue.delete(state[type], index)
  },
}

export function getTimeout ({ title = '', content = '' } = {}) {
  const charactersPerSecond = 25
  const chunks = _.chunk(`${title}.${JSON.stringify(content)}`.split(''), charactersPerSecond).length
  return (chunks * 1000) + 1500
}

function getOrderedByTimestampAlerts (alertObject = {}) {
  return _(alertObject)
    .entries()
    .map(([type, alertList]) => alertList.map((alert) => ({ ...alert, type })))
    .flatten()
    .orderBy(['timestamp'], ['desc'])
    .value()
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
