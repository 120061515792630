export const hoverClasses = 'sibling-of-hovered'

function addClassesToSiblings (el, ...classes) {
  el.previousElementSibling?.classList.add(classes)
  el.nextElementSibling?.classList.add(classes)
}

function removeClassesFromSiblings (el, ...classes) {
  el.previousElementSibling?.classList.remove(classes)
  el.nextElementSibling?.classList.remove(classes)
}

function hoverStartHandler () {
  addClassesToSiblings(this, hoverClasses)
}

function hoverEndHandler () {
  removeClassesFromSiblings(this, hoverClasses)
}

export default {
  install (Vue) {
    Vue.directive('hover-siblings', {
      bind (el) {
        el.addEventListener('mouseover', hoverStartHandler)
        el.addEventListener('mouseleave', hoverEndHandler)
      },
      unbind: function (el) {
        el.removeEventListener('mouseover', hoverStartHandler)
        el.removeEventListener('mouseleave', hoverEndHandler)
      },
    })
  },
}
