const parseProviderBucket = ({ co2e, cost, kwh, value, buckets }, format) => ({
  // TODO: FE#6428 remove amazon to aws conversion once BE response allows
  canonical: value === 'amazon' ? 'aws' : value,
  co2e: co2e * 1000,
  cost,
  kwh,
  co2eData: buckets.map(({ co2e, value }) => ({ co2e: co2e * 1000, date: $date.parse(value, format, new Date()).getTime() })),
  costData: buckets.map(({ cost, value }) => ({ cost, date: $date.parse(value, format, new Date()).getTime() })),
  kwhData: buckets.map(({ kwh, value }) => ({ kwh, date: $date.parse(value, format, new Date()).getTime() })),
})

const parseAggregateData = ({ providers: { co2e, cost, kwh, buckets } }, granularity) => {
  const format = granularity === 'month' ? 'yyyy-MM' : 'yyyy-MM-dd'
  return {
    co2e: co2e * 1000,
    cost,
    kwh,
    begin: _.head(buckets) ? $date.parse(_.head(_.head(buckets).buckets).value, format, new Date()).getTime() : 0,
    end: _.head(buckets) ? $date.parse(_.last(_.head(buckets).buckets).value, format, new Date()).getTime() : 0,
    providerDatas: buckets.map((bucket) => parseProviderBucket(bucket, format)),
  }
}

export { parseAggregateData }
