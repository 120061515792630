export default {
  en: {
    type: {
      AWS: '@:untranslated.aws.alias',
      Custom: 'custom',
      Cycloid: 'cycloid',
      Monitoring: '@.lower:infra.Monitoring',
    },
    noEventsFound: 'No events found',
    tryDifferent: 'Try different time ranges and filters.',
  },
  es: {
    type: {
      AWS: '@:untranslated.aws.alias',
      Custom: 'personalizado',
      Cycloid: 'cycloid',
      Monitoring: '@.lower:infra.Monitoring',
    },
    noEventsFound: 'No se encontraron eventos',
    tryDifferent: 'Pruebe diferentes rangos de tiempo y filtros.',
  },
  fr: {
    type: {
      AWS: '@:untranslated.aws.alias',
      Custom: 'personnalisé',
      Cycloid: 'cycloid',
      Monitoring: '@.lower:infra.Monitoring',
    },
    noEventsFound: 'Aucun événement trouvé',
    tryDifferent: `Essayez d'autres plages de temps et filtres.`,
  },
}
