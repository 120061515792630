export default class RGBColor {
  constructor (rgbObject = console.error('[RGBColor] Required param is missing: { r, g, b }.')) {
    if (!_.some([_.isPlainObject(rgbObject), rgbObject instanceof RGBColor])) {
      return console.error('[RGBColor] Required param must be an Object: { r, g, b }.', {
        passedParam: rgbObject,
      })
    }

    const { r, g, b } = _.cloneDeep(rgbObject)
    Object.assign(this, { r, g, b })
  }

  get cssString () {
    const { r, g, b } = this
    return `rgb(${r}, ${g}, ${b})`
  }

  get cssValues () {
    const { r, g, b } = this
    return `${r} ${g} ${b}`
  }

  get rgbObject () {
    return _.pick(this, ['r', 'g', 'b'])
  }

  get isDark () {
    const { r, g, b } = this
    const brightness = (r * 0.299) + (g * 0.587) + (b * 0.114)
    const normalizedBrightness = Math.round((brightness / 255) * 100)
    return normalizedBrightness <= 60
  }

  get contrastingColor () {
    return this.isDark
      ? new RGBColor({ r: 255, g: 255, b: 255 })
      : new RGBColor({ r: 0, g: 0, b: 0 })
  }
}
