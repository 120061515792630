import ResizeObserver from 'resize-observer-polyfill'

export default {
  install (Vue) {
    /** Resize Observer
     * A directive to listen for size change.
     *
     * @example
     * // Default usage, callback is internally throttled by 500ms
     * // as observers can be quite taxing otherwise.
     * <component v-resize-observer="callback"/>
     * @example
     * // Custom throttle delay (in ms)
     * <component v-resize-observer:300="callback"/>
     * @example
     * // Disable throttling
     * <component v-resize-observer.no_throttle="callback"/>
     */
    Vue.directive('resize-observer', {
      inserted (el, { value: callback, arg, modifiers }, { context: component }) {
        if (!_.isFunction(callback)) {
          console.warn(`[resize-observer] was not passed a function`, callback)
          return
        }

        // Avoid throttling the callback if the 'no_throttle' modifier is passed.
        if (!_.get(modifiers, 'no_throttle', false)) {
          const delay = Number.parseInt(arg) || 500 // Delay defaults to 500ms
          callback = _.throttle(callback, delay)
        }

        const observer = new ResizeObserver(callback)
        const parentNode = el.parentNode
        observer.observe(parentNode)
        component.$once('hook:beforeDestroy', () => {
          observer.unobserve(parentNode)
        })
      },
    })
  },
}
