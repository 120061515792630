import Vue from 'vue'
import mainActions from 'static/policies/actions.json'
import alphaActions from 'static/policies/alpha-actions.json'

const Actions = { ...alphaActions, ...mainActions }

const checkUserPermissionsError = '[checkUserPermissions] Passed invalid action, please correct it'
const canDisplayError = `[checkUserPermissions] was passed an Array.

Arrays are invalid. Instead pass params normally:

e.g. checkUserPermissions('UpdateItem')
e.g. checkUserPermissions('UpdateItem', item.id)
`

/** Cycloid permissions checker
 *
 * @typedef {Object} cycloid~permissions - An object which contains:
 *                                          - canDisplay method to check if the user has permission to perform an action on a resource.
 *                                          - actions system defined user actions example: { CreateOrg: { policies: ['organization:create'] } }
 */

/** Adds key property with name value to each action.
 * It is used to be able to target specific action by name.
 *
 * @returns {object} Actions
 */
export function mapActionNameToKeyPerAction () {
  const actions = {
    ...Actions,
  }

  for (const key of _.keys(actions)) actions[key].key = key

  return actions
}

export const systemDefinedActions = mapActionNameToKeyPerAction()

/** Checks whether the user can perform the given actions.
 *
 * This function calls canDo() to allow or forbid the user triggered action.
 * If action is triggered on specific resource it scopes evaluation to a specified resource only.
 * It returns the boolean value depending of the outcome of evaluation call.
 * Returns false if something goes wrong and policyAction or vuex store instance is not passed down.
 *
 * @param {Object}  store         - The Vuex store instance.
 * @param {String}  action        - The permission key. (e.g. 'GetOrgs')
 * @param {String?} identifier    - *optional* An entity id. (e.g. canonical, id, ref, etc.)
 *
 *
 * @example
 * checkUserPermissions(store, 'GetOrgs')
 * checkUserPermissions(store, 'GetOrg', 'cycloid')
 *
 * @returns {Boolean}                   Whether user can access that entity/endpoint
 */
export function checkUserPermissions ({ state, getters }, action, identifier) {
  const invalidActions = getInvalidActions(action)
  if (invalidActions) console.error(checkUserPermissionsError, invalidActions)

  if (getters.isOrgAdmin) return true
  if (!state.auth.evaluatedActions?.[action]) return false

  const { ok = false, entityCanonicals = [] } = state.auth.evaluatedActions?.[action] ?? {}

  return _.some([identifier, entityCanonicals], _.isEmpty)
    ? ok
    : checkEntityCanonicalMatch(entityCanonicals, identifier)
}

export function checkEntityCanonicalMatch (entityCanonicals, identifier) {
  return _.some(entityCanonicals, (entityCanonical) => {
    return new RegExp(`^${entityCanonical.replace('*', '.*')}$`).test(identifier)
  })
}

/**
 * Get required actions for a given route
 *
 * @param {object} store         - Vuex store instance
 * @param {object} router        - Vue router instance
 * @param {object} routeLocation - Route location
 *
 * @example                      - getRouteRequiredActions(store, router, { name: 'events' })
 * @returns {array}              - The actions that a user must have to visit the given route
 */
function getRouteRequiredActions (store, router, routeLocation) {
  if (!store || !router) return []
  const { route } = router.resolve(routeLocation)
  const requiredActions = _.get(route, 'meta.requiredActions', [])
  const computedActions = (_.isFunction(requiredActions))
    ? requiredActions(store)
    : requiredActions
  return _.without(computedActions, ..._.keys(alphaActions))
}

/**
 * Check the users permissions against the given route
 *
 * @param {object} store         - Vuex store instance
 * @param {object} router        - Vue router instance
 * @param {object} routeLocation - Route location
 *
 * @example                      - checkUserRoutePermissions(store, router, { name: 'events' })
 * @returns {boolean}            - Does the user have sufficient permissions to access the given route?
 */
function checkUserRoutePermissions (store, router, routeLocation) {
  if (!store || !router) return false
  if (_.isString(routeLocation)) console.warn(`[checkUserRoutePermissions] routeLocation should be an object in the { name: 'routeName' } format. (passed '${routeLocation}')`)
  const routeRequiredActions = getRouteRequiredActions(store, router, routeLocation)
  return canPerformActions(store, routeRequiredActions)
}

/**
 * Check the users permissions against a set of policy actions
 *
 * @param {object} store             - Vuex store instance
 * @param {array} [policyActions=[]] - Array of policy actions to check
 *
 * @example                          - canPerformActions(store, ['GetProjects', 'GetEvents'])
 * @returns {boolean}                - Does the user have sufficient permissions to perform all the given actions?
 */
function canPerformActions ({ state, getters }, policyActions = []) {
  if (!_.isArray(policyActions)) return false
  if (!getters.hasOrgs || getters.isOrgAdmin) return true

  return _.every(policyActions, (action) => state.auth.evaluatedActions?.[action]?.ok ?? false)
}

function getInvalidActions (action) {
  const validKeys = _.keys(Actions)
  const invalidKeys = _.without([action], ...validKeys)
  return _.isEmpty(invalidKeys) ? null : invalidKeys
}

/** Create an object which has canDisplay method to check different kind of permissions
 * that will be stored in the vuex store.
 *
 * @param {Vuex} store    - Vuex store instance
 * @param {object} router - Vue router instance
 *
 * @returns {cycloid~permissions} - A new instance which uses the provided store
 */
export default function createPermissionChecker (store, router) {
  const devFeaturePermission = {
    localBE: {},
    nonLocalBE: {
      /* e.g. GetAppearance: true, */
    },
  }
  const devFeatureRoutePermission = {
    localBE: {},
    nonLocalBE: {
      /* e.g. orgSettingsAppearance: true, */
    },
  }

  const handleFeaturePermission = (action) => _.every([
    store.state.dev.showDevThings,
    Vue.prototype.$isLocalBE
      ? _.has(devFeaturePermission.localBE, action)
      : _.has(devFeaturePermission.nonLocalBE, action),
  ])
  const handleFeatureRoutePermission = (routeLocation) => _.every([
    store.state.dev.showDevThings,
    Vue.prototype.$isLocalBE
      ? _.has(devFeatureRoutePermission.localBE, routeLocation)
      : _.has(devFeatureRoutePermission.nonLocalBE, routeLocation),
  ])

  return {
    /** This method is called to determine which items should be disabled or hidden
     * based on existence of evaluated action in auth.evaluatedActions object.
     *
     * @param {object} policyAction - The triggered action with specified resource if action is performed on specific entity item.
     *
     * @returns {boolean}
     */
    canDisplay: (action, identifier) => {
      if (_.isArray(action)) return console.error(canDisplayError)

      // dev features
      if (handleFeaturePermission(action)) {
        return Vue.prototype.$isLocalBE
          ? devFeaturePermission.localBE[action]
          : devFeaturePermission.nonLocalBE[action]
      }

      // fully implemented features
      return identifier
        ? checkUserPermissions(store, action, identifier)
        : checkUserPermissions(store, action)
    },

    /**
     * Check the users permissions against the given route
     *
     * @param {*} routeLocation - Route location
     *
     * @returns {boolean}       - Does the user have sufficient permissions to access the given route?
     */
    canDisplayRoute: (routeLocation) => {
      // dev features
      if (handleFeatureRoutePermission(routeLocation)) {
        return devFeatureRoutePermission[routeLocation]
      }

      // fully implemented features
      return checkUserRoutePermissions(store, router, routeLocation)
    },

    /**
     * Get required actions for a given route
     *
     * @param {*} routeLocation - Route location
     *
     * @returns {array}         - The actions that a user must have to visit the given route
     */
    getRouteRequiredActions: (routeLocation) => getRouteRequiredActions(store, router, routeLocation),
  }
}
