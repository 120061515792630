export default {
  en: {
    anErrorOccurred: '<b>An error occurred while importing data for this metric.</b>',
    averageBuildTime: 'Average build time',
    averageBuildTimeLegend: 'Get the average build duration and visualize its progression in time.',
    buildFrequency: 'Build frequency',
    buildFrequencyLegend: 'Get the total number of build executed and visualize its progression in time.',
    buildHistory: 'Build history',
    buildHistoryLegend: 'A summary of the most recent builds, with date, duration and status.',
    codeCoverage: 'Code coverage',
    codeCoverageLegend: 'A gauge that helps you understand how much of your source code is tested.',
    successVSFailure: 'Success vs failure',
    successVSFailureLegend: 'Display build statuses ratio.',
    timeToRelease: 'Time to release',
    timeToReleaseLegend: 'Track the time versions take to go through your pipelines.',
  },
  es: {
    anErrorOccurred: '<b>Se produjo un error al importar los datos para esta métrica.</b>',
    averageBuildTime: 'Tiempo medio de build',
    averageBuildTimeLegend: 'Obtenga la duración promedio de build y visualice su progresión en el tiempo.',
    buildFrequency: 'Frecuencia de build',
    buildFrequencyLegend: 'Obtenga el número total de build ejecutadas y visualice su progresión en el tiempo.',
    buildHistory: 'Historia de build',
    buildHistoryLegend: 'Un resumen de los builds más recientes, con fecha, duración y estado.',
    codeCoverage: 'Cobertura de código',
    codeCoverageLegend: 'Un indicador que le ayuda a comprender qué parte de su código fuente se prueba.',
    successVSFailure: 'Éxito vs fracaso',
    successVSFailureLegend: 'Mostrar proporción de estados de build.',
    timeToRelease: 'Tiempo de lanzamiento',
    timeToReleaseLegend: 'Realice un seguimiento del tiempo que las versiones tardan en pasar por sus pipelines.',
  },
  fr: {
    anErrorOccurred: `<b>Une erreur s'est produite lors de l'importation des données pour cette métrique</b>`,
    averageBuildTime: 'Durée moyenne de build',
    averageBuildTimeLegend: 'Obtenez la durée moyenne de build et visualisez sa progression dans le temps.',
    buildFrequency: 'Fréquence de build',
    buildFrequencyLegend: 'Obtenez le nombre total de build exécutés et visualisez sa progression dans le temps.',
    buildHistory: 'Historique de build',
    buildHistoryLegend: 'Un résumé des builds les plus récents, avec date, durée et statut.',
    codeCoverage: 'Couverture de code',
    codeCoverageLegend: 'Une jauge qui vous aide à comprendre dans quelle mesure votre code source est testé.',
    successVSFailure: 'Succès vs échec',
    successVSFailureLegend: 'Affiche le ratio des statuts de build',
    timeToRelease: 'Durée de release',
    timeToReleaseLegend: 'Suivez le temps que prennent les versions pour parcourir vos pipelines',
  },
}
