export default {
  en: {
    amsterdam: 'Amsterdam',
    athens: 'Athens',
    barcelona: 'Barcelona',
    bordeaux: 'Bordeaux',
    boston: 'Boston',
    brussels: 'Brussels',
    buenosAires: 'Buenos Aires',
    chicago: 'Chicago',
    houston: 'Houston',
    istanbul: 'Istanbul',
    lisbon: 'Lisbon',
    london: 'London',
    losAngeles: 'Los Angeles',
    madrid: 'Madrid',
    marseille: 'Marseille',
    moscow: 'Moscow',
    newYork: 'New York',
    paris: 'Paris',
    perth: 'Perth',
    reykjavik: 'Reykjavik',
    rome: 'Rome',
    sanFrancisco: 'San Francisco',
    sydney: 'Sydney',
    warsaw: 'Warsaw',
  },
  es: {
    amsterdam: 'Ámsterdam',
    athens: 'Atenas',
    barcelona: 'Barcelona',
    bordeaux: 'Burdeos',
    boston: 'Bostón',
    brussels: 'Bruselas',
    buenosAires: 'Buenos Aires',
    chicago: 'Chicago',
    houston: 'Houston',
    istanbul: 'Estanbul',
    lisbon: 'Lisboa',
    london: 'Londres',
    losAngeles: 'Los Angeles',
    madrid: 'Madrid',
    marseille: 'Marsella',
    moscow: 'Moscú',
    newYork: 'Nueva York',
    paris: 'París',
    perth: 'Perth',
    reykjavik: 'Reikiavik',
    rome: 'Roma',
    sanFrancisco: 'San Francisco',
    sydney: 'Sídney',
    warsaw: 'Varsovia',
  },
  fr: {
    amsterdam: 'Amsterdam',
    athens: 'Athènes',
    barcelona: 'Barcelone',
    bordeaux: 'Bordeaux',
    boston: 'Boston',
    brussels: 'Bruxelles',
    buenosAires: 'Buenos Aires',
    chicago: 'Chicago',
    houston: 'Houston',
    istanbul: 'Istanbul',
    lisbon: 'Lisbonne',
    london: 'Londres',
    losAngeles: 'Los Angeles',
    madrid: 'Madrid',
    marseille: 'Marseille',
    moscow: 'Moscou',
    newYork: 'New York',
    paris: 'Paris',
    perth: 'Perth',
    reykjavik: 'Reykjavik',
    rome: 'Rome',
    sanFrancisco: 'San Francisco',
    sydney: 'Sydney',
    warsaw: 'Varsovie',
  },
}
