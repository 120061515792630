/** Used by vuex mixin BULK_DELETE, to map entities to api endpoints */
export const DELETE = {
  apiKeys: 'deleteAPIKey',
  appearances: 'deleteAppearance',
  catalogRepositories: 'deleteServiceCatalogSource',
  configRepositories: 'deleteConfigRepository',
  credentials: 'deleteCredential',
  externalBackends: 'deleteExternalBackend',
  infraPolicies: 'deleteInfraPolicy',
  invites: 'deleteInvitation',
  members: 'removeOrgMember',
  projects: 'deleteProject',
  project: {
    environments: 'deleteProjectEnvironment',
    kpis: 'deleteKPI',
    pipelines: 'deletePipeline',
  },
  roles: 'deleteOrgRole',
  stacks: 'deleteServiceCatalog',
  teamMembers: 'unassignMemberFromTeam',
  teams: 'deleteTeam',
  watchRules: 'deleteWatchRule',
}

/** Used by vuex mixin FETCH_AVAILABLE, to map entities to api endpoints */
export const GET = {
  apiKeys: 'getAPIKeys',
  appearances: 'listAppearances',
  catalogRepositories: 'getServiceCatalogSources',
  childOrgs: 'getOrgChildren',
  cloudProviders: 'getCloudProviders',
  configRepositories: 'getConfigRepositories',
  credentials: 'listCredentials',
  environments: 'getEnvironments',
  events: 'getOrgEvents',
  externalBackends: 'getExternalBackends',
  infraPolicies: 'getInfraPolicies',
  inventoryResources: 'getInventoryResources',
  invites: 'getOrgMembers',
  labels: 'listResourceLabels',
  members: 'getOrgMembers',
  pipelines: 'getPipelines',
  policies: 'getPolicies',
  projects: 'getProjects',
  project: {
    kpis: 'getKPIs',
    pipelines: 'getProjectPipelines',
  },
  quotas: 'listQuotas',
  resourcePools: 'listResourcePools',
  resourcesByLabel: 'listResourcesByLabel',
  resourceTypes: 'listResourceTypes',
  roles: 'getOrgRoles',
  stacks: 'getServiceCatalogs',
  tags: 'getOrgEventsTags',
  teamMembers: 'getTeamMembers',
  teams: 'getTeams',
  workers: 'getWorkers',
}

export default {
  DELETE,
  GET,
}
