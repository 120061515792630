/* eslint-disable vue/no-unused-properties */
import i18n from '@/utils/plugins/i18n'

export default {
  install (Vue) {
    Vue.mixin({
      methods: {
        /** Super-tiny helper to act as a wrapper for i18n.t & this.$t
         *
         * @returns the translated string, if the path exists, or null if it doesn't
         */
        $tFind (initialPath) {
          const pathExists = _.get(i18n.messages[this.$i18n.locale], initialPath)
          return pathExists ? i18n.t(initialPath) : null
        },

        /** Super-tiny helper to act as a wrapper for i18n.tc & this.$tc
         *
         * @returns the translated string, if the path exists, or null if it doesn't
         */
        $tcFind (initialPath, ...rest) {
          const pathExists = _.get(i18n.messages[this.$i18n.locale], initialPath)
          return pathExists ? i18n.tc(initialPath, ...rest) : null
        },
      },
    })
  },
}
