import RGBColor from '@/utils/classes/RGBColor'

export default class Appearance {
  #orgCanonical

  constructor (orgCanonical = null, {
    canonical = '',
    color = {},
    created_at = 0,
    display_name = '',
    favicon = '',
    footer = '',
    is_active = false,
    logo = '',
    name = '',
    tab_title = '',
    updated_at = 0,
  } = {}) {
    Object.assign(this, {
      canonical,
      color: new RGBColor(color),
      created_at,
      display_name,
      favicon,
      footer,
      is_active,
      logo,
      name,
      tab_title,
      updated_at,
    })

    this.#orgCanonical = orgCanonical
  }

  get orgCanonical () {
    return this.#orgCanonical
  }

  get isRoot () {
    return _.isNull(this.#orgCanonical)
  }
}
