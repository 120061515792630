import { untranslatedRoutes } from './routes'

/**
 * This file holds "translations" that don't change between languages.
 *
 * We decided to do this to stop allowing for text floating in templates and being unsure if it should be translated or not.
 * ! If you want to add text to a component, but it shouldn't be translated, add it here.
 *
 * @slackDecision https://cycloid-io.slack.com/archives/CBVU4QRBL/p1580985112005300
 */

export default {
  apiUrl: 'api_url',
  aws: {
    alias: 'AWS',
    name: 'Amazon Web Services',
  },
  azure: 'Microsoft Azure',
  bareMetal: 'Bare Metal',
  beta: 'beta',
  billingDataset: 'Billing dataset',
  billingTable: 'Billing table',
  blob: 'Blob',
  blobStorage: 'Blob Storage',
  bucket: 'Bucket',
  build: 'Build',
  canonical: 'Canonical',
  CIDR: 'CIDR',
  ciTeamName: 'ci_team_name',
  container: 'Container',
  containerName: 'Container name',
  co2e: 'KgCO<sub>2</sub>e',
  customerTag: 'Customer Tag',
  dataset: 'Dataset',
  default: 'Default',
  docker: 'Docker',
  endpoint: 'Endpoint',
  exclVAT: 'Excl. VAT',
  finops: 'FinOps',
  flexeng: 'Flexible Engine',
  freeTrial: 'Free trial',
  gcp: {
    alias: 'GCP',
    name: 'Google Cloud Platform',
  },
  gitlab: 'GitLab',
  gitlabUrl: 'GitLab URL',
  greenops: 'GreenOps',
  id: 'ID',
  job: 'Job',
  json: 'JSON',
  JWT: 'JWT',
  key: 'Key',
  label: 'Label',
  labels: 'Labels',
  metadata: 'Metadata',
  new: 'New',
  none: 'none',
  object: 'Object',
  output: 'Output',
  owner: 'Owner',
  pipeline: 'Pipeline',
  plan: 'Plan',
  policies: 'Policies',
  region: 'Region',
  regoPolicy: 'Rego policy',
  root: 'Root',
  S3ForcePathStyle: 'S3ForcePathStyle',
  self: 'self',
  SG: 'SG',
  stack: 'Stack',
  stacks: 'Stacks',
  subnet: 'Subnet',
  tags: 'Tags',
  teamId: 'Team ID',
  terracost: 'TerraCost',
  terraform: 'Terraform',
  total: 'Total',
  URL: 'URL',
  vaultRoleId: 'vault_role_id',
  vaultSecretId: 'vault_secret_id',
  workerKey: 'Worker Key',
  schedulerHost: 'Scheduler host',
  schedulerHostHint: 'Cycloid CI scheduler host address (concourse_tsa_host)',
  schedulerPort: 'Scheduler port',
  schedulerPortHint: 'Cycloid CI scheduler port (concourse_tsa_port)',
  schedulerPublicTSAKey: 'Scheduler public TSA key',
  schedulerPublicTSAKeyHint: 'Cycloid CI scheduler public key (concourse_tsa_public_key)',
  schedulerAPIAddress: 'Scheduler API address',
  kwh: 'kWh',
  ...untranslatedRoutes,
}
