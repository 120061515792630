const cannotBeChangedError = (propName) => new Error(`${propName} cannot be changed`)

/** Class representing any predictable internal error.
 * It represents a predictable internal error which is suitable for logging
 * or be passed through any of the intermediate elements, callers, scopes, etc.,
 * empowering to be handled accordingly or to add metadata fro providing more
 * accurate context before reporting it (logs, etc.).
 * It's suitable to be caught and recreated if that's a requirement, however
 * because of the metadata feature, the most of the cases it isn't needed.
 * If you need an error to have this features but also of being able to report
 * to the user, @see AppError below.
 *
 * @param {string} message - The system error. It's the message provided to
 *            the native Error constructor
 * @param {string} userMessage - The friendly user message which will be
 *            shown to the user. NOTE the message must be localized before
 *            by the caller.
 * @param {string} type - A string which identifies a error type, for making
 *            easier to filter them or execute different actions. NOTE the
 *            accepted values are defined in 'types' object exported by this
 *            module
 * @param {Object} metadata - An object with metadata which can be relevant
 *             for the error.
 * @throws {Error} - if 'type' is a value which does not exist in 'types'
 *            object or metadata isn't an object
 * @see types defined in this module
 */
export function InternalError (message, type = types.APP, metadata = {}) {
  if (!typesSet.has(type)) {
    throw new Error(`Invalid error type. ${type} does not exist`)
  }

  if (!message) {
    throw new Error('error message is required')
  }

  if ((typeof metadata !== 'object') || !metadata) {
    throw new Error('metadata is not an object')
  }

  Object.defineProperties(this, {
    name: {
      enumerable: true,
      configurable: true,
      get: () => 'InternalError',
      set: () => { throw cannotBeChangedError('name') },
    },
    stack: {
      enumerable: true,
      get: () => (new Error()).stack,
      set: () => { throw cannotBeChangedError('stack') },
    },
    message: {
      enumerable: true,
      get: () => message,
      set: () => { throw cannotBeChangedError('message') },
    },
    type: {
      enumerable: true,
      get: () => type,
      set: () => { throw cannotBeChangedError('type') },
    },
    code: {
      get: () => 'AppError',
    },
    metadata: {
      enumerable: true,
      get: () => Object.assign({}, metadata),
      set: () => { throw cannotBeChangedError('metadata') },
    },
    /** Add metadata to a current object.
     * This function isn't in the prototype because of the limitations of not
     * having already available Proxy (ES2015) by the babel transpiler
     * @link http://babeljs.io/learn-es2015/#ecmascript-2015-features-proxies
     * @see {Function} mergeProp
     */
    addMetadata: {
      enumerable: false,
      writable: false,
      value (propName, ...propValues) {
        mergeProp(metadata, propName, ...propValues)
      },
    },
  })
}

/** Create a new InternalError instance from a simple Error.
 * It's basically a helper to create the InternalError an attach the original
 * error into the "error" metadata property name.
 *
 * @param {Error} error - An instance of an Error
 * @returns {InternalError} - The created internal error
 * @see InternalError constructor for the rest of the parameters
 */
InternalError.fromError = function (error, message, type = types.APP, metadata = {}) {
  const internalErr = new InternalError(message, type, metadata)
  internalErr.addMetadata('error', error)

  return internalErr
}

InternalError.prototype = Object.create(Error.prototype)

/** Get the metadata in text, using key=value format
 * NOTE the metadata values is only iterate on the keys directly assigned,
 * in case that a values is an Object (nested), it will be stringified to JSON,
 * all the values are wrapped by single quotes
 */
InternalError.prototype.metaText = function () {
  return Object.keys(this.metadata).reduce((text, k) => {
    const raw = this.metadata[k]
    const v = (typeof raw === 'object') ? JSON.stringify(raw) : raw

    if (text) {
      text += ','
    }

    return `${text}${k}='${v}'`
  }, '')
}

/** Class representing any predictable application error.
 * It represents a predictable application error which is suitable to be
 * reported to the user.
 * The rest of the attributes and methods are inherited from the parent, @see
 * InternalError above
 * In comparison with an internal error it's not suitable to be modified or
 * be caught and recreated.
 *
 * @extends InternalError
 */
export class AppError extends InternalError {
  /** AppError constructor
   * @param {string} message          - @see InternalError message param
   * @param {string} userMessage      - The friendly user message which will be shown to the user.
   *                                    NOTE: the message must be localized before by the caller.
   * @param {string} [type=types.APP] - @see InternalError type param
   * @param {Object} [metadata={}]    - @see InternalError metadata param
   * @throws {Error}                  - if 'type' is a value which does not exist in 'types' object
   *                                    or metadata isn't an object or userMessage isn't provided.
   *                                    @see types defined in this module
   */
  constructor (message, userMessage, type = types.APP, metadata = {}) {
    super(message, type, metadata)

    if (!userMessage) {
      throw new Error('user friendly error message is required')
    }

    Object.defineProperties(this, {
      name: {
        enumerable: true,
        configurable: true,
        get: () => 'AppError',
        set: () => { throw cannotBeChangedError('name') },
      },
      userMessage: {
        enumerable: true,
        get: () => userMessage,
        set: () => { throw cannotBeChangedError('userMessage') },
      },
    })
  }

  /** Create a new AppError instance from a simple Error.
   * It's basically a helper to create the AppError an attach the original
   * error into the "error" metadata property name.
   *
   * @param {Error} error - An instance of an Error
   * @returns {AppError} - The created internal error
   * @see AppError constructor for the rest of the parameters
   */
  static fromError (error, message, userMessage, type = types.APP, metadata = {}) {
    const appErr = new AppError(message, userMessage, type, metadata)
    appErr.addMetadata('error', error)

    return appErr
  }

  /** Create a new AppError instance from an InternalError.
   * It's basically a helper to create the AppError with the information that
   * an internal error already has and add the user message which is the one
   * which doesn't exist in an internal error.
   *
   * @param {InternalError} iErr - An instance of an InternalError
   * @param {string} userMessage - The friendly user message
   *            @see AppError constructor
   * @returns {AppError} - The created internal error
   */
  static fromInternalError (iErr, userMessage) {
    return new AppError(iErr.message, userMessage, iErr.type, iErr.metadata)
  }
}

export const types = {
  APP: 'application',
  AUTH: 'authorization',
  SYS: 'system',
  API: 'api',
  BUG: 'bug',
}

const typesSet = new Set(Object.keys(types).map((k) => types[k]))

function mergeProp (obj, propName, ...propValues) {
  if (propValues.length === 0) {
    throw new Error('At least one value must be provided to the property')
  }

  if (!(propName in obj)) {
    if (propValues.length === 1) {
      obj[propName] = propValues[0]
    } else {
      obj[propName] = propValues
    }

    return
  }

  if (Array.isArray(obj[propName])) {
    obj[propName].push(...propValues)
    return
  }

  propValues.unshift(obj[propName])
  obj[propName] = propValues
}
