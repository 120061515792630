import Vue from 'vue'
import { vuexMixin } from '@/utils/helpers'
import i18n from '@/utils/plugins/i18n'

export const initialState = {
  notifications: [],
  watchRules: [],
  watchRule: null,
}

const {
  mutations: { SET_ERRORS, CLEAR_ERRORS, RESET_STATE },
} = vuexMixin(initialState)

const STATE = _.cloneDeep(initialState)

export const actions = {
  async GET_NOTIFICATIONS ({ commit, dispatch }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.listNotifications() || {}
    if (data) commit('SET_NOTIFICATIONS', data)
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
  },

  async GET_WATCH_RULES ({ commit, dispatch }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.listWatchRules() || {}
    if (data) commit('SET_WATCH_RULES', data)
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
  },

  async GET_WATCH_RULE ({ commit, dispatch }, { canonical }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getWatchRule(canonical) || {}
    if (data) commit('SET_WATCH_RULE', data)
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
  },

  async CREATE_WATCH_RULE ({ dispatch }, { watchRule, $router }) {
    const { errors } = await Vue.prototype.$cycloid.ydAPI.createWatchRule(watchRule) || {}
    if (errors) return dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    const content = i18n.t('alerts.success.watchRule.created')
    dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    $router.push({ name: 'userWatchRules' })
  },

  async UPDATE_WATCH_RULE ({ commit, dispatch }, { watchRuleCanonical, watchRule }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateWatchRule(watchRuleCanonical, watchRule) || {}
    if (errors) return dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    commit('SET_WATCH_RULE', data)
    const content = i18n.t('alerts.success.watchRule.updated')
    dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
  },

  async DELETE_WATCH_RULE ({ dispatch }, { watchRuleCanonical }) {
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteWatchRule(watchRuleCanonical) || {}
    if (errors) return dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    const content = i18n.t('alerts.success.watchRule.deleted')
    dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
  },
}

export const mutations = {
  CLEAR_NOTIFICATION_ERRORS: CLEAR_ERRORS,
  RESET_NOTIFICATION_STATE: RESET_STATE,
  SET_NOTIFICATION_ERRORS: SET_ERRORS,

  SET_NOTIFICATIONS (state, notifications) {
    Vue.set(state, 'notifications', notifications)
  },

  SET_WATCH_RULES (state, watchRules) {
    Vue.set(state, 'watchRules', watchRules)
  },

  SET_WATCH_RULE (state, watchRule) {
    Vue.set(state, 'watchRule', watchRule)
  },
}

export {
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  actions,
  mutations,
}
