import xregexp from 'xregexp'

export default {
  AWS_VALID_KEY: /.*-aws-billing-detailed-line-items-with-resources-and-tags-.*/,
  AWS_VALID_MANIFEST_KEY: /.*-Manifest.json$/,
  AZURE_CONTAINER_NAME: /^(?=.{3,63}$)[a-z0-9]+(-[a-z0-9]+)*$/,
  BUILD_LOG_HASH: /^#([a-zA-Z0-9\-/]*)-(\d{0,10})/,
  CG: {
    IS_RAW: /.+[{[<>].+/,
    RULE_NAME: /(^[\d_a-z-]+$)/,
    RULE_PORTS: /^\d+-?\d*$/,
  },
  CREDENTIAL_PATH: /(^[\w_\-./]+$)/,
  ENV_NAME: /^[\da-zA-Z]+(?:[\da-zA-Z\-._]+[\da-zA-Z]|[\da-zA-Z])$/,
  GIT: /^((https?|git|ssh):\/\/|git@).*(\/)+([\w.-])*$/,
  ID_PREFIX: /(^(\d+-)*)/,
  INFRA_POLICY_NAME: /^[a-zA-Z0-9]+(\s+|[_-]?[a-zA-Z0-9])*$/,
  IS_IMAGE: /.+(png|gif|jpg)$/,
  PROFILE_NAME: xregexp('^[\\p{L}| -]*$'),
  PROJECT_NAME: /^\w+([\s_-]*\w)*$/,
  ROUTE: {
    PARAM_CATEGORY: /^(\w+(Canonical|Id|Name|Ref))$/,
  },
  STACK_TEMPLATE: /\(\$-?\s(?!project.*|environment|organization_canonical|scs.*|stack_path|cr_.*|inventory_jwt|api_url|console_url|use_case)(.*?)\$\)/,
  TF: {
    COUNT_KEYS: /.*[#|%].*/,
    MODULE_PATH: /(module\.[\w-]+\.)/g,
  },
  URL_SCHEME: /^https?:\/\//,
}
