export default function populate ({ stencilGroups, stencil }) {
  return {
    stencil () {
      Object.entries(stencilGroups).forEach(([providerId, { resources, dataSources }]) => {
        if (providerId === 'vault') stencil.loadGroup(Object.values(resources), `${providerId}-all-resources`)
        else {
          stencil.loadGroup([], `${providerId}-all-resources`)
          for (const [category, resource] of Object.entries(resources)) stencil.loadGroup(resource, `${providerId}-${category}-resources`)
        }
        stencil.loadGroup(Object.values(dataSources), `${providerId}-all-datasources`)
      })
    },
  }
}
