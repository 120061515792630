/**
 * GTM events definition by section:
 * https://docs.google.com/spreadsheets/d/1uMaykG7hP1n4QKT6HOS6m0Eyg3gi_O4Tv0pcWymA-qc/edit#gid=0
 */

/** Section: Logins **/
export const gtmLoginEvents = {
  login: {
    event: 'login',
    category: 'Form_login',
    action: 'submitFormSuccess',
    label: 'Login',
  },
  email: {
    event: 'email',
    category: 'Field_Email',
    action: 'input_active',
    label: 'Login',
  },
  password: {
    event: 'Password',
    category: 'Field_Password',
    action: 'input_active',
    label: 'Login',
  },
  forgotPassword: {
    event: 'Forgot password',
    category: 'ForgotPwd',
    action: 'click',
    label: 'ForgotPwd',
  },
  signupHyperlink: {
    event: 'Signup hyperlink',
    category: 'SignUpStep',
    action: 'click',
    label: 'SignUpStep',
  },
  createAccount: {
    event: 'Create Account ',
    category: 'Form_createAccount',
    action: 'submitFormSuccess',
    label: 'CreateAccount',
  },
  loginHyperlink: {
    event: 'Login hyperlink',
    category: 'LoginStep',
    action: 'click',
    label: 'LoginStep',
  },
  // deprecated
  createNewOrganization: {
    event: 'Create new organization',
    category: 'Form_newOrg',
    action: 'submitFormSuccess',
    label: 'Organizations',
  },
  startFreeTrial: {
    event: 'Start free trial',
    category: 'Org_FreeTrial',
    action: 'click',
    label: 'FreeTrial',
  },
  contactUs: {
    event: 'Contact us',
    category: 'OrgsContactUs',
    action: 'click',
    label: 'ContactUs',
  },
}

/** Section: Header **/
export const gtmHeaderEvents = {
  // deprecated
  headNewReleases: {
    event: 'New Releases',
    category: 'Head_NewReleases',
    action: 'click',
    label: 'Header',
  },
  // deprecated
  headNewReleasesLatest: {
    event: 'New Releases - Latest',
    category: 'Head_NewReleases_Latest',
    action: 'click',
    label: 'Header',
  },
  // deprecated
  headNewReleasesPrevious: {
    event: 'New Releases - Previous',
    category: 'Head_NewReleases_Previous',
    action: 'click',
    label: 'Header',
  },
  headNewReleasesSeeAll: {
    event: 'New Releases - See all',
    category: 'Head_NewReleases_SeeAll',
    action: 'click',
    label: 'Header',
  },
  headNewReleasesHelpDropDown: {
    event: 'Help drop-down',
    category: 'Head_Help',
    action: 'click',
    label: 'Header',
  },
  headNewReleasesDocumentation: {
    event: 'Help drop-down - Documentation',
    category: 'Head_Help_Documentation',
    action: 'click',
    label: 'Header',
  },
  headRoadmap: {
    event: 'Roadmap',
    category: 'Head_Roadmap',
    action: 'click',
    label: 'Header',
  },
}

/** Section: Collapse **/
export const gtmCollapseEvents = {
  mainMenuCollapse: {
    event: 'Main menu collapse',
    category: 'MN_collapse',
    action: 'click',
    label: 'MN',
  },
  mainMenuExpand: {
    event: 'Main menu expand',
    category: 'MN_expand',
    action: 'click',
    label: 'MN',
  },
}

/** Section: Organizations **/
export const gtmOrganizationsEvents = {
  // deprecated
  organizations: {
    event: 'Organizations',
    category: 'MN_Orgs',
    action: 'click',
    label: 'MN_Orgs',
  },
  organizationsCreateNewOrganization: {
    event: 'Organizations - Create new organization',
    category: 'MN_Orgs_CreateNewOrg',
    action: 'click',
    label: 'MN_Orgs',
  },
  // deprecated
  customers: {
    event: 'Customers',
    category: 'MN_Customers',
    action: 'click',
    label: 'MN_Orgs',
  },
  // deprecated
  organizationsOrganizations: {
    event: 'Organizations - Organizations',
    category: 'MN_Org',
    action: 'click',
    label: 'MN_Orgs',
  },
  organizationsGeneral: {
    event: 'Organizations - General',
    category: 'MN_Org_General',
    action: 'click',
    label: 'MN_Orgs',
  },
  // deprecated
  organizationsGeneralDelete: {
    event: 'Organizations - General - Delete',
    category: 'MN_Org_General_Delete',
    action: 'click',
    label: 'MN_Orgs',
  },
  organizationsWorkers: {
    event: 'Organizations - Workers',
    category: 'MN_Org_Workers',
    action: 'click',
    label: 'MN_Orgs',
  },
  // deprecated
  organizationsWorkersLearnMore: {
    event: 'Organizations - Workers - Learn more',
    category: 'NN_Org_Workers_LearnMore',
    action: 'click',
    label: 'MN_Orgs',
  },
  organizationsMembers: {
    event: 'Organizations - Members',
    category: 'MN_Org_Members',
    action: 'click',
    label: 'MN_Orgs',
  },
  organizationsMembersInviteMembers: {
    event: 'Organizations - Members - Invite members',
    category: 'MN_Org_Members_InviteMembers',
    action: 'click',
    label: 'MN_Orgs',
  },
  organizationsTeams: {
    event: 'Organizations - Teams',
    category: 'MN_Org_Teams',
    action: 'click',
    label: 'MN_Orgs',
  },
  organizationsCreateNewTeam: {
    event: 'Organizations - Create a new team',
    category: 'MN_Org_Teams_CreateNewTeam',
    action: 'click',
    label: 'MN_Orgs',
  },
}

/** Section: Dashboard **/
export const gtmDashboardEvents = {
  // deprecated
  dashboard: {
    event: 'Dashboard',
    category: 'MN_Dashboard',
    action: 'click',
    label: 'MN_Dashboard',
  },
  // deprecated
  dashboardProjects: {
    event: 'Dashboard Projects',
    category: 'Dashboard_Projects',
    action: 'click',
    label: 'Dashboard',
  },
  // deprecated
  dashboardPipelines: {
    event: 'Dashboard Pipelines',
    category: 'Dashboard_Pipelines',
    action: 'click',
    label: 'Dashboard',
  },
  // deprecated
  dashboardCredentials: {
    event: 'Dashboard - Credentials',
    category: 'Dashboard_Credentials',
    action: 'click',
    label: 'Dashboard',
  },
  // deprecated
  dashboardPrivateCatalogRepositories: {
    event: 'Dashboard - Private catalog repositories',
    category: 'Dashboard_PrivCatRep',
    action: 'click',
    label: 'Dashboard',
  },
}

/** Section: Projects **/
export const gtmProjectsEvents = {
  projects: {
    event: 'Projects',
    category: 'MN_Projects',
    action: 'click',
    label: 'MN_Projects',
  },
  // deprecated
  allProjects: {
    event: 'All projects',
    category: 'MN_AllProjects',
    action: 'click',
    label: 'MN_Projects',
  },
  allProjectsCreateNewProject: {
    event: 'All projects - Create new project',
    category: 'AllProjects_CreateNew',
    action: 'click',
    label: 'Projects',
  },
  // deprecated
  allProjectsConfigRepositories: {
    event: 'All projects - - Config Repositories',
    category: 'AllProjects_ConfigRepositories',
    action: 'click',
    label: 'Projects',
  },
  // deprecated
  allProjectsViewWorkers: {
    event: 'All projects - View workers',
    category: 'AllProjects_ViewWorkers',
    action: 'click',
    label: 'Projects',
  },
  configRepositories: {
    event: 'Config repositories',
    category: 'MN_ConfigRepositories',
    action: 'click',
    label: 'MN_Projects',
  },
  // deprecated
  configRepositoriesAllProjects: {
    event: 'Config repositories - All projects',
    category: 'ConfigRepositories_AllProjects',
    action: 'click',
    label: 'Projects',
  },
  configRepositoriesAddConfigRepository: {
    event: 'Config repositories - Add config repository',
    category: 'ConfigRepositories_AddConfigRepository',
    action: 'click',
    label: 'Projects',
  },
}

/** Section: Stacks **/
export const gtmStacksEvents = {
  stacks: {
    event: 'Stacks',
    category: 'MN_Stacks',
    action: 'click',
    label: 'MN_Stacks',
  },
  // deprecated
  stacksAllStacks: {
    event: 'Stacks - All stacks',
    category: 'MN_Stacks_AllStacks',
    action: 'click',
    label: 'MN_Stacks',
  },
  stacksAllStacksCreateANewStack: {
    event: 'Stacks - All stacks - Create a new stack',
    category: 'MN_Stacks_AllStacks',
    action: 'click',
    label: 'Stacks',
  },
  // deprecated
  stacksAllStacksCatalogRepositories: {
    event: 'Stacks - All stacks - Config repositories',
    category: 'Stacks_AllStacks_CatalogRepositories',
    action: 'click',
    label: 'Stacks',
  },
  stacksCatalogRepositories: {
    event: 'Stacks - Catalog repositories',
    category: 'MN_Stacks_CatalogRepositories',
    action: 'click',
    label: 'MN_Stacks',
  },
  stacksCatalogRepositoriesAddPrivateCatalog: {
    event: 'Stacks - Catalog repositories - Add private catalog',
    category: 'Stacks_CatalogRepositories_AddPrivateCatalog',
    action: 'click',
    label: 'Stacks',
  },
  // deprecated
  stacksCatalogRepositoriesWhatIsACatalogRepository: {
    event: 'Stacks - Catalog repositories - What is a catalog repository',
    category: 'Stacks_CatalogRepositories_WhatIsCatalogRepository',
    action: 'click',
    label: 'Stacks',
  },
  // deprecated
  stacksCatalogRepositoriesAllStacks: {
    event: 'Stacks - Catalog repositories - All stacks',
    category: 'Stacks_CatalogRepositories_AllStacks',
    action: 'click',
    label: 'Stacks',
  },
}

/** Section: Observability **/
export const gtmObservabilityEvents = {
  observability: {
    event: 'Observability',
    category: 'MN_Observability',
    action: 'click',
    label: 'MN_Observability',
  },
  observabilityPipelinesOverview: {
    event: 'Observability - Pipelines overview',
    category: 'MN_Observability_PipelinesOverview',
    action: 'click',
    label: 'MN_Observability',
  },
  // deprecated
  observabilityPipelinesOverviewViewWorkers: {
    event: 'Observability - Pipelines overview - View workers',
    category: 'Observability_PipelinesOverview_ViewWorkers',
    action: 'click',
    label: 'Observability',
  },
  // deprecated
  observabilityPipelinesOverviewEvents: {
    event: 'Observability - Pipelines overview - Events',
    category: 'Observability_PipelinesOverview_Events',
    action: 'click',
    label: 'Observability',
  },
  // deprecated
  observabilityPipelinesOverviewRefresh: {
    event: 'Observability - Pipelines overview - Refresh',
    category: 'Observability_PipelinesOverview_Refresh',
    action: 'click',
    label: 'Observability',
  },
  // deprecated
  observabilityEvents: {
    event: 'Observability - Events',
    category: 'MN_Observability_Events',
    action: 'click',
    label: 'MN_Observability',
  },
  // deprecated
  observabilityEventsPipelinesOverview: {
    event: 'Observability - Events - Pipelines overview',
    category: 'Observability_Events_PipelineOverview',
    action: 'click',
    label: 'Observability',
  },
  // deprecated
  observabilityEventsRefresh: {
    event: 'Observability - Events - Refresh',
    category: 'Observability_Events_Refresh',
    action: 'click',
    label: 'Observability',
  },
}

/** Section: Security **/
export const gtmSecurityEvents = {
  security: {
    event: 'Security',
    category: 'MN_Security',
    action: 'click',
    label: 'MN_Security',
  },
  securityCredentials: {
    event: 'Security - Credentials',
    category: 'Security_Credentials',
    action: 'click',
    label: 'Security',
  },
  securityInfraPolicies: {
    event: 'Security - Infra policies',
    category: 'MN_Security_InfraPolicies',
    action: 'click',
    label: 'MN_Security',
  },
  // deprecated
  securityInfraPoliciesAddInfraPolicy: {
    event: 'Security - Infra policies - Add infra policy',
    category: 'Security_InfraPolicies_AddInfraPolicy',
    action: 'click',
    label: 'Security',
  },
  // deprecated
  securityInfraPoliciesRoles: {
    event: 'Security - Infra policies - Roles',
    category: 'Security_InfraPolicies_Roles',
    action: 'click',
    label: 'Security',
  },
  // deprecated
  securityInfraPoliciesCredentials: {
    event: 'Security - Infra policies - Credentials',
    category: 'Security_InfraPolicies_Credentials',
    action: 'click',
    label: 'Security',
  },
  securityRoles: {
    event: 'Security - Roles',
    category: 'MN_Security_Roles',
    action: 'click',
    label: 'MN_Security',
  },
  // deprecated
  securityRolesAddRole: {
    event: 'Security - Roles - Add role',
    category: 'Security_Roles_AddRole',
    action: 'click',
    label: 'Security',
  },
  // deprecated
  securityRolesInfraPolicies: {
    event: 'Security - Roles - Infra policy',
    category: 'Security_Roles_InfraPolicies',
    action: 'click',
    label: 'Security',
  },
  // deprecated
  securityRolesCredentials: {
    event: 'Security - Roles - Credentials',
    category: 'Security_Roles_Credentials',
    action: 'click',
    label: 'Security',
  },
  securityApiKeys: {
    event: 'Security - ApiKeys',
    category: 'MN_Security_ApiKeys',
    action: 'click',
    label: 'MN_Security',
  },
  // deprecated
  securityCredentialsAddCredentials: {
    event: 'Security - Credentials - Add credentials',
    category: 'Security_Credentials_AddCredentials',
    action: 'click',
    label: 'Security',
  },
  // deprecated
  securityCredentialsInfraPolicies: {
    event: 'Security - Credentials - Infra policies',
    category: 'Security_Credentials_InfraPolicies',
    action: 'click',
    label: 'Security',
  },
  // deprecated
  securityCredentialsRoles: {
    event: 'Security - Credentials - Roles',
    category: 'Security_Credentials_Roles',
    action: 'click',
    label: 'Security',
  },
}

/** Section: Breadcrumbs **/
export const gtmBreadcrumbsEvents = {
  breadcrumbsOrganizations: {
    event: 'Breadcrumbs organizations',
    category: 'Breadcrumbs_Organizations',
    action: 'click',
    label: 'Breadcrumbs',
  },
  breadcrumbsOrganizationsSwitch: {
    event: 'Breadcrumbs organizations - Switch',
    category: 'Breadcrumbs_Organizations_Switch',
    action: 'click',
    label: 'Breadcrumbs',
  },
  breadcrumbsOrganizationsSeeAllOrganizations: {
    event: 'Breadcrumbs organizations - See all organizations',
    category: 'Breadcrumbs_Organizations_SeeAllOrganizations',
    action: 'click',
    label: 'Breadcrumbs',
  },
  breadcrumbsOrganizationsSearchActivation: {
    event: 'Breadcrumbs organizations - Search activation',
    category: 'Breadcrumbs_Organizations_SearchActivation',
    action: 'click',
    label: 'Breadcrumbs',
  },
}
