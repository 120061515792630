/* eslint-disable vue/no-unused-properties */

export default {
  install (Vue, _options) {
    Vue.mixin({
      computed: {
        $isCreationRoute () {
          const name = _.get(this.$route, 'name') || ''
          const path = _.get(this.$route, 'path') || ''
          const meta = _.get(this.$route, 'meta') || {}
          const manualOverrides = ['memberInvite']
          return _.some([
            manualOverrides.includes(name),
            meta.permissionsKey?.includes('create'),
            name.startsWith('new') && path.includes('/create'),
          ])
        },
      },
    })
  },
}
