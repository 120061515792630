import zoomToFit from './cg-icons/arrows-alt-h-solid.png'
import settingsIcon from './cg-icons/baseline-settings.png'
import noLink from './cg-icons/nolink.png'
import layout from './cg-icons/sitemap-solid.png'
import figma from './figma.png'
import inaccessible from './inaccessible.png'

// Provider Icons
import alibabaLogo from './providers/alibaba/logo.svg'
import ansibleLogo from './providers/ansible/logo.svg'
import awsApiGateway from './providers/aws/apigateway.svg'
import awsCloudFront from './providers/aws/cloudfront.svg'
import awsDynamodb from './providers/aws/dynamodb.svg'
import awsEc2 from './providers/aws/ec2.svg'
import awsLambda from './providers/aws/lambda.svg'
import awsLogo from './providers/aws/logo.svg'
import awsS3 from './providers/aws/s3.svg'
import azurermLogo from './providers/azurerm/logo.svg'
import elasticSearchLogo from './providers/elasticsearch/logo.svg'
import flexibleEngineLogo from './providers/flexeng/logo.png'
import gitlabLogo from './providers/gitlab/logo.svg'
import googleLogo from './providers/google/logo.svg'
import kubernetesLogo from './providers/kubernetes/logo.png'
import openstackLogo from './providers/openstack/logo.png'
import oracleLogo from './providers/oracle/logo.svg'
import ovhLogo from './providers/ovh/logo.svg'
import scalewayLogo from './providers/scaleway/logo.svg'
import scalewayLogoWithText from './providers/scaleway/logoWithText.svg'
import swiftLogo from './providers/swift/logo.svg'
import terraformLogo from './providers/terraform/logo.svg'
import vaultLogoColor from './providers/vault/logo-color.svg'
import vaultLogoWithText from './providers/vault/logo-with-text.png'
import vaultLogo from './providers/vault/logo.svg'
import vmwareLogo from './providers/vmware/logo.svg'
import vsphereLogo from './providers/vsphere/logo.svg'

export const CycloidIcons = {
  figma,
  inaccessible,
  layout,
  noLink,
  settingsIcon,
  zoomToFit,
}

export const aws = {
  /** @see {@link https://worldvectorlogo.com/search/aws} for more AWS logos */
  logo: awsLogo,

  apiGateway: awsApiGateway,
  cloudfront: awsCloudFront,
  dynamodb: awsDynamodb,
  ec2: awsEc2,
  lambda: awsLambda,
  s3: awsS3,
}
export const vault = {
  logo: vaultLogo,
  logoColor: vaultLogoColor,
  logoWithText: vaultLogoWithText,
}
export const google = {
  logo: googleLogo,
}
export const kubernetes = {
  logo: kubernetesLogo,
}
export const vmware = {
  logo: vmwareLogo,
}
export const vsphere = {
  logo: vsphereLogo,
}
export const scaleway = {
  logo: scalewayLogo,
  logoWithText: scalewayLogoWithText,
}
export const ovh = {
  logo: ovhLogo,
}
export const alibaba = {
  logo: alibabaLogo,
}
export const oracle = {
  logo: oracleLogo,
}
export const flexibleengine = {
  logo: flexibleEngineLogo,
}
export const azurerm = {
  logo: azurermLogo,
}
export const gitlab = {
  logo: gitlabLogo,
}
export const elasticsearch = {
  logo: elasticSearchLogo,
}
export const swift = {
  logo: swiftLogo,
}
export const openstack = {
  logo: openstackLogo,
}
export const ansible = {
  logo: ansibleLogo,
}
export const terraform = {
  logo: terraformLogo,
}

export const ProviderIcons = {
  alibaba,
  ansible,
  aws,
  azurerm,
  elasticsearch,
  flexibleengine,
  gitlab,
  google,
  kubernetes,
  openstack,
  ovh,
  scaleway,
  swift,
  terraform,
  vault,
  vmware,
  vsphere,
}

export default {
  ...CycloidIcons,
  ...ProviderIcons,
}
