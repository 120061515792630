import Vue from 'vue'
import { ObjWithPrivateProps, RepoUrl } from '@/utils/classes'
import { vuexMixin } from '@/utils/helpers'
import i18n from '@/utils/plugins/i18n'

export const initialState = {
  detail: null,
  branches: null,
  errors: {
    branches: [],
    catalogRepository: [],
    refresh: [],
  },
  fetchInProgress: {
    catalogRepository: false,
  },
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  catalogRepository: (state) => state.detail,
  hasBranches: (state) => !_.isEmpty(state.branches),
  hasBranchesErrors: (state) => !_.isEmpty(state.errors.branches),
}

const {
  actions: { GET_OWNER_OBJECT },
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS, START_FETCH, STOP_FETCH },
} = vuexMixin(initialState)

export const actions = {
  GET_OWNER_OBJECT,

  async GET_CATALOG_REPOSITORY ({ commit, rootGetters: { orgCanonical } }, { catalogRepositoryCanonical }) {
    commit('START_FETCH', 'catalogRepository')
    commit('CLEAR_CR_ERRORS', 'catalogRepository')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getServiceCatalogSource(orgCanonical, catalogRepositoryCanonical) || {}
    if (data) commit('SET_CATALOG_REPOSITORY', data)
    if (errors) commit('SET_ERRORS', { key: 'catalogRepository', errors })
    commit('STOP_FETCH', 'catalogRepository')
  },

  async CREATE_CATALOG_REPOSITORY ({ commit, dispatch, rootGetters: { orgCanonical } }, { catalogRepository, $router }) {
    commit('CLEAR_CR_ERRORS', 'catalogRepository')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createServiceCatalogSource(orgCanonical, catalogRepository) || {}
    if (data) {
      commit('SET_CATALOG_REPOSITORY', data)
      if ($router) $router.replace({ name: 'catalogRepository', params: { catalogRepositoryCanonical: data.canonical } })
      const content = i18n.t('alerts.success.catalogRepository.created', { catalogRepoName: data.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { key: 'catalogRepository', errors })
  },

  async UPDATE_CATALOG_REPOSITORY ({ commit, dispatch, rootGetters: { orgCanonical } }, { catalogRepository, successMessage }) {
    commit('CLEAR_CR_ERRORS', 'catalogRepository')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateServiceCatalogSource(orgCanonical, catalogRepository) || {}
    if (data) {
      commit('SET_CATALOG_REPOSITORY', await dispatch('GET_OWNER_OBJECT', { data, oldVal: catalogRepository }))
      const content = successMessage || i18n.t('alerts.success.catalogRepository.updated', { catalogRepoName: data.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { key: 'catalogRepository', errors })
  },

  async DELETE_CATALOG_REPOSITORY ({ commit, dispatch, rootGetters: { orgCanonical } }, { catalogRepository, $router }) {
    commit('CLEAR_CR_ERRORS', 'catalogRepository')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteServiceCatalogSource(orgCanonical, catalogRepository.canonical) || {}
    if (errors) return commit('SET_ERRORS', { key: 'catalogRepository', errors })

    const content = i18n.t('alerts.success.catalogRepository.deleted', { catalogRepoName: catalogRepository.name })
    dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    if ($router) $router.replace({ name: 'catalogRepositories' })
  },

  async VALIDATE_CATALOG_REPOSITORY ({ commit, rootGetters: { orgCanonical } }, { catalogRepositoryCanonical }) {
    commit('CLEAR_CR_ERRORS', 'catalogRepository')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.validateServiceCatalogSource(orgCanonical, catalogRepositoryCanonical) || {}
    if (errors) commit('SET_ERRORS', { key: 'catalogRepository', errors })
  },

  async GET_BRANCHES ({ commit, rootGetters: { orgCanonical } }, { credentialCanonical, url }) {
    const code = 'InvalidCatalogRepositoryNotFound'
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getRepoBranches(orgCanonical, credentialCanonical, url) || {}
    if (data) {
      commit('CLEAR_CR_ERRORS', 'catalogRepository')
      commit('CLEAR_CR_ERRORS', 'branches')
      commit('SET_BRANCHES', data.sort())
    }
    if (_.every([data, errors], _.isEmpty)) commit('SET_ERRORS', { key: 'catalogRepository', errors: [{ code, message: i18n.t(`apiErrors.${code}`) }] })
    if (errors) commit('SET_ERRORS', { key: 'branches', errors })
  },

  async REFRESH_CATALOG_REPOSITORY ({ commit, dispatch, state, rootGetters: { orgCanonical } }, { canonical, name }) {
    commit('CLEAR_CR_ERRORS', 'refresh')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.refreshServiceCatalogSource(orgCanonical, canonical) || {}
    if (data) {
      commit('SET_STACKS', _.uniqWith([...data.updated || [], ...data.created || []], _.isEqual))
      return data
    }
    if (errors) {
      if (state.detail) commit('SET_ERRORS', { key: 'refresh', errors })
      else {
        errors[0].message = i18n.t('alerts.errors.catalogRepository.refresh', { name, errorMessage: errors[0].message })
        dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
      }
    }
  },
}

export const mutations = {
  CLEAR_CR_ERRORS: CLEAR_ERRORS,
  RESET_CR_STATE: RESET_STATE,
  SET_ERRORS,
  START_FETCH,
  STOP_FETCH,

  SET_CATALOG_REPOSITORY (state, repo) {
    const catalogRepository = _.cloneDeep(repo)
    catalogRepository.service_catalogs = _.sortBy(repo.service_catalogs, ['canonical'])

    Vue.set(state, 'detail', new ObjWithPrivateProps(catalogRepository, {
      _repoURL: new RepoUrl(catalogRepository),
    }))
  },

  SET_BRANCHES (state, branches) {
    Vue.set(state, 'branches', branches)
  },

  SET_STACKS (state, stacks) {
    if (!state.detail) return
    const existingStacks = state.detail.service_catalogs || []
    const mergedStacks = _.unionBy(existingStacks, stacks, 'canonical')
    Vue.set(state.detail, 'stack_count', mergedStacks.length)
    Vue.set(state.detail, 'service_catalogs', _.sortBy(mergedStacks, 'canonical'))
  },

}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
