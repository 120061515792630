export default {
  en: {
    AmiLaunchIndex: 'AMI Launch Index',
    Architecture: 'Architecture',
    AvailabilityZone: 'Availability Zone',
    BlockDeviceMappings: 'Block Device Mappings',
    ClientToken: 'Client Token',
    CpuOptions: 'CPU Options',
    EbsOptimized: 'EBS Optimized',
    EnaSupport: 'ENA Support',
    Hypervisor: 'Hypervisor',
    IamInstanceProfile: 'IAM Instance Profile',
    ImageId: 'Image ID',
    InstanceId: 'Instance ID',
    InstanceLifecycle: 'Instance Lifecycle',
    instanceName: 'Instance name',
    InstanceType: 'Instance type',
    KeyName: 'Key Name',
    LaunchTime: 'Launch Time',
    Monitoring: 'Monitoring',
    NetworkInterfaces: 'Network Interfaces',
    PrivateDnsName: 'Private DNS Name',
    PrivateIpAddress: 'Private IP Address',
    Protocol: 'Protocol',
    PublicDnsName: 'Public DNS Name',
    PublicIpAddress: 'Public IP Address',
    RootDeviceName: 'Root Device Name',
    RootDeviceType: 'Root Device Type',
    SecurityGroup: 'Security Group',
    SecurityGroups: 'Security Groups',
    SourceDestCheck: 'Source Dest Check',
    SpotInstanceRequestId: 'Spot Instance Request ID',
    State: 'State',
    StateReason: 'State Reason',
    StateTransitionReason: 'State Transition Reason',
    SubnetId: 'Subnet ID',
    VirtualizationType: 'Virtualization Type',
    VpcId: 'Vpc ID',
  },
  es: {
    AmiLaunchIndex: 'AMI Launch Index',
    Architecture: 'Architecture',
    AvailabilityZone: 'Availability Zone',
    BlockDeviceMappings: 'Block Device Mappings',
    ClientToken: 'Client Token',
    CpuOptions: 'CPU Options',
    EbsOptimized: 'EBS Optimized',
    EnaSupport: 'ENA Support',
    Hypervisor: 'Hypervisor',
    IamInstanceProfile: 'IAM Instance Profile',
    ImageId: 'Image ID',
    InstanceId: 'Instance ID',
    InstanceLifecycle: 'Instance Lifecycle',
    instanceName: 'Instance name',
    InstanceType: 'Instance type',
    KeyName: 'Key Name',
    LaunchTime: 'Launch Time',
    Monitoring: 'Monitoring',
    NetworkInterfaces: 'Network Interfaces',
    PrivateDnsName: 'Private DNS Name',
    PrivateIpAddress: 'Private IP Address',
    Protocol: 'Protocolo',
    PublicDnsName: 'Public DNS Name',
    PublicIpAddress: 'Public IP Address',
    RootDeviceName: 'Root Device Name',
    RootDeviceType: 'Root Device Type',
    SecurityGroup: 'Security Group',
    SecurityGroups: 'Security Groups',
    SourceDestCheck: 'Source Dest Check',
    SpotInstanceRequestId: 'Spot Instance Request ID',
    State: 'State',
    StateReason: 'State Reason',
    StateTransitionReason: 'State Transition Reason',
    SubnetId: 'Subnet ID',
    VirtualizationType: 'Virtualization Type',
    VpcId: 'Vpc ID',
  },
  fr: {
    AmiLaunchIndex: 'AMI Launch Index',
    Architecture: 'Architecture',
    AvailabilityZone: 'Availability Zone',
    BlockDeviceMappings: 'Block Device Mappings',
    ClientToken: 'Client Token',
    CpuOptions: 'CPU Options',
    EbsOptimized: 'EBS Optimized',
    EnaSupport: 'ENA Support',
    Hypervisor: 'Hypervisor',
    IamInstanceProfile: 'IAM Instance Profile',
    ImageId: 'Image ID',
    InstanceId: 'Instance ID',
    InstanceLifecycle: 'Instance Lifecycle',
    instanceName: 'Instance name',
    InstanceType: 'Instance type',
    KeyName: 'Key Name',
    LaunchTime: 'Launch Time',
    Monitoring: 'Monitoring',
    NetworkInterfaces: 'Network Interfaces',
    PrivateDnsName: 'Private DNS Name',
    PrivateIpAddress: 'Private IP Address',
    Protocol: 'Protocole',
    PublicDnsName: 'Public DNS Name',
    PublicIpAddress: 'Public IP Address',
    RootDeviceName: 'Root Device Name',
    RootDeviceType: 'Root Device Type',
    SecurityGroup: 'Security Group',
    SecurityGroups: 'Security Groups',
    SourceDestCheck: 'Source Dest Check',
    SpotInstanceRequestId: 'Spot Instance Request ID',
    State: 'State',
    StateReason: 'State Reason',
    StateTransitionReason: 'State Transition Reason',
    SubnetId: 'Subnet ID',
    VirtualizationType: 'Virtualization Type',
    VpcId: 'Vpc ID',
  },
}
