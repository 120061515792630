export const KPIS_SETTINGS = {
  event: [
    {
      component: 'CyKpiWidgetsCodeCoverage',
      type: 'code_coverage',
      widget: 'doughnut',
      label: 'kpis.codeCoverage',
      legend: 'kpis.codeCoverageLegend',
      extraFields: [],
    },
    {
      component: 'CyKpiWidgetsTimeToRelease',
      type: 'time_to_release',
      widget: 'line',
      label: 'kpis.timeToRelease',
      legend: 'kpis.timeToReleaseLegend',
      extraFields: ['env_order'],
    },
  ],
  concourse: [
    {
      component: 'CyKpiWidgetsSuccessFailure',
      type: 'build_history',
      widget: 'pie',
      label: 'kpis.successVSFailure',
      legend: 'kpis.successVSFailureLegend',
      extraFields: ['env', 'pipeline', 'job'],
    },
    {
      component: 'CyKpiWidgetsBuildHistory',
      type: 'build_history',
      widget: 'history',
      label: 'kpis.buildHistory',
      legend: 'kpis.buildHistoryLegend',
      extraFields: ['env', 'pipeline', 'job'],
    },
    {
      component: 'CyKpiWidgetsBuildTime',
      type: 'build_avg_time',
      widget: 'bars',
      label: 'kpis.averageBuildTime',
      legend: 'kpis.averageBuildTimeLegend',
      extraFields: ['env', 'pipeline', 'job'],
    },
    {
      component: 'CyKpiWidgetsBuildFrequency',
      type: 'build_frequency',
      widget: 'line',
      label: 'kpis.buildFrequency',
      legend: 'kpis.buildFrequencyLegend',
      extraFields: ['env', 'pipeline', 'job'],
    },
  ],
}

export function getKPISetting ({ type, widget }) {
  return _.chain(KPIS_SETTINGS)
    .values()
    .flatten()
    .find((TYPE) => TYPE.type === type && TYPE.widget === widget)
    .value()
}
