var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{directives:[{name:"show",rawName:"v-show",value:(_vm.appearance),expression:"appearance"}],class:['cy-app', {
    'cy-app--full-screen': _vm.isFullScreen,
    'cy-app--public-status': _vm.isPublicStatusPage,
  }],style:([{
    '--appearance-bg-color': _vm._.get(_vm.backgroundColor, 'cssString'),
    '--appearance-text-color': _vm._.get(_vm.textColor, 'cssString'),
    '--appearance-text-color-values': _vm._.get(_vm.textColor, 'cssValues'),
  }]),attrs:{"aria-locale":_vm.$i18n.locale}},[(_vm.isRouteReady)?_c(_vm.layout,{tag:"component",attrs:{"variant":_vm.variant,"fit-on-screen":_vm.fitOnScreen}},[_c('router-view')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }