export default {
  en: {
    system: 'System error, please try again in a few minutes. If the error persists, contact the customer service. Thank you.',
    network: 'Error accessing to the network, please, check that you are connected to a network with Internet access',
  },
  es: {
    system: 'Error del sistema, inténtalo de nuevo en unos minutos. Si el error persiste, póngase en contacto con el servicio de atención al cliente. Gracias.',
    network: 'Error de acceso a la red, por favor, compruebe que se encuentra coneectodo a una red con acceso a Internet.',
  },
  fr: {
    system: `Erreur système, veuillez réessayer dans quelques minutes. Si l'erreur persiste, contactez le service client. Merci.`,
    network: `Erreur lors de l'accès au réseau, vérifiez que vous êtes connecté à un réseau avec accès à Internet`,
  },
}
