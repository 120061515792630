/** Will return the original Object, along with any privateProps passed on the prototype.
 *
 * @returns Object
 */
export default class ObjWithPrivateProps {
  constructor (item, privateProps, keysToDelete = []) {
    if (_.keys(privateProps).some((key) => !key.startsWith('_'))) console.warn(`[ObjWithPrivateProps] was passed keys not prefixed with an underscore`, privateProps)
    Object.setPrototypeOf(this, { ...(Object.getPrototypeOf(item) || {}), ...privateProps })
    for (const key of keysToDelete) delete item[key]
    Object.assign(this, { ...item })
    // this._private = _.cloneDeep(Object.getPrototypeOf(this)) // ! TODO: implement this if/when we want to see these in dev tools (but make sure to strip it when sending back to api)
  }
}
