export const plans = {
  enterprise: {
    name: 'Enterprise',
    canonical: 'enterprise',
  },
  essential: {
    name: 'Essential',
    canonical: 'essential',
  },
  freeTrial: {
    name: 'Free trial',
    canonical: 'free-trial',
    daysDefault: 15,
  },
}

export const thresholds = {
  // time thresholds (absolute values) mean 'if less than <value> it is <key>'
  time: {
    critical: 3,
    warning: 7,
  },
  // members thresholds (percentage values) mean 'if less than <value> it is <key>'
  members: {
    critical: 10,
    warning: 50,
  },
}
