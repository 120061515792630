import Vue from 'vue'
import Vuex from 'vuex'
import Appearance from '@/utils/classes/Appearance'
import { decodeJWT, arrayToObject, vuexMixin, parseModules, getOwnerUsername } from '@/utils/helpers'
import createPersistedState from 'vuex-persistedstate'
import Alerts from './modules/alerts'
import Auth from './modules/auth'
import Customers from './modules/customers'
import Dev from './modules/dev'
import I18n from './modules/i18n'
import Layout from './modules/layout'
import Notifications from './modules/notifications'
import Organization from './modules/organization'
import User from './modules/user'

Vue.use(Vuex)

export const checkProductTutorials = (guide, commit) => {
  const isPresentationCompleted = _.get(guide, 'presentationCompleted', false)
  const isProductTourCompleted = _.get(guide, 'tour.completed', false)

  if (!isPresentationCompleted) commit('user/CLEAR_PRESENTATION_COMPLETE')
  isProductTourCompleted
    ? commit('user/SET_TOUR_COMPLETE')
    : commit('user/CLEAR_TOUR_COMPLETE')
}

export const supportedProviders = ['azuread', 'google', 'github']

export const initialState = {
  apiVersion: null,
  appAppearance: null,
  appConfig: null,
  countries: [],
  errors: {
    organizations: [],
  },
  fetchInProgress: {
    rootAppAppearance: false,
  },
  organizations: [],
  rootAppAppearance: null,
  servicesStatus: {},
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  appearance: (state) => state.appAppearance?.orgCanonical ? state.appAppearance : state.rootAppAppearance,
  defaultRoute: (_state, getters) => getters['auth/defaultRoute'],
  displayName: (state) => state.appAppearance?.orgCanonical ? state.appAppearance?.display_name : state.rootAppAppearance?.display_name,
  hasActiveBillingPlan: (_state, getters) => getters['organization/billing/hasActiveBillingPlan'],
  hasOrgs: (state) => !_.isEmpty(state.organizations),
  isAuthenticated: (_state, getters) => getters['auth/isAuthenticated'],
  isEntityOwner: (state, getters) => ({ owner } = {}) => _.isEqual(getters.username, getOwnerUsername(owner)),
  isOnPrem: (_state, getters) => getters['organization/licence/isOnPrem'],
  isOrgAdmin: (_state, getters) => getters['auth/isOrgAdmin'],
  isOrgSelected: (_state, getters) => getters['organization/isOrgSelected'],
  isUsingMSP: (state, getters) => getters['auth/isUsingMSP'],
  orgCanonical: (_state, getters) => getters['organization/orgCanonical'],
  orgMembers: (_state, getters) => getters['organization/orgMembers'],
  orgName: (_state, getters) => getters['organization/orgName'],
  projectCanonical: (_state, getters) => getters['organization/project/projectCanonical'],
  projectName: (_state, getters) => getters['organization/project/projectName'],
  requirePayment: (_state, getters) => getters['organization/billing/requirePayment'],
  socialProviders: (state) => _(state.appConfig?.authentication?.oauth || {}).keys().intersection(supportedProviders).value(),
  username: (state, getters) => getters['auth/username'],
  userRole: (state, getters) => getters['auth/userRole'],
}

const {
  mutations: { SET_ERRORS, START_FETCH, STOP_FETCH },
} = vuexMixin(initialState)

export const actions = {
  async FETCH_API_VERSION ({ commit }) {
    const { data } = await Vue.prototype.$cycloid.ydAPI.getAppVersion() || {}
    if (data) commit('SET_API_VERSION', data)
  },

  async FETCH_APP_APPEARANCE ({ state, commit }, orgCanonical = null) {
    if (orgCanonical && !_.isEqual(orgCanonical, state.appAppearance?.orgCanonical)) {
      const { data } = await Vue.prototype.$cycloid.ydAPI.getActiveAppearance(orgCanonical) || {}
      data
        ? commit('SET_APP_APPEARANCE', { ...data, orgCanonical })
        : commit('RESET_STATE', 'appAppearance')
    }

    if (!state.rootAppAppearance && !state.fetchInProgress.rootAppAppearance) {
      commit('START_FETCH', 'rootAppAppearance')
      const { data = null } = await Vue.prototype.$cycloid.ydAPI.getDefaultAppearance() || {}
      data && commit('SET_ROOT_APP_APPEARANCE', data)
      commit('STOP_FETCH', 'rootAppAppearance')
    }
  },

  async FETCH_SERVICES_STATUS ({ commit }) {
    const { data } = await Vue.prototype.$cycloid.ydAPI.getStatus() || {}
    if (data) commit('SET_SERVICES_STATUS', data)
  },

  async FETCH_COUNTRIES ({ commit }) {
    const { data } = await Vue.prototype.$cycloid.ydAPI.getCountries() || {}
    if (data) commit('SET_COUNTRIES', data)
  },

  async FETCH_ORGS ({ commit }, { reqPage } = {}) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getOrgs(reqPage) || {}
    if (data) commit('SET_ORGANIZATIONS', data)
    if (errors) commit('SET_ERRORS', { key: 'organizations', errors })
  },

  async RESTORE_SESSION ({ commit, dispatch, state, getters }) {
    dispatch('auth/RESTORE_CREDENTIALS')
    const { isAuthenticated } = getters
    const { cycloid } = (state.auth.jwt && decodeJWT(state.auth.jwt.split('.')[1])) || {}
    const lsProfile = JSON.parse(_.get(localStorage, LSK.PROFILE) || '{}')
    const hasValidProfileStored = lsProfile?.created_at && _.isEqual(lsProfile.username, _.get(cycloid, 'user.username'))

    if (!isAuthenticated) {
      commit('user/CLEAR_PROFILE')
      commit('auth/CLEAR_CREDENTIALS')
      return false
    }
    if (hasValidProfileStored) commit('user/RESTORE_PROFILE')
    else await dispatch('user/GET_PROFILE')

    checkProductTutorials(state.user.guide, commit)

    commit('organization/RESTORE_ORGANIZATION')
    dispatch('organization/billing/SET_ORG_BILLING_STATUS')

    const locale = _.get(state, 'user.profile.locale', null)
    if (locale) commit('i18n/CHANGE_LOCALE', locale)

    return true
  },

  async UPDATE_USER_SESSION ({ commit, dispatch, state }, jwt) {
    const { cycloid: { user, organization } } = decodeJWT(jwt.split('.')[1])
    if (user) await dispatch('user/GET_PROFILE', user)

    checkProductTutorials(state.user.guide, commit)

    if (organization) commit('organization/SET_ORGANIZATION', organization)
    else commit('organization/RESET_ORGANIZATION', state.organizations)

    await dispatch('organization/billing/SET_ORG_BILLING_STATUS')
  },

  async GET_APP_CONFIG ({ commit }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getAppConfig() || {}

    if (data) {
      const { oauth, ...rest } = data.authentication
      const objectifiedOauth = arrayToObject(oauth, { key: 'provider' })
      commit('SET_APP_CONFIG', _.assign(data, { authentication: { oauth: objectifiedOauth, ...rest } }))
    }
    if (errors) commit('SET_ERRORS', { key: 'appConfig', errors })
  },
}

export const mutations = {
  SET_ERRORS,
  START_FETCH,
  STOP_FETCH,

  RESET_STATE (state, key) {
    if (key) return Vue.set(state, key, _.cloneDeep(initialState[key]))

    // ! We need to retain appearance, else brand colours reset on logout
    const { appAppearance, rootAppAppearance, ...rest } = initialState
    for (const key in rest) Vue.set(state, key, _.cloneDeep(initialState[key]))
  },

  SET_API_VERSION (state, version) {
    Vue.set(state, 'apiVersion', version)
  },

  SET_APP_APPEARANCE (state, { orgCanonical, ...appearance }) {
    Vue.set(state, 'appAppearance', new Appearance(orgCanonical, appearance))
  },

  SET_ROOT_APP_APPEARANCE (state, appearance) {
    Vue.set(state, 'rootAppAppearance', new Appearance(null, appearance))
  },

  SET_ORGANIZATIONS (state, organizations) {
    Vue.set(state, 'organizations', organizations)
  },

  SET_SERVICES_STATUS (state, status) {
    Vue.set(state, 'servicesStatus', status)
  },

  SET_APP_CONFIG (state, config) {
    Vue.set(state, 'appConfig', config)
  },

  SET_COUNTRIES (state, countries) {
    countries = _.sortBy(countries, 'name')
    Vue.set(state, 'countries', countries)
  },
}

export const storeConfig = {
  strict: Vue.prototype.$isDevMode,
  devTools: Vue.prototype.$isStagingOrDev,

  state: STATE,
  getters: GETTERS,
  mutations,
  actions,

  modules: parseModules({
    Alerts,
    Auth,
    Customers,
    Dev,
    I18n,
    Layout,
    Organization,
    Notifications,
    User,
  }),

  plugins: [
    createPersistedState({
      paths: [
        'customers',
        'layout.dataTables',
        'layout.sidebar.collapsed',
        'auth.socialProvider',
        'organization.infraImport',
        'organization.project.kpi.filters',
      ],
    }),
  ],
}

export {
  GETTERS as getters,
  STATE as state,
}

export default new Vuex.Store(storeConfig)
