/** Terraform Provider object.
 *  - Moves private properties to the prototype.
 *  - Adds `_private` obj prop, so the private properties can still be seen in the devTools.
 *
 * @param {Object} [provider]      **the provider object**:
 *                                  when first run through this class,
 *                                  provider will still contain schema etc,
 *                                  so will need to be moved to the prototype
 * @param {Object} [privateProps]  **base properties**:
 *                                  any properties that can be applied to the prototype, as is
 *
 * @returns `Object`                - a formatted provider, now with private properties configured
 */
export default class TerraformProvider {
  constructor (provider, baseProps) {
    let { _private } = provider
    const hasPrivatePropsAlready = !!_private
    const _available = {
      computedProps: {},
      requiredProps: {},
      optionalProps: {},
      attributes: {},
      arguments: {},
    }

    function setType (type) {
      const _type = type.substring(4)
      return _type === 'Int' ? 'Number' : _type
    }

    // TODO: [CG] revert to previous solution once https://github.com/terraform-providers/terraform-provider-vault/issues/804 is fixed
    function getDefaultValue (prop) {
      const defaultValue = _.get(prop, 'default', null)
      if (_.isNull(defaultValue)) return null

      return prop.type === 'String' ? String(defaultValue) : defaultValue
    }

    function setSchemaProps (schema, { topLevel = true } = {}) {
      for (const [key, value] of Object.entries(schema)) {
        schema[key].required = value.required || false
        schema[key].optional = value.optional || false
        schema[key].computed = value.computed || false

        const isAttribute = !schema[key].required && !schema[key].optional
        schema[key].isAttribute = isAttribute
        schema[key].isArgument = !isAttribute

        if (topLevel) {
          if (value.computed) _available.computedProps[key] = value
          else if (value.required) _available.requiredProps[key] = value
          else if (value.optional) _available.optionalProps[key] = value

          if (isAttribute) _available.attributes[key] = value
          else _available.arguments[key] = value
        }

        schema[key].type = setType(schema[key].type)
        schema[key].defaultValue = getDefaultValue(schema[key])
        schema[key].hasDefaultValue = schema[key].defaultValue !== null
        if (schema[key].type === 'List') {
          schema[key].maxItems = _.get(schema[key], 'max_items', Infinity)
          schema[key].isTypeSet = schema[key].maxItems === 1
          delete schema[key].max_items
        }
        delete schema[key].default

        if (_.has(schema[key], 'elem.schema')) {
          schema[key].elem.schema = setSchemaProps(schema[key].elem.schema, { topLevel: false })
        } else if (_.has(schema[key], 'elem')) {
          schema[key].elem.type = setType(schema[key].elem.type)
        }
      }
      return schema
    }

    function _hasSatisfiedRequiredProps () {
      return !Object.keys(_available.requiredProps)
        .map((key) => !!_.get(this, key))
        .includes(false)
    }

    if (!hasPrivatePropsAlready) {
      let { schema } = _.cloneDeep(baseProps)
      const privateProps = _.merge(
        Object.fromEntries(Object.entries({ ...baseProps, schema })
          .map(([key, value]) => [`_${_.camelCase(key)}`, value]),
        ),
      )

      if (schema) schema = setSchemaProps(schema)
      _private = {
        _available,
        _hasSatisfiedRequiredProps,
        _hasComputedProps: !_.isEmpty(_available.computedProps),
        _hasRequiredProps: !_.isEmpty(_available.requiredProps),
        _hasOptionalProps: !_.isEmpty(_available.optionalProps),
        ...privateProps,
      }
    }

    // set public properties
    Object.assign(this, { ...provider })
    // set private properties
    Object.setPrototypeOf(this, { ..._private })

    // set public property (just so we can view in devTools)
    // ! this gets removed when sent to BE
    this._private = _private
  }
}
