import { imageExists, vuexMixin } from '@/utils/helpers'
import i18n from '@/utils/plugins/i18n'

export const initialState = {}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  getAttributes: (_state, getters) =>
    (resource) => getters.isCustomResource(resource)
      ? resource?.custom_attributes
      : resource?.attributes,
  getId: (_state, getters) =>
    (resource) => getters.getAttributes(resource)?.id || resource?.id,
  getImage: () =>
    (resource) => {
      const { type, image } = resource

      if (type.startsWith('vault_')) return '/static/images/stackcraft/elements/default/vault.svg'
      if (image?.endsWith('.svg') && imageExists(image)) return image

      return '/static/images/stackcraft/elements/default/resource.svg'
    },
  getProvider: (_state) =>
    (resource) => {
      return resource?.provider
    },
  getProviderTranslation: (_state, getters, globalState) =>
    (resource, key = 'abbreviation') => {
      if (getters.isCustomResource(resource)) {
        return i18n.t('inventory.custom_resources')
      }
      const { cloudProviders } = globalState.organization.available
      const matchingCloudProvider = _.find(
        cloudProviders,
        { canonical: getters.getProvider(resource) },
      )
      const translation = matchingCloudProvider?.[key]
      return translation
    },
  getResource: (_state, _getters, globalState) =>
    (id) => _.find(
      globalState.organization.available.inventoryResources,
      { id },
    ),
  isCustomResource: (_state) =>
    (resource) => resource?.provider === 'custom_resources',
}

const { mutations: { RESET_STATE } } = vuexMixin(initialState)

export const actions = {}

export const mutations = {
  RESET_INVENTORY_RESOURCE_STATE: RESET_STATE,
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
