import i18n from '@/utils/plugins/i18n'

export const K_BYTES = 1024

const applyFormat = (format, count, size) => {
  switch (format) {
    case 'string':
      return `${count} ${size}`
    case 'object':
      return { count, size }
  }
}

export const formatBytes = (
  bytes,
  {
    decimals = 1,
    inputSize = 'MB',
    maxSize = 'TB',
    format = 'string',
    round = true,
  } = {}) => {
  if (bytes === 0) return applyFormat(format, 0, inputSize)

  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const maxSizeIndex = sizes.indexOf(maxSize)
  const inputSizeIndex = sizes.indexOf(inputSize)

  const resizedBytes = bytes * Math.pow(K_BYTES, inputSizeIndex)
  const i = _.min([Math.floor(Math.log(resizedBytes) / Math.log(K_BYTES)), maxSizeIndex])

  const count = round
    ? parseFloat((resizedBytes / Math.pow(K_BYTES, i)).toFixed(dm))
    : parseFloat((resizedBytes / Math.pow(K_BYTES, i)))
  const size = sizes[i]

  return applyFormat(format, count, size)
}

export const resourceTypes = {
  memory: {
    formatter: formatBytes,
    unit: 'bytes',
  },
  cpu: {
    unit: 'cores',
  },
  storage: {
    formatter: formatBytes,
    unit: 'bytes',
  },
}

const getFormattedUnits = (number, type) => {
  const resourceType = resourceTypes[type]
  const formatter = resourceType?.formatter || ((v) => v)
  const formattedUnits = formatter(number)
  return type === 'cpu' ? i18n.tc('quotas.nCores', formattedUnits) : formattedUnits
}

const getUsedOfText = ({ type, used, allocated }, short = false) => {
  const resourceType = resourceTypes[type]
  const formatter = resourceType?.formatter || ((v) => v)
  const formattedUsed = formatter(used)
  const formattedOf = formatter(allocated)
  const i18nKey = `quotas.usedOf${short ? '.short' : ''}.${resourceType.unit}`
  return i18n.t(i18nKey, { used: formattedUsed, of: formattedOf })
}

const getUsagePercentage = ({ used, allocated, capacity }) => {
  const total = capacity || allocated
  if (!total) return 0
  return parseFloat((used / total * 100).toFixed(2))
}

const getUsageBarColor = (resource) => {
  const usagePercentage = getUsagePercentage(resource)
  if (usagePercentage < 70) return 'secondary lighten-1'
  if (usagePercentage < 90) return 'warning lighten-1'
  return 'error lighten-3'
}

export {
  getUsedOfText,
  getUsagePercentage,
  getUsageBarColor,
  getFormattedUnits,
}
