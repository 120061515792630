import Vue from 'vue'
import { plans } from '@/utils/config/billing'
import { decodeJWT, vuexMixin } from '@/utils/helpers'
import i18n from '@/utils/plugins/i18n'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment

export const initialState = {
  blocked: [],
  subscription: {},
  errors: [],
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  hasActiveBillingPlan: (state) => {
    const blocked = _.get(state, 'blocked', [])
    const blockedStatuses = ['require_payment', 'require_plan', 'expired_free_trial']
    return _.isEmpty(_.intersection(blocked, blockedStatuses))
  },
  subscriptionPlan: (state) => {
    return _.get(state.subscription, 'plan', {})
  },
  requirePayment: (state) => {
    const blocked = _.get(state, 'blocked', [])
    const paymentStatuses = ['require_payment', 'expired_free_trial']
    return !_.isEmpty(_.intersection(blocked, paymentStatuses))
  },
  requirePlan: (state) => {
    return _.includes(_.get(state, 'blocked', []), 'require_plan')
  },
  daysRemaining: (state) => {
    const { expires_at: expireTime } = state.subscription
    /**
     * We have a small issue with time check:
     * when starting the free trial, sometimes it displays 31 days left,
     * then after refresh or passing some time it displays back 30 days left.
     **/
    return moment.unix(expireTime).diff(moment(), 'days')
  },
  hoursRemaining: (state) => {
    const { expires_at: expireTime } = state.subscription
    return moment.unix(expireTime).diff(moment(), 'hours')
  },
  trialExpired: (state, { isFreeTrial }) => {
    const { blocked, subscription: { expires_at: expireTime } } = state
    return (isFreeTrial && moment.unix(expireTime).diff(moment()) <= 0) || blocked.includes('expired_free_trial')
  },
  isFreeTrial: (_state, { subscriptionPlan }) => {
    const { name: planName = '' } = subscriptionPlan || {}
    return _.lowerCase(plans.freeTrial.name) === _.lowerCase(planName)
  },
  isEssential: (_state, { subscriptionPlan }) => {
    const { name: planName = '' } = subscriptionPlan || {}
    return _.lowerCase(plans.essential.name) === _.lowerCase(planName)
  },
  isEnterprise: (_state, { subscriptionPlan }) => {
    const { name: planName = '' } = subscriptionPlan || {}
    return _.lowerCase(plans.enterprise.name) === _.lowerCase(planName)
  },
  planName: (_state, getters) => {
    const {
      isFreeTrial, isEssential, isEnterprise, trialExpired,
      subscriptionPlan: { name: planName } = {},
    } = getters
    const showEnterPrise = !_.some([isFreeTrial, isEssential, isEnterprise, trialExpired])
    if (planName) return planName
    return showEnterPrise ? 'Enterprise' : plans.freeTrial.name
  },
}

const {
  mutations: { SET_ERRORS, CLEAR_ERRORS, RESET_STATE },
} = vuexMixin(initialState)

export const actions = {
  async CREATE_SUBSCRIPTION ({ commit, dispatch, rootGetters: { orgCanonical } }, { subscriptionPlan }) {
    const { name: planName, canonical: planCanonical } = subscriptionPlan

    commit('CLEAR_BILLING_ERRORS')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.createSubscription(orgCanonical, planCanonical) || {}
    if (errors) commit('SET_ERRORS', { errors })
    else {
      await dispatch('auth/REFRESH_TOKEN', { query: { organization_canonical: orgCanonical } }, { root: true })
      dispatch('SET_ORG_BILLING_STATUS')
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.organization.subscription.created', { planName }) }, { root: true })
    }
  },

  SET_ORG_BILLING_STATUS ({ commit, rootState }) {
    const { blocked, subscription } = decodeJWT(rootState.auth.jwt?.split('.')[1])?.cycloid?.organization || {}
    commit('SET_BLOCKED', blocked)
    commit('SET_SUBSCRIPTION', subscription)
  },
}

export const mutations = {
  CLEAR_BILLING_ERRORS: CLEAR_ERRORS,
  SET_ERRORS,
  RESET_BILLING_STATE: RESET_STATE,

  SET_BLOCKED (state, blocked = []) {
    Vue.set(state, 'blocked', blocked)
  },

  SET_SUBSCRIPTION (state, subscription = {}) {
    Vue.set(state, 'subscription', subscription)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
