export default {
  start: {
    content: [
      'Hola {name}, bienvenida a bordo!',
      `Presentemos algunos conceptos básicos en Cycloid.io. No tomará más de 2 minutos.`,
    ],
    button: 'Vamonos',
  },
  organizations: {
    title: 'Organizaciones',
    content: [
      'Todo en Cycloid ocurre dentro de una organización. Puede invitar personas y organizar miembros en @:teams.',
      `Puede definir roles y políticas granulares para garantizar que todos tengan la visibilidad correcta. Y puede configurar eventos para rastrear lo que sucede en tiempo real.`,
      'Las organizaciones también proporcionan administración centralizada de credenciales y le permiten mantener sus repositorios de catálogos.',
    ],
  },
  catalogRepositories: {
    title: 'Repositorios de catálogo',
    content: [
      'Un repositorio de catálogo es una colección de pilas, guardadas en un repositorio git. Puede pensar en una pila como una plantilla que puede cubrir varias capas. Infraestructura, aprovisionamiento y monitoreo, por ejemplo.',
      `Mantenemos una colección curada de pilas, basadas en tecnologías populares de código abierto como Terraform, Ansible y Concourse. Es el catálogo público de Cycloid.`,
    ],
  },
  configRepository: {
    title: 'Repositorio de configuración',
    content: [
      'Si un repositorio de catálogo es un lugar para almacenar su pila, un repositorio de configuración es un lugar para almacenar su configuración de pila: es solo un repositorio git donde puede empujar la configuración de su pila para el almacenamiento',
      'Ahora sus repositorios y configuraciones de pila están en un solo lugar, por lo que es aún más fácil mantenerlos y personalizarlos.',
    ],
  },
  privateCatalogs: {
    title: 'Catálogos privados',
    content: [
      `También puede mantener sus propias colecciones de pilas para necesidades particulares que su organización pueda tener. Puede escribir su propio código y enviarlo a su propio repositorio de catálogos.`,
    ],
  },
  projects: {
    title: 'Proyectos',
    content: [
      'Para crear un proyecto, comience eligiendo una Pila del catálogo público y defina los entornos que desea implementar. Las opciones comunes serían Demo, Pre-prod o Prod, pero puede nombrarlas como desee.',
      'Utilizamos variables para hacer que las pilas sean configurables para cada entorno. Simplemente anule algunos de ellos si es necesario.',
    ],
  },
  pipelines: {
    title: 'Conozca siempre el estado de su solicitud',
    content: [
      `Use les pipelines para monitorear lo que está sucediendo. Un nuevo commit a un repositorio, un lanzamiento en Github, un disparador periódico. Lo dice usted.`,
      'Siga fácilmente cada confirmación en su flujo de trabajo y acceda a los detalles de cada paso. Los trabajos se pueden activar de forma automática o manual a través de la interfaz. También puede deshabilitar la automatización o mezclar ambos enfoques para tener, por ejemplo, un flujo de trabajo automatizado que requiera aprobación humana para algunos pasos.',
    ],
  },
  monitoring: {
    title: 'Y mucho más',
    content: [
      'Puede programar entornos para la optimización de costos, inspeccionar los detalles de su infraestructura, obtener una visualización de todos los componentes de su proyecto o centralizar todos los registros. La línea de tiempo de eventos rastrea las acciones y el estado de sus proyectos para que siempre pueda saber lo que está sucediendo. Todo dentro de la misma interfaz.',
      'Otras características:',
    ],
    list: ['Vista de costo centralizado', 'DevOps como servicio', 'Manejar organizaciones de clientes'],
  },
  end: {
    content: [
      'Ok, eso es todo para el resumen. Deberías poder llegar a las nubes ahora (ehem, perdón por eso)',
      'Si desea más detalles sobre lo que hay debajo del capó, eche un vistazo a la documentación:',
    ],
    links: [
      'Visita la documentación',
    ],
    button: 'Ir a Cycloid.io',
  },
}
