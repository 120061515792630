const shapeSize = 80
const fontSize = 12
const strokeWidth = 0
const body = {
  rect: {
    width: shapeSize,
    height: shapeSize,
    strokeWidth,
    rx: 5,
    ry: 5,
    fill: 'transparent',
  },
  circle: {
    width: shapeSize,
    height: shapeSize,
    strokeWidth,
    cx: shapeSize / 2,
    cy: shapeSize / 2,
    r: shapeSize / 2,
    fill: 'transparent',
  },
}
const icon = {
  ref: 'body',
  width: 50,
  height: 50,
  refX: 15,
  refY: 0,
}
const label = {
  textVerticalAnchor: 'top',
  textAnchor: 'middle',
  refX: 0.5,
  refY: 0.7,
  ref: 'body',
  fontSize,
  fill: '#253449',
  fontWeight: 'bold',
}
const markup = [
  { tagName: 'rect', selector: 'body' },
  { tagName: 'text', selector: 'label' },
  { tagName: 'image', selector: 'icon' },
  { tagName: 'text', selector: 'config' },
]
const config = {
  textVerticalAnchor: 'top',
  textAnchor: 'middle',
  fontSize,
  fontStyle: 'italic',
  fontWeight: 'bold',
  refX: 0.5,
  refY: 0.2,
  ref: 'icon',
  fill: 'black',
}

export const meta = {
  shapeSize,
  fontSize,
  strokeWidth,
  body,
  icon,
  label,
  config,
  markup,
}

export function defineResource (joint) {
  joint.dia.Element.define('custom.Resource', {
    attrs: {
      body: body.rect,
      label,
      icon,
      config,
    },
  }, {
    markup,
  })
}

export function defineDataSource (joint) {
  joint.dia.Element.define('custom.DataSource', {
    attrs: {
      body: body.rect,
      label,
      icon,
      config,
    },
  }, {
    markup,
  })
}

export function defineCustomShapes ({ $joint }) {
  defineResource($joint)
  defineDataSource($joint)
}

export default defineCustomShapes
