import Vue from 'vue'
import CatalogRepository from '@/store/modules/organization/catalogRepository'
import { ObjWithPrivateProps } from '@/utils/classes'
import { parseModules, vuexMixin } from '@/utils/helpers'
import i18n from '@/utils/plugins/i18n'

const DESCRIPTION_LIMIT = 200

export const initialState = {
  detail: null,
  config: null,
  errors: {
    dependencies: [],
    stackConfig: [],
    stack: [],
  },
  fetchInProgress: {
    stack: false,
  },
  searchTerm: '',
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  stack: (state) => state.detail,
  stackConfig: (state) => state.config,
  stackDescriptionLimit: () => DESCRIPTION_LIMIT,
}

const {
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS, START_FETCH, STOP_FETCH },
} = vuexMixin(initialState)

export const actions = {
  async GET_STACK ({ commit, dispatch, rootGetters: { orgCanonical } }, { stackRef }) {
    commit('START_FETCH', 'stack')
    commit('CLEAR_STACK_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getServiceCatalog(stackRef, orgCanonical) || {}
    if (data) {
      commit('SET_STACK', data)
      const catalogRepositoryCanonical = data?.service_catalog_source_canonical
      const canGetCatalogRepository = Vue.prototype.$cycloid.permissions.canDisplay('GetServiceCatalogSource', catalogRepositoryCanonical)
      if (catalogRepositoryCanonical && canGetCatalogRepository) {
        await dispatch('catalogRepository/GET_CATALOG_REPOSITORY', { catalogRepositoryCanonical })
      }
    }
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    commit('STOP_FETCH', 'stack')
  },

  async GET_STACK_CONFIG ({ commit, rootGetters: { orgCanonical } }, { stackRef, query = [] }) {
    commit('CLEAR_STACK_ERRORS', 'stackConfig')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getServiceCatalogConfig(stackRef, orgCanonical, ...query) || {}
    if (data) commit('SET_STACK_CONFIG', { config: data })
    if (errors) commit(`SET_ERRORS`, { key: 'stackConfig', errors })
    return !_.isEmpty(data)
  },

  async CREATE_STACK_FROM_TEMPLATE ({ commit, dispatch, rootGetters: { orgCanonical } }, { stack, $router }) {
    commit('CLEAR_STACK_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createServiceCatalogFromTemplate(orgCanonical, stack) || {}
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    if (data) {
      $router.push({ name: 'stack', params: { stackRef: data.ref } })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.stacks.created') }, { root: true })
    }
  },

  async UPDATE_STACK ({ commit, dispatch, rootGetters: { orgCanonical } }, { stack }) {
    commit('CLEAR_STACK_ERRORS')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateServiceCatalog(orgCanonical, stack.ref, stack) || {}
    if (errors) commit('SET_ERRORS', { key: 'stack', errors })
    if (data) {
      commit('SET_STACK', stack)
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.stacks.updated', { stackName: stack.name }) }, { root: true })
    }
  },

  async VALIDATE_STACK_DEPENDENCIES ({ commit, rootGetters: { orgCanonical } }, { stackRef }) {
    commit('CLEAR_STACK_ERRORS', 'dependencies')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.validateServiceCatalogDependencies(stackRef, orgCanonical) || {}
    if (data) commit('SET_ERRORS', { key: 'dependencies', errors: [..._.get(data, 'errors', []), ..._.get(data, 'warnings', [])] })
    if (errors) commit('SET_ERRORS', { key: 'dependencies', errors })
  },

  async DELETE_STACK ({ commit, dispatch, rootGetters: { orgCanonical } }, { stack, $router }) {
    commit('CLEAR_STACK_ERRORS')

    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteServiceCatalog(orgCanonical, stack.ref) || {}
    if (errors) return commit('SET_ERRORS', { key: 'stack', errors })
    $router.push({ name: 'stacks' })
    commit('RESET_STACK_STATE', 'detail')
    dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.stacks.deleted', { stackName: stack.name }) }, { root: true })
  },
}

export const mutations = {
  CLEAR_STACK_ERRORS: CLEAR_ERRORS,
  RESET_STACK_STATE: RESET_STATE,
  SET_ERRORS,
  START_FETCH,
  STOP_FETCH,

  SET_SEARCH (state, value) {
    state.searchTerm = value
  },

  SET_STACK (state, stack) {
    stack.description = stack.description.substring(0, DESCRIPTION_LIMIT)
    Vue.set(state, 'detail', stack)
  },

  SET_STACK_CONFIG (state, { config: theConfig }) {
    const config = _.cloneDeep(theConfig)

    for (const [key, useCase] of _.entries(config)) {
      if (!_.has(useCase, 'cloud_provider')) continue

      config[key] = new ObjWithPrivateProps(useCase, {
        _cloudProvider: {
          canonical: useCase.cloud_provider,
        },
      })
    }

    Vue.set(state, 'config', config)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,

  modules: parseModules({
    CatalogRepository,
  }),
}
