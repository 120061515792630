import Vue from 'vue'
import formsConditionAndDynamics from '@/assets/vendor/web-assembly/forms-condition-and-dynamics.wasm'
import Go from '@/assets/vendor/web-assembly/wasm_exec'

Vue.prototype.$wasm = {}

/* eslint-disable no-undef, promise/always-return, no-console */
const go = new Go()

const instantiateWasmFromBuffer = async (bufferedBytes, importObject) => {
  const { instance } = await WebAssembly.instantiate(bufferedBytes, importObject)
  return instance
}

const instantiateWasm = async () => {
  try {
    const formsConditionAndDynamicsInstance = await instantiateWasmFromBuffer(formsConditionAndDynamics, go.importObject)

    go.run(formsConditionAndDynamicsInstance)

    // Both 'evaluateCondition' and 'getValuesAndDefault'
    // functions are defined in the Go code
    Vue.prototype.$wasm = {
      ...Vue.prototype.$wasm,
      evaluateCondition,
      getValuesAndDefault,
    }
  } catch (error) {
    console.log(error)
  }
}

instantiateWasm()
