export default {
  en: {
    catalogStatus: {
      allHint: 'Both public and private stacks',
      infraImportName: '@:InfraImport stacks',
      infraImportHint: 'Stacks imported with @:InfraImport',
      ownHint: `All your organization's stacks`,
      ownName: `Organization stacks`,
      privateHint: `Only your organization's private stacks`,
      privateName: 'Private stacks',
      publicHint: `Only Cycloid's public stacks`,
      publicName: 'Public stacks',
      trustedHint: 'All trusted stacks',
      trustedName: 'Trusted stacks',
    },
    cloneInstructions: 'Clone the catalog repository on your local machine to copy or edit stacks. Then commit and push your changes to your target catalog repository.',
    howToClone: 'How to clone',
  },
  es: {
    catalogStatus: {
      allHint: 'Stacks públicos y privados',
      infraImportName: '@:InfraImport stacks',
      infraImportHint: 'Stacks importadas con @:InfraImport',
      ownHint: 'Todos los stacks de tu organizacion',
      ownName: 'Organizacion stacks',
      privateHint: 'Solamente los stacks privados de tu organizacion',
      privateName: 'Stacks privados',
      publicHint: 'Solamente los stacks publicos de Cycloid',
      publicName: 'Stacks públicos',
      trustedHint: 'Todos los stacks de confianza',
      trustedName: 'Stacks de confianza',
    },
    cloneInstructions: 'Clone el repositorio del catálogo en su máquina local para copiar o editar stacks. Luego confirme y envíe sus cambios a su repositorio de catálogo de destino.',
    howToClone: 'Como clonar',
  },
  fr: {
    catalogStatus: {
      allHint: 'Stacks publiques et privées',
      infraImportName: '@:InfraImport stacks',
      infraImportHint: 'Stacks importées avec @:InfraImport',
      ownHint: 'Toutes les stacks de votre organisation',
      ownName: `Stacks d'organisation`,
      privateHint: 'Seulement les stacks privées de votre organisation',
      privateName: 'Stacks privées',
      publicHint: 'Seulement les stacks publiques de Cycloid',
      publicName: 'Stacks publiques',
      trustedHint: 'Toutes les stacks fiables',
      trustedName: 'Stacks fiables',
    },
    cloneInstructions: 'Clonez le dépot de catalogue sur votre machine locale pour copier ou modifier des stacks. Ensuite, validez et transférez vos modifications vers votre dépot de catalogue cible.',
    howToClone: 'Comment cloner',
  },
}
