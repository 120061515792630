import chalk from 'chalk'
import _ from 'lodash'
import throwBetterError from './helpers/throwBetterError'
import typeOf from './helpers/typeOf'

const ERROR = {
  EMPTY_OPTIONS: () => new Error('Options should not be empty, remove the empty options object.'),
  INVALID_OPTIONS: ({ ...invalidOptions }, { ...corrections } = {}) => new Error([
    `Invalid ${_.keys(invalidOptions).length === 1 ? 'option' : 'options'} found:\n`,
    ..._(invalidOptions)
      .keys()
      .map((key) => corrections?.[key]
        ? `\n\t- rename: ${chalk.magenta(key)} to ${chalk.green(corrections?.[key])}`
        : `\n\t- remove: ${chalk.magenta(key)}`)
      .value(),
  ].join('')),
  INVALID_PARAM_TYPE: (args, index, expectedType) => new Error([
    `Invalid argument[${index}], expected type "${expectedType}"`,
    `but received type "${typeOf(args[index])}"`,
  ].join('')),
}

/** Flattens an Object to replace each key/hierarchy with a singular flat path.
 *
 * @param {Object} obj - The Object to flatten
 *
 * ---
 * @returns {Object} An object with only 1 depth of nesting, using all paths as keys.
 *
 * ---
 * @example
 *  _.$flattenObject({ a: { b: {c: 1, d: [3] } } })
 *  // returns { 'a.b.c': 1, 'a.b.d[0]' = 3 }
 */
function $flattenObject (obj = {}) {
  const result = {}

  const flatten = (collection, prefix = '', suffix = '') => {
    _.forEach(collection, (value, key) => {
      const path = `${prefix}${key}${suffix}`

      if (_.isArray(value)) flatten(value, `${path}[`, ']')
      else if (_.isPlainObject(value)) flatten(value, `${path}.`)
      else result[path] = value
    })
  }

  flatten(obj)
  return result
}

/** Returns the `obj[path]` if the value is truthy, else returns the `alternativeValue`.
 *
 * @param {Object} obj              - The Object to check
 * @param {String} path             - The path to check
 * @param {Any}    alternativeValue - The value to return if the path is falsy
 *
 * ---
 * @returns {Any} The value at the path, or the alternativeValue if the path is falsy.
 *
 * ---
 * @example
 * const obj = { a: { b: { c: 'apple' } } }
 * _.$get(obj, 'a.b.c', 'banana')
 * // returns 'apple'
 * _.$get(obj, 'a.b.d', 'banana')
 * // returns 'banana'
 */
function $get (obj, path, alternativeValue) {
  return _.get(obj, path) || alternativeValue
}

/** Finds the first populated value, given an Object and a list of valid keys to check.
 *
 * @param {Object} obj        - The Object to check
 * @param {Array}  validKeys  - The keys to check
 *
 * ---
 * @returns {Any} The first populated value found, or null if none are found.
 *
 * ---
 * @example
 *   const obj = { a: '', b: 2, c: [1, 2, 3], d: 'apple' }
 *   _.$getFirstPopulatedValue(obj, ['a', 'b', 'd'])
 *   // returns 2
 *   _.$getFirstPopulatedValue(obj, ['a', 'd'])
 *   // returns 'apple'
 *   _.$getFirstPopulatedValue(obj, ['a', 'e'])
 *   // returns null
 */
function $getFirstPopulatedValue (obj, validKeys) {
  const sortedValues = _.map(validKeys, (key) => obj[key])
  return _.find(sortedValues) || null
}

/** Returns a string with a list of items from an Array.
 *
 * @param {Array}   theArray
 * @param {Object}  options
 * @param {String}  [options.conjunction='&'] - The conjunction to use between the last two items
 * @param {Boolean} [options.boldItems=false] - Whether to wrap the items in <b> tags
 *
 * ---
 * @returns {String} The list of items as a string.
 *
 * ---
 * @example
 *  _.$getListFromArray(['apple', 'banana', 'cherry', 'date'])
 * // returns 'apple, banana, cherry & date'
 * @example
 * _.$getListFromArray(['apple', 'banana', 'cherry', 'date'], { conjunction: 'and' })
 * // returns 'apple, banana, cherry and date'
 * @example
 * _.$getListFromArray(['apple', 'banana', 'cherry', 'date'], { boldItems: true })
 * // returns '<b>apple</b>, <b>banana</b>, <b>cherry</b> & <b>date</b>'
 */
function $getListFromArray (theArray, { conjunction = '&', boldItems = false } = {}) {
  const array = _.cloneDeep(theArray)
  const isValidArray = _.every(array, _.isString) && !_.isEmpty(array) && _.isArray(array)
  if (!isValidArray) {
    console.warn('[Lodash mixin $getListFromArray] was not passed a valid param. The param must be an Array with all of its items as Strings', array)
    return ''
  }
  const finalItem = array.pop()
  const wrapItem = (item) => boldItems ? `<b>${item}</b>` : item
  return array.length
    ? `${array.map((item) => wrapItem(item)).join(', ')} ${conjunction} ${wrapItem(finalItem)}`
    : `${wrapItem(finalItem)}`
}

/** Throws errors when a Function is passed invalid options.
 *
 * @param {Function}            callee  - The Function itself
 * @param {Function.arguments}  args    - The Function's arguments
 * @param {Object?}             options - The options Object
 * @param {Object}              [options.corrections={}]    - A map for corrections
 * @param {Object}              [options.invalidOptions={}] - A map to flag up invalid options
 *
 * ---
 * @returns {Error} Throws an error if invalid options are found.
 *
 * ---
 * @example // No options
 * function getUser (name, age)
 *   _.$handleInvalidOptions(getUser, arguments)
 *   return `Hello my name is ${name}, I am ${age} years old.`
 * }
 * @example // Has options (no corrections)
 * function getUser (name, { age = 99, ...invalidOptions })
 *   _.$handleInvalidOptions(getUser, arguments, { invalidOptions, calleeName: 'getUser' })
 *   return `Hello my name is ${name}, I am ${age} years old.`
 * }
 * @example // Has options (with corrections)
 * function getUser (name, { age = 99, ...invalidOptions })
 *   _.$handleInvalidOptions(getUser, arguments, {
 *     invalidOptions,
 *     corrections: { year: 'age' } // ! { [commonlyMistakenKey], 'correctKeyName' }
 *   })
 *   return `Hello my name is ${name}, I am ${age} years old.`
 * }
 *
 * ---
 * @throws `No params were passed`                      - _when $handleInvalidOptions was not passed any params._
 * @throws `Invalid argument[index], expected type...`  - _when any $handleInvalidOptions param type is invalid._
 * @throws `Options should not be empty...`             - _when an empty options Object is passed._
 * @throws `Invalid options found...`                   - _when unexpected entries were found on the options Object._
 */
function $handleInvalidOptions (callee, args, { corrections = {}, invalidOptions = {} } = {}) {
  const extraOptions = _.omit(arguments[2], ['corrections', 'invalidOptions'])
  const internalError = _({
    emptyArgs: _.isEmpty(arguments) && new Error('No params were passed.'),
    invalid1stParam: !_.isFunction(arguments[0]) && ERROR.INVALID_PARAM_TYPE(arguments, 0, 'function'),
    invalid2ndParam: !_.isArguments(arguments[1]) && ERROR.INVALID_PARAM_TYPE(arguments, 1, 'arguments'),
    invalid3rdParam: !_.isPlainObject(arguments[2]) && ERROR.INVALID_PARAM_TYPE(arguments, 2, 'object'),
    emptyOptions: _.isEqual(_.last(arguments), {}) && ERROR.EMPTY_OPTIONS(),
    hasExtraOptions: !_.isEmpty(extraOptions) && ERROR.INVALID_OPTIONS(extraOptions),
  }).pickBy().values().find()
  if (internalError) {
    internalError.name = 'Params Error'
    return throwBetterError(internalError, _.$handleInvalidOptions)
  }

  const calleeOptions = _.isPlainObject(_.last(args)) ? _.last(args) : null
  const externalError = _({
    emptyOptions: _.isEqual(calleeOptions, {}) && ERROR.EMPTY_OPTIONS(),
    hasExtraOptions: !_.isEmpty(invalidOptions) && ERROR.INVALID_OPTIONS(invalidOptions, corrections),
  }).pickBy().values().find()
  if (externalError) {
    externalError.name = 'Params Error'
    return throwBetterError(externalError, _.$handleInvalidOptions, { calleeName: callee?.name })
  }
}

/** Returns true if the Object has all the keys passed in the Array.
 * @param {Object} obj        - The Object to check
 * @param {Array}  keysToCheck - The keys to check
 *
 * ---
 * @returns {Boolean} True if all keys are present, else false.
 *
 * ---
 * @example
 * const obj = { a: 1, b: 2, c: 3 }
 * _.$hasAll(obj, ['a', 'b'])
 * // returns true
 * _.$hasAll(obj, ['a', 'd'])
 * // returns false
 */
function $hasAll (obj, keysToCheck) {
  if (typeOf(obj) !== 'object') console.warn(`[Lodash mixin $hasAll] was not passed a valid 1st param, it must be an Object`, obj)
  if (!_.isArray(keysToCheck)) console.warn(`[Lodash mixin $hasAll] was not passed a valid 2nd param, it must be an Array`, keysToCheck)
  if (typeOf(obj) !== 'object' || !_.isArray(keysToCheck)) return false
  return keysToCheck.every((key) => _.has(obj, key))
}

/** Returns true if the Object has any of the keys passed in the Array.
 * @param {Object} obj          - The Object to check
 * @param {Array}  keysToCheck  - The keys to check
 *
 * ---
 * @returns {Boolean} True if any key is present, else false.
 *
 * ---
 * @example
 * const obj = { a: 1, b: 2, c: 3 }
 * _.$hasAny(obj, ['a', 'd'])
 * // returns true
 * _.$hasAny(obj, ['d', 'e'])
 * // returns false
 */
function $hasAny (obj, keysToCheck) {
  if (typeOf(obj) !== 'object') console.warn(`[Lodash mixin $hasAny] was not passed a valid 1st param, it must be an Object`, obj)
  if (!_.isArray(keysToCheck)) {
    console.warn(`[Lodash mixin $hasAny] was not passed a valid 2nd param, it must be an Array`, keysToCheck)
    return false
  }
  return keysToCheck.some((key) => _.has(obj, key))
}

/** Returns true if the value is an empty Object, Array, String, null or undefined.
 * @see Lodash's {@link https://lodash.com/docs/#isEmpty _.isEmpty} only checks empty Objects, Arrays or Strings.
 * @param {Any} value - The value to check
 *
 * ---
 * @returns {Boolean} True if the value is empty, else false.
 *
 * ---
 * @example
 * _.$isEmpty({}) // returns true
 * _.$isEmpty([]) // returns true
 * _.$isEmpty('') // returns true
 * _.$isEmpty(null) // returns true
 * _.$isEmpty(undefined) // returns true
 * _.$isEmpty(0) // returns false
 * _.$isEmpty('0') // returns false
 * _.$isEmpty(false) // returns false
 * _.$isEmpty(true) // returns false
 */
function $isEmpty (value) {
  const canBeEmptyChecked = ['object', 'array', 'string', 'null', 'undefined']
  return canBeEmptyChecked.includes(typeOf(value)) && _.isEmpty(value)
}

/** Returns true if not an Array, Object, null nor undefined
 * @see Lodash's {@link https://lodash.com/docs/#isObject _.isObject} returns true for Arrays and Objects.
 * @see Lodash's {@link https://lodash.com/docs/#isNil _.isNil} returns true for null and undefined.
 *
 * @param {Any} value - The value to check
 *
 * ---
 * @returns {Boolean} True if the value is a primitive, else false.
 *
 * ---
 * @example
 * _.$isPrimitive(0) // returns true
 * _.$isPrimitive('0') // returns true
 * _.$isPrimitive(false) // returns true
 * _.$isPrimitive(true) // returns true
 * _.$isPrimitive([]) // returns false
 * _.$isPrimitive({}) // returns false
 * _.$isPrimitive(null) // returns false
 * _.$isPrimitive(undefined) // returns false
 */
function $isPrimitive (value) {
  return !_.isObject(value) && !_.isNil(value)
}

/** Converts all keys in an object to snake_case.
 *
 * @param {Object} object - The object to convert
 *
 * ---
 * @returns {Object} The object with all keys converted to snake_case.
 *
 * ---
 * @example
 * const obj = { firstName: 'John', lastName: 'Doe' }
 * _.$snakeCaseKeys(obj)
 * // returns { first_name: 'John', last_name: 'Doe' }
 */
function $snakeCaseKeys (object) {
  return _.mapKeys(object, (_value, key) => _.snakeCase(key))
}

/** Converts all keys in an object to camelCase.
 *
 * @param {Object} object - The object to convert
 *
 * ---
 * @returns {Object} The object with all keys converted to camelCase.
 *
 * ---
 * @example
 * const obj = { first_name: 'John', last_name: 'Doe' }
 * _.$camelCaseKeys(obj)
 * // returns { firstName: 'John', lastName: 'Doe' }
 */
function $camelCaseKeys (object) {
  return _.mapKeys(object, (_value, key) => _.camelCase(key))
}

/** Converts a string to PascalCase.
 * @param {String} string - The string to convert
 *
 * ---
 * @returns {String} The string in PascalCase.
 *
 * ---
 * @example
 * _.$pascalCase('the BIG blue Whale... OMG!')
 * // returns 'TheBigBlueWhaleOmg'
 * @example
 * _.$pascalCase('wellHelloThere')
 * // returns 'WellHelloThere'
 * @example
 * _.$pascalCase('hello_world')
 * // returns 'HelloWorld'
 */
function $pascalCase (string) {
  return _.chain(string).camelCase().upperFirst().value()
}

/** Pauses code for x milliseconds.
 *
 * @param {Number} milliseconds - The time to pause for
 *
 * ---
 * @returns {Promise} A Promise that resolves after the milliseconds have passed.
 *
 * ---
 * @example
 * await _.$pause(1000)
 * // waits for 1 second
 */
function $pause (milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

/** Lowercases everything in a string apart from the very first character.
 *
 * @param {String} [text=''] - The text
 *
 * ---
 * @returns {String} The sentence-cased text.
 *
 * ---
 * @example
 * _.$sentenceCase('the BIG blue Whale... OMG!')
 * // returns 'The big blue whale... omg!'
 */
function $sentenceCase (text = '') {
  return _.upperFirst(text.toLowerCase())
}

/** Sorts the keys of an object alphabetically.
 *
 * @param {Object} object - The object to sort
 *
 * ---
 * @returns {Object} The object with its keys sorted alphabetically.
 *
 * ---
 * @example
 * const obj = { b: 2, a: 1, c: 3 }
 * _.$sortObjKeys(obj)
 * // returns { a: 1, b: 2, c: 3 }
 */
function $sortObjKeys (object) {
  return _(object).toPairs().sortBy(0).fromPairs().value()
}

/** Returns a random item from an Array.
 *
 * @param {Array} array - The Array to pick from
 *
 * ---
 * @returns {Any} A random item from the Array
 *
 * ---
 * @example
 * _.$randomFromList(['apple', 'banana', 'cherry', 'date'])
 * // returns 'apple' | 'banana' | 'cherry' | 'date'
 * @example
 * _.$randomFromList([])
 * // returns undefined
 */
function $randomFromList (array = []) {
  if (!_.isArray(array) || _.isEmpty(array)) return console.warn(`[Lodash mixin $randomFromList] was not passed a valid param, it must be an Array`, array)
  return array[_.random(array.length - 1)]
}

const $_ = _.mixin({
  $camelCaseKeys,
  $flattenObject,
  $get,
  $getFirstPopulatedValue,
  $getListFromArray,
  $handleInvalidOptions,
  $hasAll,
  $hasAny,
  $isEmpty,
  $isPrimitive,
  $pascalCase,
  $pause,
  $randomFromList,
  $sentenceCase,
  $snakeCaseKeys,
  $sortObjKeys,
}, { chain: true })

const logMessage = (message) => process.env.NODE_ENV === 'test' ? null : console.info(message)
if (!window) logMessage(`⚠️ Lodash was not loaded, as 'window' was unavailable.`)
else {
  window._ = $_
  logMessage(`🔥 Lodash has been loaded globally @v${$_.VERSION}`)
}

export default $_
