import Vue from 'vue'
import { vuexMixin } from '@/utils/helpers'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment

export const initialState = {
  detail: {},
  isFetched: false,
  errors: [],
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  hasLicence: ({ detail }) => !_.$isEmpty(detail),
  isExpired: ({ detail }) => moment.unix(detail?.expires_at).isSameOrBefore(),
  isMaxMembersReached: (_state, { membersLeft }) => {
    if (membersLeft === null) return null
    return membersLeft <= 0
  },
  isOnPrem: ({ detail }) => !!detail?.on_prem,
  membersLeft: ({ detail }) => {
    const { current_members: membersCount = null, members_count: maxMembers = null } = detail || {}
    return _.some([membersCount, maxMembers], _.isNull)
      ? null
      : maxMembers - membersCount
  },
  membersLeftPercentage: ({ detail }) => {
    const { current_members: membersCount = null, members_count: maxMembers = null } = detail || {}
    return _.some([membersCount, maxMembers], _.isNull)
      ? null
      : Math.round((maxMembers - membersCount) * 100 / maxMembers)
  },
}

const {
  mutations: { SET_ERRORS, CLEAR_ERRORS, RESET_STATE },
} = vuexMixin(initialState)

export const actions = {
  async GET_LICENCE ({ commit, rootGetters: { orgCanonical } }) {
    commit('CLEAR_LICENCE_ERRORS')

    if (_.$isEmpty(orgCanonical)) return

    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getLicence(orgCanonical) || {}

    if (data) {
      commit('SET_LICENCE', data)
      commit('SET_FETCHED', true)
    }
    if (errors) commit('SET_LICENCE_ERRORS', { errors })
  },

  async ACTIVATE_LICENCE ({ commit, dispatch, rootState: { customers }, rootGetters: { orgCanonical } }, { licenceToken }) {
    if (_.$isEmpty(licenceToken) || customers.currentScopeDepth !== 0) return

    commit('CLEAR_LICENCE_ERRORS')

    const { errors } = await Vue.prototype.$cycloid.ydAPI.activateLicence(orgCanonical, { key: licenceToken }) || {}

    if (!_.$isEmpty(errors)) commit('SET_LICENCE_ERRORS', { errors })
    else await dispatch('GET_LICENCE')
  },
}

export const mutations = {
  CLEAR_LICENCE_ERRORS: CLEAR_ERRORS,
  RESET_LICENCE_STATE: RESET_STATE,
  SET_LICENCE_ERRORS: SET_ERRORS,

  SET_LICENCE (state, detail) {
    Vue.set(state, 'detail', detail)
  },

  SET_FETCHED (state, isFetched) {
    Vue.set(state, 'isFetched', isFetched)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  mutations,
  actions,
}
