export default {
  install (Vue) {
    Vue.directive('fix-autofill-rendering', {
      bind (el) {
        const input = el.querySelector('input')
        const label = el.querySelector('label')
        const errors = []
        const structureError = input && label && !(input.nextElementSibling === label || label.nextElementSibling === input)

        if (!input) errors.push('\n- input not found')
        if (!label) errors.push('\n- label not found')
        if (structureError) errors.push('\n- internal structure check failed: input and label are expected to be immediate siblings')

        if (errors.length) console.error(`v-fix-autofill-rendering${errors.join()}\n`, { input, label, context: el })
        else input.after(label)
      },
    })
  },
}
