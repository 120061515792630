import Vue from 'vue'
import ConfigRepository from '@/store/modules/organization/configRepository'
import Stack from '@/store/modules/organization/stack'
import { ObjWithPrivateProps } from '@/utils/classes'
import ebMapping from '@/utils/config/external-backends-mapping'
import { extractStartStopPipelines, parseModules, vuexMixin } from '@/utils/helpers'
import i18n from '@/utils/plugins/i18n'
import Infraview from './infraview'
import Kpi from './kpi'
import Pipeline from './pipeline'

export const initialState = {
  detail: null,
  environmentsConfig: {},
  errors: {
    env: [],
    environmentsConfig: [],
    externalBackend: [],
    favorite: [],
    forms: [],
    formsValidation: [],
    logEntries: [],
    logSources: [],
    pipelines: [],
    project: [],
  },
  externalBackend: null,
  externalBackends: [],
  fetchInProgress: {
    environmentsConfig: false,
  },
  forms: {
    config: null,
  },
  validatedForms: null,
  logEntries: [],
  logSources: [],
  pipelines: [],
  selected: {
    env: null,
  },
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  configRepositoryBranch: (state) => state.configRepository.detail?.branch,
  configRepositoryCanonical: (state) => state.detail?.config_repository_canonical,
  envForms: (state, _getters) => {
    const { pipelines } = state
    const stackConfig = state.stack.config
    if (_.isEmpty(stackConfig)) return {}

    const nonStartStopPipelines = extractStartStopPipelines(pipelines)?.regular
    return nonStartStopPipelines.reduce((acc, { environment: { canonical }, use_case: useCase }) => ({
      ...acc,
      [canonical]: _.get(stackConfig[useCase], 'forms'),
    }), {})
  },
  envs: (state) => _.get(state.detail, 'environments') || [],
  environmentsConfig: (state) => state.environmentsConfig,
  eventProvider: (state) => state.externalBackends.find(({ purpose }) => purpose === 'events'),
  hasEnvs: (_state, getters) => !_.isEmpty(getters.envs),
  hasNoExternalBackendErrors: (state) => _.isEmpty(state.errors.externalBackend),
  hasProject: (state) => !_.isEmpty(state.detail),
  infraViewExternalBackends: (state) => state.externalBackends.filter(({ purpose }) => purpose === 'remote_tfstate'),
  isFavorite: (state) => !!state.detail?.favorite,
  logProvider: (state) => state.externalBackends.find(({ purpose }) => purpose === 'logs'),
  project: (state) => state.detail,
  projectCanonical: (state) => state.detail?.canonical ?? null,
  projectName: (state) => state.detail?.name ?? null,
  stackCanonical: (state) => state.detail?.service_catalog?.canonical,
  stackRef: (state) => state.detail?.service_catalog?.ref,
}

const { mutations: { SET_ERRORS, CLEAR_ERRORS, RESET_STATE, START_FETCH, STOP_FETCH } } = vuexMixin(initialState)

export const actions = {
  async GET_PROJECT ({ commit, dispatch, state, rootGetters: { orgCanonical } }, { projectCanonical, envCanonical = state.selected.env, fetchDeps = true }) {
    commit('CLEAR_PROJ_ERRORS', 'project')

    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getProject(orgCanonical, projectCanonical) || {}
    if (data) {
      commit('SET_PROJECT', data)
      if (!_.isEmpty(data.environments)) {
        const env = data.environments.includes(envCanonical) ? envCanonical : data.environments[0].canonical
        commit('SELECT_ENV', env)
      }
      if (fetchDeps) dispatch('GET_PROJECT_DEPS')
    }
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
  },

  async GET_PROJECT_DEPS ({ dispatch, state }) {
    const configRepositoryCanonical = state.detail?.config_repository_canonical
    const canGetConfigRepository = Vue.prototype.$cycloid.permissions.canDisplay('GetConfigRepository', configRepositoryCanonical)
    const stackRef = state.detail?.service_catalog?.ref

    if (configRepositoryCanonical && canGetConfigRepository) {
      await dispatch('configRepository/GET_CONFIG_REPOSITORY', { configRepositoryCanonical })
    }

    if (stackRef) {
      await dispatch('stack/GET_STACK', { stackRef })
      await dispatch('stack/GET_STACK_CONFIG', { stackRef })
    }
  },

  async GET_PROJECT_ENVS ({ commit, dispatch, rootGetters: { orgCanonical, projectCanonical } }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getProject(orgCanonical, projectCanonical) || {}
    if (data) commit('SET_PROJECT_ENVS', data)
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
  },

  async CREATE_PROJECT ({ commit, dispatch, rootGetters: { orgCanonical } }, project) {
    commit('CLEAR_PROJ_ERRORS', 'project')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createProject(orgCanonical, { ...project }) || {}
    if (data) {
      const content = i18n.t('alerts.success.project.created', { projectName: project.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { key: 'project', errors })
    return _.isEmpty(errors)
  },

  async UPDATE_PROJECT ({ commit, dispatch, rootGetters: { orgCanonical } }, { refetchProject = true, displaySuccessMessage = true, project, successMessage, refreshConfigForEnvCanonical }) {
    commit('CLEAR_PROJ_ERRORS', 'project')
    if (project?.service_catalog?.ref) project.service_catalog_ref = project.service_catalog.ref

    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateProject(orgCanonical, project) || {}
    if (data) {
      if (refetchProject) await dispatch('GET_PROJECT', { projectCanonical: data.canonical })
      if (refreshConfigForEnvCanonical) await dispatch('GET_PROJECT_ENVIRONMENT_CONFIG', refreshConfigForEnvCanonical)
      const content = successMessage || i18n.t('alerts.success.project.updated', { projectName: project.name })
      if (displaySuccessMessage) dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    if (errors) {
      commit('SET_ERRORS', { key: 'project', errors })
      dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    }
    return _.isEmpty(errors)
  },

  async DELETE_PROJECT ({ commit, dispatch, rootGetters: { orgCanonical } }, project) {
    commit('CLEAR_PROJ_ERRORS', 'project')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteProject(orgCanonical, project.canonical) || {}
    if (errors) commit('SET_ERRORS', { key: 'project', errors })
    else {
      const content = i18n.t('alerts.success.project.deleted', { projectName: project.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    return _.isEmpty(errors)
  },

  async CREATE_ENV ({ commit, dispatch, rootGetters: { orgCanonical, projectCanonical } }, { displaySuccessMessage = true, environment }) {
    commit('CLEAR_PROJ_ERRORS', 'env')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.createProjectEnvironment(orgCanonical, projectCanonical, { ...environment }) || {}
    if (errors) {
      dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
      commit('SET_ERRORS', { key: 'env', errors })
    } else {
      const content = i18n.t('alerts.success.project.env.created', { envCanonical: environment.canonical })
      if (displaySuccessMessage) dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    return _.isEmpty(errors)
  },

  async UPDATE_ENV ({ commit, dispatch, rootGetters: { orgCanonical, projectCanonical } }, { displaySuccessMessage = true, environment, refreshConfig, refreshProject = false }) {
    commit('CLEAR_PROJ_ERRORS', 'env')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.updateProjectEnvironment(orgCanonical, projectCanonical, { ...environment }) || {}
    if (errors) {
      dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
      commit('SET_ERRORS', { key: 'env', errors })
    } else {
      if (refreshProject) await dispatch('GET_PROJECT', { projectCanonical })
      if (refreshConfig) await dispatch('GET_PROJECT_ENVIRONMENT_CONFIG', environment.canonical)
      const content = i18n.t('alerts.success.environment.updated')
      if (displaySuccessMessage) dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
  },

  async DELETE_ENV ({ commit, dispatch, state, rootGetters: { orgCanonical, projectCanonical } }, { displaySuccessMessage = true, envCanonical }) {
    commit('CLEAR_PROJ_ERRORS', 'env')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteProjectEnvironment(orgCanonical, projectCanonical, envCanonical) || {}
    if (errors) commit('SET_ERRORS', { key: 'env', errors })
    else {
      const project = _.cloneDeep(state.detail)
      project.environments = _.filter(state.detail.environments, ({ canonical }) => canonical !== envCanonical)
      commit('SET_PROJECT', project)
      const content = i18n.t('alerts.success.project.env.deleted', { envCanonical })
      if (displaySuccessMessage) dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
  },

  async CREATE_EXTERNAL_BACKEND ({ commit, rootGetters: { orgCanonical, projectCanonical } }, externalBackend) {
    commit('CLEAR_PROJ_ERRORS', 'externalBackend')
    if (_.has(externalBackend, 'project_canonical')) externalBackend.project_canonical = projectCanonical
    const { errors } = await Vue.prototype.$cycloid.ydAPI.createExternalBackend(orgCanonical, externalBackend) || {}
    if (errors) commit('SET_ERRORS', { key: 'externalBackend', errors })
    return _.isEmpty(errors)
  },

  async GET_EXTERNAL_BACKENDS ({ commit, dispatch, rootGetters: { orgCanonical, projectCanonical } }) {
    commit('CLEAR_PROJ_ERRORS', 'externalBackend')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getExternalBackends(orgCanonical) || {}
    if (data) {
      const processedData = _(data)
        .filter((eb) => eb.project_canonical === projectCanonical || eb.purpose === 'events')
        .map(ebMapping.remap)
        .value()
      commit('SET_EXTERNAL_BACKENDS', processedData)
    }
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    return _.isEmpty(errors)
  },

  async GET_EXTERNAL_BACKEND ({ commit, dispatch, rootGetters: { orgCanonical } }, { externalBackendId }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getExternalBackend(orgCanonical, externalBackendId) || {}
    if (data) commit('SET_EXTERNAL_BACKEND', ebMapping.remap(data))
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    return _.isEmpty(errors)
  },

  async UPDATE_EXTERNAL_BACKEND ({ commit, dispatch, rootGetters: { orgCanonical } }, { externalBackendId, config }) {
    commit('CLEAR_PROJ_ERRORS', 'externalBackend')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateExternalBackend(orgCanonical, externalBackendId, config) || {}
    if (data) commit('SET_EXTERNAL_BACKEND', data)
    if (errors) {
      dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
      commit('SET_ERRORS', { key: 'externalBackend', errors })
    }
    return _.isEmpty(errors)
  },

  async DELETE_EXTERNAL_BACKEND ({ dispatch, rootGetters: { orgCanonical } }, { externalBackendId }) {
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteExternalBackend(orgCanonical, externalBackendId) || {}
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    return _.isEmpty(errors)
  },

  async GET_PROJECT_ENVIRONMENT_CONFIG ({ commit, rootGetters: { orgCanonical, projectCanonical } }, envCanonical) {
    commit('CLEAR_PROJ_ERRORS', 'environmentsConfig')
    commit('CLEAR_PROJ_ERRORS', 'forms')
    commit('START_FETCH', 'environmentsConfig')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getProjectEnvironmentConfig(orgCanonical, projectCanonical, envCanonical) || {}
    if (data) commit('SET_PROJECT_ENVIRONMENT_CONFIG', { envCanonical, config: data })
    if (errors) commit('SET_ERRORS', { key: 'environmentsConfig', errors })
    commit('STOP_FETCH', 'environmentsConfig')
  },

  async GET_PROJECT_PIPELINES ({ commit, rootGetters: { orgCanonical, projectCanonical } }) {
    commit('CLEAR_PROJ_ERRORS', 'pipelines')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getProjectPipelines(orgCanonical, projectCanonical) || {}
    if (data) commit('SET_PROJECT_PIPELINES', _.sortBy(data, ['name']))
    if (errors) commit('SET_ERRORS', { key: 'pipelines', errors })
  },

  async CREATE_FORMS_CONFIG ({ commit, rootGetters: { orgCanonical, projectCanonical } }, formsConfig) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createFormsConfig(orgCanonical, projectCanonical, formsConfig) || {}
    if (data) commit('SET_FORMS_CONFIG', data)
    if (errors) commit('SET_ERRORS', { key: 'forms', errors })
  },

  async VALIDATE_FORMS_CONFIG ({ commit, rootGetters: { orgCanonical } }, formsFile) {
    commit('CLEAR_PROJ_ERRORS', 'formsValidation')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.validateFormsFile(orgCanonical, formsFile) || {}
    if (errors) {
      // If processing errors were found, hide the previous forms
      commit('SET_ERRORS', { key: 'formsValidation', errors })
      commit('SET_VALIDATED_FORMS', null)
    } else if (data) {
      const { forms, errors: formErrors } = data
      commit('SET_VALIDATED_FORMS', forms)

      // Feedback errors are rendered together with the forms
      if (!_.isEmpty(formErrors)) commit('SET_ERRORS', { key: 'formsValidation', errors: formErrors })
    }
  },

  async RESET_FORM_VALIDATION_STATE ({ commit }) {
    commit('SET_VALIDATED_FORMS', null)
    commit('CLEAR_PROJ_ERRORS', 'formsValidation')
  },

  async GET_LOG_ENTRIES ({ commit, rootGetters: { orgCanonical, projectCanonical } }, { envCanonical, source, begin, end, query }) {
    commit('CLEAR_PROJ_ERRORS', 'logEntries')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getProjEnvLogEntries({ orgCanonical, projectCanonical, envCanonical, source, begin, end, query }) || {}
    if (data) commit('SET_LOG_ENTRIES', data)
    if (errors) commit('SET_ERRORS', { key: 'logEntries', errors })
  },

  async GET_LOG_SOURCES ({ commit, dispatch, rootGetters: { orgCanonical, projectCanonical } }, envCanonical) {
    commit('CLEAR_PROJ_ERRORS', 'logSources')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getProjEnvLogSources(orgCanonical, projectCanonical, envCanonical) || {}
    if (data) commit('SET_LOG_SOURCES', data)
    else commit('SET_LOG_SOURCES', [])
    if (errors && errors[0].code !== 'NotFound') {
      dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    }
  },

  async SET_FAVORITE_PROJECT ({ commit, dispatch, state, rootGetters: { orgCanonical } }, project) {
    commit('CLEAR_PROJ_ERRORS', 'favorite')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.createProjectFavorite(orgCanonical, project.canonical) || {}
    if (errors) return commit('SET_ERRORS', { key: 'favorite', errors })

    if (state.detail) commit('TOGGLE_FAVORITE')
    const content = i18n.t('alerts.success.project.favorite.created', { projectName: project.name })
    dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
  },

  async UNSET_FAVORITE_PROJECT ({ commit, dispatch, state, rootGetters: { orgCanonical } }, project) {
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteProjectFavorite(orgCanonical, project.canonical) || {}
    if (errors) return commit('SET_ERRORS', { key: 'favorite', errors })

    if (state.detail) commit('TOGGLE_FAVORITE')
    const content = i18n.t('alerts.success.project.favorite.deleted', { projectName: project.name })
    dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
  },
}

export const mutations = {
  CLEAR_PROJ_ERRORS: CLEAR_ERRORS,
  RESET_PROJ_STATE: RESET_STATE,
  START_FETCH,
  STOP_FETCH,
  SET_ERRORS,

  SET_PROJECT (state, project) {
    Vue.set(state, 'detail', parseProjectEnvironments(project))
  },

  SET_PROJECT_ENVS (state, project) {
    Vue.set(state.detail, 'environments', parseProjectEnvironments(project).environments)
  },

  SET_PROJECT_ENVIRONMENT_CONFIG (state, { envCanonical, config }) {
    Vue.set(state.environmentsConfig, envCanonical, config)
  },

  SET_PROJECT_PIPELINES (state, pipelines) {
    Vue.set(state, 'pipelines', pipelines)
  },

  SET_EXTERNAL_BACKENDS (state, externalBackends) {
    Vue.set(state, 'externalBackends', externalBackends)
  },

  SET_EXTERNAL_BACKEND (state, externalBackend) {
    Vue.set(state, 'externalBackend', externalBackend)
  },

  SELECT_ENV (state, env) {
    Vue.set(state.selected, 'env', env)
  },

  SET_FORMS_CONFIG (state, formsConfig) {
    Vue.set(state.forms, 'config', formsConfig)
  },

  SET_VALIDATED_FORMS (state, forms) {
    Vue.set(state, 'validatedForms', forms)
  },

  SET_LOG_ENTRIES (state, logEntries) {
    Vue.set(state, 'logEntries', logEntries)
  },

  SET_LOG_SOURCES (state, logSources) {
    Vue.set(state, 'logSources', logSources)
  },
  TOGGLE_FAVORITE (state) {
    Vue.set(state.detail, 'favorite', !state.detail.favorite)
  },
}

function parseProjectEnvironments (theProject) {
  const project = _.cloneDeep(theProject)
  project.favorite ??= false

  for (const [index, env] of _.entries(project.environments)) {
    if (!_.isPlainObject(_.get(env, 'cloud_provider'))) continue

    project.environments[index] = new ObjWithPrivateProps(env, {
      _cloudProvider: {
        ...env.cloud_provider,
      },
    })
    project.environments[index].cloud_provider_canonical = env.cloud_provider.canonical
    project.environments[index].cloud_provider_name = env.cloud_provider.name
    delete project.environments[index].cloud_provider
  }

  return project
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,

  modules: parseModules({
    ConfigRepository,
    Infraview,
    Kpi,
    Pipeline,
    Stack,
  }),
}
