import { imageExists } from '@/utils/helpers'
import { getElementRefs } from '@/utils/helpers/rappid'

export default class InfraViewDiagram {
  constructor (diagram) {
    if (_.isEmpty(diagram)) return null
    const { graph, config } = _.cloneDeep(diagram)

    function parseNodes () {
      const getElementImage = (theImage, entityType, type) => {
        let image = theImage
        const imgExists = imageExists(image) && image.endsWith('.svg')
        const isVault = type.startsWith('vault_')

        if (isVault) image = `/static/images/stackcraft/elements/default/vault.svg`
        else if (!imgExists) image = `/static/images/stackcraft/elements/default/${entityType}.svg`
        return image
      }

      return graph.nodes.map((node, z) => {
        const entityType = _.has(node, 'resource') ? 'resource' : 'dataSource'
        const cellType = _.has(node, 'resource') ? 'custom.Resource' : 'custom.DataSource'
        const { id, name: nodeName } = node
        const { image, category, keywords } = node[entityType]
        const { canonical, type, typeKey, name: canonicalName, modules, provider } = getElementRefs(node.canonical)

        return {
          id,
          type: cellType,
          position: {
            x: _.get(node.position, 0, 0),
            y: _.get(node.position, 1, 0),
          },
          size: {
            width: 65,
            height: 65,
          },
          angle: 0,
          z,
          attrs: {
            label: {
              textWrap: {
                text: _.truncate(nodeName, {
                  length: 30,
                  omission: '...',
                }),
              },
            },
            icon: {
              xlinkHref: getElementImage(image, entityType, type),
              opacity: 1,
            },
            config: {
              textWrap: { text: '' },
            },
            name: canonicalName,
            nodeName,
            category,
            keywords: _.fromPairs(Object.entries(keywords)),
            providerId: provider,
            canonical,
            entityType,
            typeKey,
            type,
            modules,
          },
        }
      })
    }

    function parseEdges () {
      const startZ = graph.nodes.length
      return graph.edges.map((node, index) => ({
        ...node,
        type: 'custom.Link',
        connector: { name: 'rounded' },
        labels: [],
        source: { id: node.source },
        target: { id: node.target },
        z: startZ + index,
        attrs: {},
      }))
    }

    this.cells = [
      ...parseNodes(),
      ...parseEdges(),
    ]
    this.config = config
  }
}
