export default {
  en: {
    createdAtDescending: 'Date created: new to old',
    createdAtAscending: 'Date created: old to new',
    timestampDescending: 'Date created: new to old',
    timestampAscending: 'Date created: old to new',
    updatedAtDescending: 'Recently updated',
    nameAscending: 'A-Z',
    nameDescending: 'Z-A',
    emailAscending: 'Email: A-Z',
    emailDescending: 'Email: Z-A',
    enabledDescending: 'Enabled first',
    enabledAscending: 'Disabled first',
    severityDescending: 'Severity: Z-A',
    severityAscending: 'Severity: A-Z',

  },
  es: {
    createdAtDescending: 'Fecha de creación: nuevo a viejo',
    createdAtAscending: 'Fecha de creación: viejo a nuevo',
    timestampDescending: 'Fecha de creación: nuevo a viejo',
    timestampAscending: 'Fecha de creación: viejo a nuevo',
    updatedAtDescending: 'Recientemente actualizado',
    nameAscending: 'A-Z',
    nameDescending: 'Z-A',
    emailAscending: 'Correo electrónico: A-Z',
    emailDescending: 'Correo electrónico: Z-A',
    enabledDescending: 'Activada primero',
    enabledAscending: 'Desactivada primero',
    severityDescending: 'Importancia: Z-A',
    severityAscending: 'Importancia: A-Z',
  },
  fr: {
    createdAtDescending: `Date de création: du nouveau à l'ancien`,
    createdAtAscending: `Date de création: de l'ancien au nouveau`,
    timestampDescending: `Date de création: du nouveau à l'ancien`,
    timestampAscending: `Date de création: de l'ancien au nouveau`,
    updatedAtDescending: 'Récemment mis à jour',
    nameAscending: 'A-Z',
    nameDescending: 'Z-A',
    emailAscending: 'Courriel: A-Z',
    emailDescending: 'Courriel: Z-A',
    enabledDescending: 'Activée en premier',
    enabledAscending: 'Désactivée en premier',
    severityDescending: 'Gravité: Z-A',
    severityAscending: 'Gravité: A-Z',
  },
}
