import Vue from 'vue'
import { vuexMixin } from '@/utils/helpers'

// Needs to be a function to make testing easier
const isStagingDevOrEEnv = () => location.host.startsWith('console-pr-') ||
  ['development', 'staging', 'test'].includes(process.env.NODE_ENV)

const initialShowDevThings = () => isStagingDevOrEEnv()
  ? JSON.parse(localStorage?.[LSK.DEV_SHOW_DEV_THINGS] ?? 'false')
  : false

const initialShowFeature = (key) => {
  if (!isStagingDevOrEEnv()) return false

  const showFeatureLS = JSON.parse(localStorage?.[LSK.DEV_SHOW_FEATURE] ?? '{}')
  const defaultValue = isStagingDevOrEEnv() || false

  return {
    dummy: defaultValue, // ! dummy is used for tests, as it will never be removed
    costEstimation: defaultValue,
    groupResources: defaultValue,
    multipleEmails: defaultValue,
    notifications: defaultValue,
    sso: defaultValue,
    whiteLabel: defaultValue,
    ...showFeatureLS,
  }[key] ?? false
}

export const privateMethods = {
  initialShowDevThings,
  initialShowFeature,
}

export const initialState = {
  betaFeatures: [
    'whiteLabel',
  ],
  showDevLayer: false,
  showDevThings: initialShowDevThings(),
  showDevFeature: {
    dummy: initialShowFeature('dummy'),
    costEstimation: initialShowFeature('costEstimation'),
    groupResources: initialShowFeature('groupResources'),
    multipleEmails: initialShowFeature('multipleEmails'),
    notifications: initialShowFeature('notifications'),
    sso: initialShowFeature('sso'),
    whiteLabel: true,
  },
  mockedEndpointsCalled: [],
  permissionsChecked: [],
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  hasMockedEndpoints: (state) => !_.isEmpty(state.mockedEndpointsCalled),
  hasTooManyPermCalls: (_state, getters) => _.values(getters.permissionsCount).some((nb) => nb > 6),
  permissionsCount: (state) => state.permissionsChecked.reduce((acc, { action }) => ({
    ...acc,
    [action]: _.get(acc, action, 0) + 1,
  }), {}),
  $isBetaFeature: (state) => _({ ...state.showDevFeature })
    .omit((key) => key === 'dummy')
    .reduce((result, _value, key) => ({
      ...result,
      [key]: state.betaFeatures.includes(key),
    })),
  $showDevThings: (state) => state.showDevThings,
  $showFeature: (state) => _({ ...state.showDevFeature })
    .omit((key) => key === 'dummy')
    .reduce((result, value, key) => ({
      ...result,
      [key]: state.betaFeatures.includes(key) || _.every([state.showDevThings, value]),
    }))
  ,
}

const {
  mutations: { RESET_STATE },
} = vuexMixin(initialState)

export const actions = {}

export const mutations = {
  RESET_DEV_STATE: RESET_STATE,

  TOGGLE_DEV_BTNS (state) {
    if (!isStagingDevOrEEnv()) return
    const newValue = !state.showDevThings
    Vue.set(state, 'showDevThings', newValue)
    localStorage.setItem(LSK.DEV_SHOW_DEV_THINGS, newValue)
  },

  TOGGLE_DEV_LAYER (state, newValue = !state.showDevLayer) {
    if (!isStagingDevOrEEnv()) return
    Vue.set(state, 'showDevLayer', newValue)
  },

  TOGGLE_DEV_FEATURE (state, { key, value }) {
    if (!isStagingDevOrEEnv() || _.isNil(value)) return
    Vue.set(state.showDevFeature, key, value)
    localStorage.setItem(LSK.DEV_SHOW_FEATURE, JSON.stringify(state.showDevFeature))
  },

  ADD_MOCKED_ENDPOINT (state, endpointName) {
    Vue.set(state, 'mockedEndpointsCalled', _.uniq([
      ...state.mockedEndpointsCalled,
      endpointName,
    ]))
  },

  ADD_PERMISSION_FETCHED (state, { action, canonical }) {
    state.permissionsChecked.push({ action, canonical })
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
