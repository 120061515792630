const chalk = require('chalk')

/** Get an Error with a more focussed stack and messaged prefixed with the handler name.
 *
 * @param {Error}     errorToThrow          - The `Error` to throw
 * @param {Function}  handler               - The function that calls `throwBetterError`
 * @param {object}    options
 * @param {String?}   [options.calleeName]  - Defaults to `handler.name`
 *
 * @returns {Error}
*/
function throwBetterError (errorToThrow, handler, { calleeName = handler?.name } = {}) {
  const originalMsg = _.cloneDeep(errorToThrow.message)
  const [, originalStack = ''] = _.cloneDeep(errorToThrow.stack).split('Error:')
  const { stackTrace } = _.transform(originalStack.split('\n'), (acc, cur) => {
    if (acc.hasHandlerNameAppeared) acc.stackTrace = acc.stackTrace.concat(`\n\t${cur}`)
    else acc.hasHandlerNameAppeared = new RegExp(`at \\${handler.name}`).test(cur)
  }, { stackTrace: '', hasHandlerNameAppeared: false })
  const message = chalk.red(calleeName ? `[${calleeName}] ${originalMsg}` : originalMsg)
  errorToThrow.stack = `${message}\n${stackTrace}`

  throw errorToThrow
}

module.exports = throwBetterError
