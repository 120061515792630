import en from './_en'
import es from './_es'
import fr from './_fr'
import untranslated from './untranslated'

const locales = {
  en: {
    ...en,
    untranslated,
  },
  es: {
    ...es,
    untranslated,
  },
  fr: {
    ...fr,
    untranslated,
  },
}

export default locales
