export default {
  en: {
    component: 'Component',
    annualTotal: 'Annual total',
    monthlyTotal: 'Monthly total',
    hourlyTotal: 'Hourly total',
    cost: 'Cost',
    difference: 'Difference',
    estimated: '0 estimated | 1 estimated | {nb} estimated',
    hourly: 'Hourly',
    monthly: 'Monthly',
    quantity: 'Quantity',
    resource: {
      id: 'Resource IDs',
      name: 'Resource name',
      type: 'Resource type',
      text: 'Resource',
    },
    resources: 'Resources',
    unestimated: '{nb} unestimated | {nb} unestimated | {nb} unestimated',
    unit: 'Unit',
    betaWarning: 'This feature currently supports a limited set of resources.<br>Provided estimations may be inaccurate.',
  },
  es: {
    component: 'Componente',
    annualTotal: 'Total anual',
    monthlyTotal: 'Total mensual',
    hourlyTotal: 'Total por hora',
    cost: 'Coste',
    difference: 'Diferencia',
    estimated: '0 estimados | 1 estimado | {nb} estimados',
    hourly: 'Cada hora',
    monthly: 'Cada mes',
    quantity: 'Cantidad',
    resource: {
      id: 'ID de recursos',
      name: 'Nombre del recurso',
      type: 'Tipo de recurso',
      text: 'Recurso',
    },
    resources: 'Recursos',
    unestimated: '0 no estimados | 1 no estimado | {nb} no estimados',
    unit: 'Unidad',
    betaWarning: 'Esta feature actualmente soporta un número limitado de recursos. La estimación otorgada puede ser inexacta.',
  },
  fr: {
    component: 'Composant',
    annualTotal: 'Total annuel',
    monthlyTotal: 'Total mensuel',
    hourlyTotal: 'Total horaire',
    cost: 'Coût',
    difference: 'Différence',
    estimated: '0 estimée | 1 estimée | {nb} estimées',
    hourly: 'Horaire',
    monthly: 'Mensuel',
    quantity: 'Quantité',
    resource: {
      id: 'ID de ressource',
      name: 'Nom de la ressource',
      type: 'Type de ressource',
      text: 'Ressource',
    },
    resources: 'Ressources',
    unestimated: '0 non estimée | 1 non estimée | {nb} non estimées',
    unit: 'Unité',
    betaWarning: 'Cette fonctionalité supporte un nombre de ressources limité.<br>Les estimations données peuvent ne pas être exactes.',
  },
}
