import Vue from 'vue'
import { vuexMixin } from '@/utils/helpers'
import { formatBytes } from '@/utils/helpers/quotas'
import i18n from '@/utils/plugins/i18n'

export const initialState = {
  overview: [],
  usage: [],
  detail: null,
  errors: {
    quota: [],
  },
  requirements: {},
  envsResourcePool: {},
  validatedEnvs: [],
  requirementErrors: {},
  originalRequirements: {},
  fetchingRequirements: {},
  debouncing: {},
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  envRequirement: (state) => (env) => _.get(state.requirements, env, null),
  getAvailableByType: (state) => (quota, type, env) => {
    if (!quota) return 0
    const resourcePoolCanonical = _.get(quota, 'resource_pool.canonical')
    const total = _.get(quota, type)
    const allocationToIgnore = _.get(state.originalRequirements, `${env}.${type}`, 0)
    const allocated = _.get(quota, `used_${type}`) - allocationToIgnore
    const availableOnQuota = total - allocated

    const envsWithSameRP = _.toPairs(state.envsResourcePool)
      .filter(([_key, value]) => value === resourcePoolCanonical)
      .map(([key]) => key)
    const validatedEnvsWithSameRP = _.intersection(envsWithSameRP, _.without(state.validatedEnvs, env))
    const validatedRequirements = _.fromPairs(_.toPairs(state.requirements).filter(([key]) => validatedEnvsWithSameRP.includes(key)))
    const consumedByValidatedEnvs = _.sumBy(_.values(validatedRequirements), type)

    return availableOnQuota - consumedByValidatedEnvs
  },
  getTotalsText: (state) => {
    const requirementValues = _.values(state.requirements)

    const totalCpu = _.sumBy(requirementValues, 'cpu')
    const totalMemory = _.sumBy(requirementValues, 'memory')
    const totalStorage = _.sumBy(requirementValues, 'storage')

    const cpuText = `${totalCpu} ${i18n.t('quotas.cores').toLowerCase()}`
    const memoryText = `${formatBytes(totalMemory)} ${i18n.t('quotas.memory').toLowerCase()}`
    const storageText = `${formatBytes(totalStorage)} ${i18n.t('quotas.storage').toLowerCase()}`

    return `${cpuText} • ${memoryText} • ${storageText}`
  },
  isEnvValid: (state, getters, rootState) => (env) => {
    if (state.requirementErrors[env]) return false
    const resourcePoolCanonical = state.envsResourcePool[env]
    const quotas = rootState.organization.available.quotas
    const quota = _.find(quotas, ['resource_pool.canonical', resourcePoolCanonical])
    const envRequirements = _.get(state.requirements, env, {})

    if (_.some([quotas, resourcePoolCanonical, quota, envRequirements], _.isEmpty)) return false
    if (_.get(state.fetchingRequirements, env, false)) return false

    return !_.some(
      _.entries(envRequirements),
      ([type, required]) => required > getters.getAvailableByType(quota, type, env),
    )
  },
  getQuotasOverview: (state) => {
    const { overview: quotas } = state
    return ['memory', 'storage', 'cpu'].map((type) => ({
      type,
      used: _.sumBy(quotas, `used_${type}`),
      allocated: _.sumBy(quotas, type),
    }))
  },
}

const {
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  async GET_OVERVIEW ({ commit, rootGetters: { orgCanonical } }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.listQuotas(orgCanonical) || {}
    if (data) commit('SET_OVERVIEW', data)
    if (errors) commit('SET_ERRORS', { key: 'quota', errors })
  },

  async CREATE_QUOTA ({ commit, dispatch, rootGetters: { orgCanonical } }, { $router, quota }) {
    commit('CLEAR_QUOTA_ERRORS', 'quota')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createQuota(orgCanonical, quota) || {}
    if (data) {
      commit('SET_QUOTA', data)
      if ($router) $router.push({ name: 'quotas', params: { orgCanonical } })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.quota.created') }, { root: true })
    }
    if (errors) commit('SET_ERRORS', { key: 'quota', errors })
  },

  async GET_QUOTA ({ commit, rootGetters: { orgCanonical } }, { quotaId }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getQuota(orgCanonical, quotaId) || {}
    if (data) commit('SET_QUOTA', data)
    if (errors) commit('SET_ERRORS', { key: 'quota', errors })
  },

  async UPDATE_QUOTA ({ commit, dispatch, rootGetters: { orgCanonical } }, { quota, $router }) {
    commit('CLEAR_QUOTA_ERRORS', 'quota')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.updateQuota(orgCanonical, quota) || {}
    if (errors) commit(`SET_ERRORS`, { key: 'quota', errors })
    else {
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.quota.updated') }, { root: true })
      if ($router) $router.push({ name: 'quotas' })
    }
  },

  async DELETE_QUOTA ({ commit, dispatch, rootGetters: { orgCanonical } }, { quotaId, $router }) {
    commit('CLEAR_QUOTA_ERRORS', 'quota')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteQuota(orgCanonical, quotaId) || {}
    if (errors) commit('SET_ERRORS', { key: 'quota', errors })
    else {
      if ($router) $router.push({ name: 'quotas' })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t('alerts.success.quota.deleted') }, { root: true })
      commit('RESET_QUOTA_STATE')
    }
  },

  async GET_QUOTA_USAGE ({ commit, rootGetters: { orgCanonical } }, { quotaId }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getQuotaUsage(orgCanonical, quotaId) || {}
    if (data) commit('SET_USAGE', data)
    if (errors) commit('SET_ERRORS', { key: 'quota', errors })
  },

  async GET_ENV_REQUIREMENT ({ commit, rootGetters: { orgCanonical } }, { projectCanonical, stackFormsInput, env, useCase }) {
    commit('SET_REQUIREMENT_ERRORS', { env, errors: null })
    commit('SET_FETCHING_REQUIREMENT', { env, value: true })
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getEnvQuotaRequirement(orgCanonical, projectCanonical, { stackFormsInput, env }) || {}
    if (data) commit('SET_ENV_REQUIREMENT', { env, requirement: data[env][useCase] })
    else commit('SET_REQUIREMENT_ERRORS', { env, errors: errors || 'an unexpected error happened' })
    commit('SET_FETCHING_REQUIREMENT', { env, value: false })
  },
}

export const mutations = {
  CLEAR_QUOTA_ERRORS: CLEAR_ERRORS,
  RESET_QUOTA_STATE: RESET_STATE,
  SET_ERRORS,

  SET_OVERVIEW (state, overview) {
    Vue.set(state, 'overview', overview)
  },

  SET_QUOTA (state, quota) {
    Vue.set(state, 'detail', quota)
  },

  SET_USAGE (state, usage) {
    Vue.set(state, 'usage', usage)
  },

  SET_ENV_REQUIREMENT (state, { env, requirement }) {
    Vue.set(state.requirements, env, requirement)
  },

  SET_ORIGINAL_ENV_REQUIREMENT (state, { env, requirement }) {
    Vue.set(state.originalRequirements, env, requirement)
  },

  SET_ENV_RESOURCE_POOL (state, { env, canonical }) {
    Vue.set(state.envsResourcePool, env, canonical)
  },

  INVALIDATE_ENV (state, { env }) {
    Vue.set(state, 'validatedEnvs', _.without(state.validatedEnvs, env))
  },

  VALIDATE_ENV (state, { env }) {
    Vue.set(state, 'validatedEnvs', _.union(state.validatedEnvs, [env]))
  },

  RESET_ENV_DATA (state, env) {
    Vue.set(state.requirements, env, undefined)
    Vue.set(state.envsResourcePool, env, undefined)
    Vue.set(state, 'validatedEnvs', _.without(state.validatedEnvs, env))
    Vue.set(state.originalRequirements, env, undefined)
  },

  SET_FETCHING_REQUIREMENT (state, { env, value }) {
    Vue.set(state.fetchingRequirements, env, value)
  },

  SET_DEBOUNCING_ENV_CHANGE (state, { env, value }) {
    Vue.set(state.debouncing, env, value)
  },

  SET_REQUIREMENT_ERRORS (state, { env, errors }) {
    Vue.set(state.requirementErrors, env, errors)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
