const LSK = {
  /* eslint sort-keys: "error" */
  APP_VERSION: 'appVersion',
  CCM_DASHBOARD_NOTIFICATIONS: 'cloud-cost-management/notifications',
  CCM_DISMISSED_ACCOUNT_ERROR: 'cloud-cost-management/notifications/dismissedAccountErrors',
  CCM_DISMISSED_ACCOUNT_IMPORT: 'cloud-cost-management/notifications/dismissedAccountImports',
  CCM_GRAPHS_STATE: 'cloud-cost-management/graphs',
  CCM_GROUP_BY_NOTIFICATIONS: 'cloud-cost-management/group-by/notifications',
  CCM_SIDEBAR_COLLAPSED: 'cloud-cost-management/sidebar/collapsed',
  CCM_TAGS_NOTIFICATIONS: 'cloud-cost-management/tags/notifications',
  DEV_SHOW_DEV_THINGS: 'showDevThings',
  DEV_SHOW_FEATURE: 'showDevFeature',
  ENVIRONMENT: 'environment',
  JWT: 'vuex/auth/jwt',
  ORGANIZATION: 'vuex/organization/detail',
  OWNS: 'vuex/auth/owns',
  PERMISSIONS: 'vuex/auth/permissions',
  PROFILE: 'vuex/user/profile',
  REDIRECT_ROUTE: 'vuex/auth/afterLogIn/redirectRoute',
  SIGNUP_EMAIL: 'signupEmail',
  SSO_TOKEN_GITHUB: 'github_id_token',
  STACKFORMS_FORM_VALIDATION: 'stackforms/form-validation',
}
window.LSK = LSK

export default LSK
