export default {
  en: {
    arrayMinLength: 'Array must have at least 1 element | Array must have at least {number} elements',
    arrayMaxLength: 'Array must have no more than 1 element | Array must have no more than {number} elements',
    btnAdd: 'Add',
    btnApply: 'Apply',
    btnCancel: 'Cancel',
    btnClear: 'Clear',
    btnClose: 'Close',
    btnConfirm: 'Confirm',
    btnContinue: 'Continue',
    btnCreate: 'Create',
    btnDelete: 'Delete',
    btnDisable: 'Disable',
    btnEdit: 'Edit',
    btnEnable: 'Enable',
    btnRefresh: 'Refresh',
    btnRemove: 'Remove',
    btnReset: 'Reset',
    btnSave: 'Save',
    btnSelect: 'Select',
    btnUseThisStack: 'Use this stack',
    btnUpdate: 'Update',
    clearAll: 'Clear all',
    copyToClipboard: 'Copy to clipboard',
    fieldBranch: 'Branch',
    fieldCountry: 'Country',
    fieldCredentialTypeAWS: 'AWS',
    fieldCredentialTypeSSH: 'SSH',
    fieldDefaultRoles: 'Default Roles',
    fieldDescription: 'Description',
    fieldEmail: 'Email',
    fieldEmails: 'Emails',
    fieldImageNotFound: 'Cannot download an image from this URL',
    fieldInvalidGitUrl: 'Invalid Git URL format',
    fieldInvalidUrl: 'Invalid URL format',
    fieldKeyValue: 'Key/Value',
    fieldLanguage: 'Language',
    fieldMaxLength: 'This field must not exceed {number} characters',
    fieldMinLength: 'This field must have at least {number} characters',
    fieldMustBeUnique: 'This field must be unique',
    fieldName: 'Name',
    fieldNotAlphaNum: 'This field contains invalid characters',
    fieldNotEmail: 'This is not a valid email',
    fieldNotIpAddress: 'This is not a valid ip address',
    fieldOrgEnv: 'Environment',
    fieldOrgEnvs: 'Environments',
    fieldOrgName: 'Organization name',
    fieldPassword: 'Password',
    fieldPath: 'Path',
    fieldRequired: 'This field is required',
    fieldSearch: 'Search',
    fieldUsername: 'Username',
    field: {
      timeRange: 'Time range',
      timeRangeCustom: 'Custom range',
      timeRangeEnd: 'To',
      timeRangeLastYear: 'Last year',
      timeRangeLastMonths: 'Last month | Last {months} months',
      timeRangeLastWeek: 'Last week',
      timeRangeLastDays: 'Last day | Last {count} days',
      timeRangeLastHours: 'Last hour | Last {hours} hours',
      timeRangeLastMinutes: 'Last {minutes} minutes',
      timeRangeStart: 'From',
    },
    granularity: 'Granularity',
    granularityOptions: {
      hourly: 'Hourly',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      yearly: 'Yearly',
    },
    projectCanonicalAlreadyExists: 'Another project is already using the resulting project canonical',
    areYouSure: 'Are you sure you want to proceed?',
    back: 'Back',
    cannotBeUndone: 'This operation cannot be undone.',
    duplicate: 'Duplicate',
    key: 'key',
    loading: 'Loading',
    multiselectArrayRequired: 'When multiselect configuration is active, the default value has to be an array of values.',
    mustExistInArray: 'The following elements must exist in the array: {elements}',
    mustExistKeysInMap: 'The following keys must exist in the map: {keys}',
    next: 'Next',
    noData: 'No data',
    noResults: 'No results',
    noUnsavedChanges: 'No unsaved changes',
    paymentRequired: 'payment required',
    reviewAndSave: 'Review and save',
    role: 'Role',
    searchBy: 'Search by',
    skip: 'Skip',
    status: 'Status',
    step: 'Step',
    type: 'Type',
    value: 'Value',
    view: 'View',
  },
  es: {
    arrayMinLength: 'El array debe tener al menos 1 elemento | El array debe tener al menos {number} elementos',
    arrayMaxLength: 'El array no debe tener más de 1 elemento | El array no debe tener más de {number} elementos',
    btnAdd: 'Añadir',
    btnApply: 'Aplicar',
    btnCancel: 'Cancelar',
    btnClear: 'Limpiar',
    btnClose: 'Cerrar',
    btnConfirm: 'Confirmar',
    btnContinue: 'Seguir',
    btnCreate: 'Crear',
    btnDelete: 'Borrar',
    btnDisable: 'Desactivar',
    btnEdit: 'Editar',
    btnEnable: 'Habilitar',
    btnRefresh: 'Actualizar',
    btnRemove: 'Eliminar',
    btnReset: 'Reinicializar',
    btnSave: 'Guardar',
    btnSelect: 'Elegir',
    btnUseThisStack: 'Utilizar este stack',
    btnUpdate: 'Actualizar',
    clearAll: 'Limpiar todo',
    copyToClipboard: 'Copiar al portapapeles',
    fieldBranch: 'Rama',
    fieldCountry: 'País',
    fieldCredentialTypeAWS: 'AWS',
    fieldCredentialTypeSSH: 'SSH',
    fieldDefaultRoles: 'Roles predeterminados',
    fieldDescription: 'Descripción',
    fieldEmail: 'Correo electrónico',
    fieldEmails: 'Correos electrónicos',
    fieldImageNotFound: 'No se puede descargar una imagen de esta URL',
    fieldInvalidGitUrl: 'La URL Git no es válida',
    fieldInvalidUrl: 'La URL no es válida',
    fieldKeyValue: 'Clave/Valor',
    fieldLanguage: 'Idioma',
    fieldMaxLength: 'Este campo no debe superar los {number} caracteres',
    fieldMinLength: 'Este campo debe contener al menos {number} caracteres',
    fieldMustBeUnique: 'Este campo debe ser único',
    fieldName: 'Nombre',
    fieldNotAlphaNum: 'Este campo contiene caracteres inválidos',
    fieldNotEmail: 'El correo electrónico es inválido',
    fieldNotIpAddress: 'Esta no es una dirección IP válida',
    fieldOrgEnv: 'Entorno',
    fieldOrgEnvs: 'Entornos',
    fieldOrgName: 'Nombre de la organización',
    fieldPassword: 'Contraseña',
    fieldPath: 'Camino',
    fieldRequired: 'Este campo es requerido',
    fieldSearch: 'Buscar',
    fieldUsername: 'Nombre de usuario',
    field: {
      timeRange: 'Intervalo de tiempo',
      timeRangeCustom: 'Período personalizado',
      timeRangeEnd: 'A',
      timeRangeLastYear: 'Último ano',
      timeRangeLastMonths: 'Último mes | Ultimo {months} meses',
      timeRangeLastWeek: 'La semana pasada',
      timeRangeLastDays: 'Último día | Últimos {days} días',
      timeRangeLastHours: 'Última hora | Últimas {hours} horas',
      timeRangeLastMinutes: 'Últimos {minutes} minutos',
      timeRangeStart: 'De',
    },
    granularity: 'Granularidad',
    granularityOptions: {
      hourly: 'Cada hora',
      daily: 'Diaria',
      weekly: 'Semanalmente',
      monthly: 'Mensual',
      quarterly: 'Trimestral',
      yearly: 'Anual',
    },
    projectCanonicalAlreadyExists: 'Otro proyecto ya está usando el canónico resultado',
    areYouSure: '¿Estas seguro que deseas continuar?',
    back: 'Anterior',
    cannotBeUndone: 'Esta operación no se puede deshacer.',
    duplicate: 'Duplicar',
    key: 'clave',
    loading: 'Cargando',
    multiselectArrayRequired: 'Cuando la configuración de selección múltiple está activa, el valor predeterminado debe ser un array de valores.',
    mustExistInArray: 'Los siguientes elementos deben existir en el array: {elements}',
    mustExistKeysInMap: 'Las siguientes claves deben existir en el mapa: {keys}',
    next: 'Siguiente',
    noData: 'Sin datos',
    noResults: 'Sin resultados',
    noUnsavedChanges: 'No hay cambios sin guardar',
    paymentRequired: 'pago requerido',
    reviewAndSave: 'Revisar y guardar',
    role: 'Rol',
    searchBy: 'Buscar por',
    skip: 'Omitir',
    status: 'Estado',
    step: 'Paso',
    type: 'Tipo',
    value: 'Valor',
    view: 'Ver',
  },
  fr: {
    arrayMinLength: 'La liste doit avoir au moins 1 élément | La liste doit avoir au moins {number} éléments',
    arrayMaxLength: 'La liste ne doit pas contenir plus de 1 élément | La liste ne doit pas contenir plus de {number} éléments',
    btnAdd: 'Ajouter',
    btnApply: 'Appliquer',
    btnCancel: 'Annuler',
    btnClear: 'Vider',
    btnClose: 'Fermer',
    btnConfirm: 'Confirmer',
    btnContinue: 'Continuez',
    btnCreate: 'Créer',
    btnDelete: 'Supprimer',
    btnDisable: 'Désactiver',
    btnEdit: 'Éditer',
    btnEnable: 'Activer',
    btnRefresh: 'Rafraîchir',
    btnRemove: 'Retirer',
    btnReset: 'Recomposer',
    btnSave: 'Enregistrer',
    btnSelect: 'Sélectionner',
    btnUseThisStack: 'Utiliser cette stack',
    btnUpdate: 'Modifier',
    clearAll: 'Tout effacer',
    copyToClipboard: 'Copier dans le presse-papier',
    fieldBranch: 'Branche',
    fieldCountry: 'Pays',
    fieldCredentialTypeAWS: 'AWS',
    fieldCredentialTypeSSH: 'SSH',
    fieldDefaultRoles: 'Rôles par défaut',
    fieldDescription: 'Description',
    fieldEmail: 'Courriel',
    fieldEmails: 'Courriels',
    fieldImageNotFound: `Il n'est pas possible de télécharger une image de cette URL`,
    fieldInvalidGitUrl: `L'URL n'est pas une URL Git valide`,
    fieldInvalidUrl: `L'URL n'est pas valide`,
    fieldKeyValue: 'Clé/Valeur',
    fieldLanguage: 'Langue',
    fieldMaxLength: 'Ce champ ne doit pas dépasser {number} caractères',
    fieldMinLength: 'Ce champ doit contenir au moins {number} caractères',
    fieldMustBeUnique: 'Ce champ doit être unique',
    fieldName: 'Nom',
    fieldNotAlphaNum: 'Ce champ contient des caractères invalides',
    fieldNotEmail: `Cet email n'est pas valide`,
    fieldNotIpAddress: `Ce n'est pas une adresse IP valide`,
    fieldOrgEnv: 'Environnement',
    fieldOrgEnvs: 'Environnements',
    fieldOrgName: `Nom de l'organization`,
    fieldPassword: 'Mot de passe',
    fieldPath: 'Chemin',
    fieldRequired: 'Ce champs est requis',
    fieldSearch: 'Rechercher',
    fieldUsername: `Nom d'utilisateur`,
    field: {
      timeRange: 'Intervalle de temps',
      timeRangeCustom: 'Période personalisée',
      timeRangeEnd: 'Au',
      timeRangeLastYear: `L'année dernière`,
      timeRangeLastMonths: 'Le mois dernier | {months} derniers mois',
      timeRangeLastWeek: 'La semaine dernière',
      timeRangeLastDays: 'Dernier jour | {days} derniers jours',
      timeRangeLastHours: 'Dernière heure | {hours} dernières heures',
      timeRangeLastMinutes: '{minutes} dernières minutes',
      timeRangeStart: 'Du',
    },
    granularity: 'Granularité',
    granularityOptions: {
      hourly: 'Par heure',
      daily: 'Par jour',
      weekly: 'Par semaine',
      monthly: 'Par mois',
      quarterly: 'Par trimestre',
      yearly: 'Par an',
    },
    projectCanonicalAlreadyExists: 'Un autre projet utilise déjà la canonique résultante',
    areYouSure: 'Êtes-vous sur de vouloir continuer ?',
    back: 'Précédent',
    cannotBeUndone: 'Cette opération ne peut pas être annulée.',
    duplicate: 'Dupliquer',
    key: 'clé',
    loading: 'Chargement',
    multiselectArrayRequired: 'Lorsque le mode multiselect est actif, la valeur par défaut doit être une liste de valeurs.',
    mustExistInArray: 'Les éléments suivants doivent exister dans le tableau : {elements}',
    mustExistKeysInMap: 'Les clés suivantes doivent exister dans la map : {keys}',
    next: 'Suivant',
    noData: 'Pas de données',
    noResults: 'Pas de résultats',
    noUnsavedChanges: 'Aucune modification non sauvegardée',
    paymentRequired: 'paiement requis',
    reviewAndSave: 'Vérifier et enregistrer',
    role: 'Rôle',
    searchBy: 'Rechercher par',
    skip: 'Passer',
    status: 'Statut',
    step: 'Etape',
    type: 'Type',
    value: 'Valeur',
    view: 'Voir',
  },
}
