import router from '@/router'
import store from '@/store'
import createYDAPI from '@/utils/api'
import createHTTPClient from '@/utils/api/client'
import i18n from '@/utils/plugins/i18n'
import createPermissionChecker from '@/utils/plugins/permissions'
import { createPermissionCheckerMock, createYDAPIMock } from 'mocks/Cycloid'

const ydAPIHTTPClient = createHTTPClient(store, i18n)

export const cycloidPlugin = {
  install (Vue) {
    Vue.prototype.$cycloid = {
      permissions: createPermissionChecker(store, router),
      ydAPI: createYDAPI(ydAPIHTTPClient, store, i18n, router),
    }
  },
}

export const mockCycloidPlugin = {
  install (Vue) {
    Vue.prototype.$cycloid = {
      permissions: createPermissionCheckerMock(),
      ydAPI: createYDAPIMock(),
    }
  },
}

export default cycloidPlugin
