export default {
  en: {
    addConfiguration: 'Add configuration',
    configureHint: `Configure a remote backend to store your environments' {files}. By default, environments are created with the default remote backend configured for your organization.`,
    iacStateManagement: 'IaC state management',
    remoteTfBackendHint: 'The Terraform remote backend used to store state files.',
    iacDescription: `Configure the Terraform remote backend where to store your environment's state files. Cycloid does not store state files, it only acts as a middleware to sync resources in the inventory and to generate infrastructure diagrams. {learnMore}`,
    RemoteBackend: 'Remote backend',
    RemoteTfBackend: '@.capitalize:externalBackends.remoteTfBackend',
    remoteTfBackend: 'Terraform remote backend',
    terraformStateFiles: 'Terraform State files',
  },
  es: {
    addConfiguration: 'Agregar configuración',
    configureHint: 'Configure un backend remoto para almacenar sus entornos para {files}. De forma predeterminada, los entornos se crean con el backend remoto predeterminado configurado para su organización.',
    iacStateManagement: 'Gestión del estado IaC',
    remoteTfBackendHint: 'El backend remoto de Terraform se utiliza para almacenar archivos de estado.',
    iacDescription: 'Configure el backend remoto de Terraform donde almacenar los archivos de estado de su entorno. Cycloid no almacena archivos de estado, solo actúa como middleware para sincronizar recursos en el inventario y generar diagramas de infraestructura. {learnMore}',
    RemoteBackend: 'Backend remoto',
    RemoteTfBackend: '@.capitalize:externalBackends.remoteTfBackend',
    remoteTfBackend: 'backend remoto de Terraform',
    terraformStateFiles: 'los archivos de Terraform State',
  },
  fr: {
    addConfiguration: 'Ajouter une configuration',
    configureHint: 'Configurez un backend distant pour stocker {files} de vos environnements. Par défaut, les environnements sont créés avec le backend distant par défaut configuré pour votre organisation.',
    iacStateManagement: `Gestion de l'état IaC`,
    remoteTfBackendHint: `Le backend distant Terraform utilisé pour stocker les fichiers d'état.`,
    iacDescription: `Configurez le backend distant Terraform où stocker les fichiers d'état de votre environnement. Cycloid ne stocke pas les fichiers d'état, il agit uniquement comme un middleware pour synchroniser les ressources dans l'inventaire et générer des diagrammes d'infrastructure. {learnMore}`,
    RemoteBackend: 'Backend distant',
    RemoteTfBackend: '@.capitalize:externalBackends.remoteTfBackend',
    remoteTfBackend: 'backend Terraform distant',
    terraformStateFiles: 'les fichiers Terraform State',
  },
}
