export default {
  en: {
    alreadyExists: 'The environment {env} already exists for this project',
    create: 'Create environment',
    find: 'Find an environment',
    nameInvalid: 'Only hyphens, underscores and alphanumeric characters allowed. Minimum length is 2 characters.',
    useCase: 'Use case',
  },
  es: {
    alreadyExists: 'El entorno {env} ya existe en este proyecto',
    create: 'Crea un entorno',
    find: 'Encuentra un entorno',
    nameInvalid: 'Solo están permitidos carácteres alfanuméricos, guiones y guiones bajos. La longitud mínima es de 2 carácteres.',
    useCase: 'Caso de uso',
  },
  fr: {
    alreadyExists: `L'environnement {env} existe déjà pour ce projet`,
    create: 'Créer un environnement',
    find: 'Trouver un environnement',
    nameInvalid: `Seuls les caractères alphanumériques, traits d'union et tirets bas sont permis. La longueur minimale est de 2 caractères.`,
    useCase: `Cas d'usage`,
  },
}
