export default {
  organization: 'Una organización es la entidad de raíces de todas las demás entidades.',
  'organization:api_key': 'Las claves API se utilizan para obtener acceso a la organización.',
  'organization:appearance': 'Elija cómo se ve Cycloid para sus usuarios. Utilice los colores y logotipos de su propia marca para mostrarlos en toda la aplicación.',
  'organization:child': 'Permisos para acceder a las sub-organizaciones.',
  'organization:cloud_provider': 'Permisos para enumerar a los proveedores de la nube.',
  'organization:config_repository': 'Un repositorio de configuración es un repositorio de git donde se almacena la configuración de los Stacks.',
  'organization:cloud_cost_management': 'Permisos para acceder a Cloud Cost Management para explorar costos.',
  'organization:cloud_cost_management_account': 'Permisos para acceder a las cuentas de Cloud Cost Management.',
  'organization:cloud_cost_management_tag_mapping': 'Permisos para acceder a la asignación de etiquetas de Cloud Cost Management.',
  'organization:credential': 'Permisos para gestionar las credenciales de la organización.',
  'organization:environment': 'Permisos para listar información de entornos a través de proyectos.',
  'organization:external_backend': 'Permisos Para administrar el acceso de configuración a los servicios externos utilizados por el cicloide, como los registros de proyectos o el infraView.',
  'organization:infra_policy': 'Las infrinaciones son verificaciones de políticas empresariales para su infraestructura en la nube.',
  'organization:inventory_resource': 'Permisos para listar recursos de inventario.',
  'organization:invitation': 'Permisos para administrar la capacidad de invitar a los usuarios a su organización.',
  'organization:kpi': 'Los KPI son métricas mostradas por proyecto, útil para obtener información sobre sus entornos y construir estados.',
  'organization:member': 'Un miembro es un usuario que puede ser parte de una o más organizaciones.',
  'organization:pipeline': 'Permisos para gestionar tareas de proyectos.',
  'organization:policy': 'Permisos para listar las políticas disponibles.',
  'organization:project': 'Permisos para gestionar el acceso a los proyectos. El proyecto es una colección de entornos creados alrededor de un sol Stack.',
  'organization:quota': 'Las quotas son una herramienta para limitar el uso de recursos (bare metal) al implementar entornos.',
  'organization:resource_pool': 'Los grupos de recursos son abstracciones lógicas que se utilizan para agrupar recursos por label, por lo que no tiene que crear quotas para cada servidor que tenga.',
  'organization:role': 'Permisos para gestionar los roles que contienen un conjunto de políticas.',
  'organization:service_catalog_source': 'Más comúnmente llamados repositorios de catálogos, estos son repositorios de Git que contienen Stacks para su organización.',
  'organization:service_catalog': 'Más comúnmente llamados Stacks, estas son "plantillas" utilizadas para describir procesos e infraestructuras.',
  'organization:team:member': 'Permisos para gestionar usuarios dentro de los @:(teams).',
  'organization:team': 'Los @:teams son grupos de usuarios donde se pueden dar permisos específicos.',
}
