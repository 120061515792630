export default {
  start: {
    content: [
      'Bonjour {name}, bienvenue à bord!',
      `Présentons quelques concepts de base sur Cycloid.io. Cela ne prendra pas plus de 2 minutes.`,
    ],
    button: 'Allons-y',
  },
  organizations: {
    title: 'Les organisations',
    content: [
      `Tout sur Cycloid.io se passe au sein d'une organisation. Vous pouvez inviter des personnes et organiser des membres en @:teams.`,
      `Vous pouvez définir des rôles et des politiques granulaires pour vous assurer que tout le monde a la bonne visibilité. Et vous pouvez définir des événements pour suivre ce qui se passe en temps réel.`,
      `Les organisations fournissent également une gestion centralisée des informations d'identification et vous permettent de gérer vos référents`,
    ],
  },
  catalogRepositories: {
    title: 'Référentiels de catalogue',
    content: [
      'Un référentiel de catalogue est une collection de stacks, enregistrée dans un dépot git. Vous pouvez considérer une stack comme un modèle pouvant couvrir plusieurs couches. Infrastructure, approvisionnement et monitoring par exemple.',
      `Nous entretenons une collection de stacks, basée sur des technologies open source populaires telles que Terraform, Ansible et Concourse. C'est le catalogue public Cycloid.`,
    ],
  },
  configRepository: {
    title: 'Référentiel de configuration',
    content: [
      `Si un référentiel de catalogue est un endroit pour stocker votre stack, un référentiel de configuration est un endroit pour stocker la configuration de votre stack: c'est simplement un référentiel git où vous pouvez pousser la configuration de votre pile pour le stockage.`,
      'Maintenant, vos référentiels et configurations de stack sont au même endroit, il est donc encore plus facile de les maintenir et de les personnaliser.',
    ],
  },
  privateCatalogs: {
    title: 'Catalogues privés',
    content: [
      `Vous pouvez également gérer vos propres collections de stacks pour les besoins particuliers de votre organisation. Vous pouvez écrire votre propre code et le pousser dans votre propre référentiel de catalogue.`,
    ],
  },
  projects: {
    title: 'Projets',
    content: [
      'Pour créer un projet, commencez par choisir une stack dans le catalogue public et définissez les environnements que vous souhaitez déployer. Les choix les plus utilisés sont Demo, Pre-prod ou Prod, mais vous pouvez les nommer comme vous le souhaitez.',
      `Nous utilisons des variables pour rendre les Stacks configurables pour chaque environnement. Remplacez simplement certaines d'entre elles si nécessaire.`,
    ],
  },
  pipelines: {
    title: `Soyez toujours au courant de l'état de votre application`,
    content: [
      `Utilisez des pipelines pour surveiller ce qui se passe. Un nouveau commit sur un référentiel, une release sur Github, un trigger périodique. Ce que vous voulez.`,
      `Suivez facilement chaque commit dans votre flux de travail et accédez aux détails de chaque étape. Les jobs peuvent être déclenchés automatiquement ou manuellement via l'interface. Vous pouvez également désactiver l'automatisation ou mélanger les deux approches pour avoir, par exemple, un flux de travail automatisé qui nécessite l'approbation humaine pour certaines étapes.`,
    ],
  },
  monitoring: {
    title: 'Et beaucoup plus',
    content: [
      `Vous pouvez planifier des environnements pour l'optimisation des coûts, inspecter les détails de votre infrastructure, obtenir un affichage visuel de tous les composants de votre projet ou obtenir des logs centralisés. La chronologie de l'événement retrace les actions et l'état de vos projets afin que vous puissiez toujours savoir ce qui se passe. Le tout dans la même interface.`,
      'Autres caractéristiques:',
    ],
    list: ['Vue centralisée des coûts', 'DevOps en tant que service', 'Gérer les organisations clients'],
  },
  end: {
    content: [
      `Ok, c'est tout pour cet aperçu. Vous devriez pouvoir atteindre les nuages maintenant (hum, désolé pour ça)`,
      'Si vous souhaitez plus de détails sur ce qui se cache sous le capot, consultez la documentation:',
    ],
    links: [
      'Visitez la documentation',
    ],
    button: 'Allez sur Cycloid.io',
  },
}
