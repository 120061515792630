export default {
  catalogRepositoryCountable: '@:CatalogRepositories | @:CatalogRepository | @:CatalogRepositories',
  catalogRepositoriesCountable: '@:catalogRepositoryCountable',
  catalogRepositoryCount: 'No @:CatalogRepositories | 1 @:CatalogRepository | {count} @:CatalogRepositories',
  catalogRepositoriesCount: '@:catalogRepositoryCount',
  configRepositoryCountable: '@:ConfigRepositories | @:ConfigRepository | @:ConfigRepositories',
  configRepositoriesCountable: '@:configRepositoryCountable',
  configRepositoryCount: 'No @:ConfigRepositories | 1 @:ConfigRepository | {count} @:ConfigRepositories',
  configRepositoriesCount: '@:configRepositoryCount',
  credentialCountable: '@:Credentials | @:Credential | @:Credentials',
  credentialsCountable: '@:credentialCountable',
  credentialCount: 'No @:Credentials | 1 @:Credential | {count} @:Credentials',
  credentialsCount: '@:credentialCount',
  environmentCountable: '@:Environments | @:Environment | @:Environments',
  environmentsCountable: '@:environmentCountable',
  environmentCount: 'No @:Environments | 1 @:Environment | {count} @:Environments',
  environmentsCount: '@:environmentCount',
  memberCountable: '@:Members | @:Member | @:Members',
  membersCountable: '@:memberCountable',
  memberCount: 'No @:Members | 1 @:Member | {count} @:Members',
  membersCount: '@:memberCount',
  pipelineCountable: '@:routes.pipeline | @:untranslated.pipeline | @:routes.pipeline',
  pipelinesCountable: '@:pipelineCountable',
  pipelineCount: 'No @:routes.pipeline | 1 @:untranslated.pipeline | {count} @:routes.pipeline',
  pipelinesCount: '@:pipelineCount',
  projectCountable: '@:Projects | @:Project | @:Projects',
  projectsCountable: '@:projectCountable',
  projectCount: 'No @:Projects | 1 @:Project | {count} @:Projects',
  projectsCount: '@:projectCount',
  roleCountable: '@:Roles | @:Role | @:Roles',
  rolesCountable: '@:roleCountable',
  roleCount: 'No @:Roles | 1 @:Role | {count} @:Roles',
  rolesCount: '@:roleCount',
  stackCountable: '@:Stacks | @:Stack | @:Stacks',
  stacksCountable: '@:stackCountable',
  stackCount: 'No @:Stacks | 1 @:Stack | {count} @:Stacks',
  stacksCount: '@:stackCount',
  teamCountable: '@:Teams | @:Team | @:Teams',
  teamsCountable: '@:teamCountable',
  teamCount: 'No @:Teams | 1 @:Team | {count} @:Teams',
  teamsCount: '@:teamCount',
}
