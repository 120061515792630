/* eslint-disable vue/no-unused-properties */

import { sanitize } from 'dompurify'

export default {
  install (Vue) {
    Vue.mixin({
      methods: {
        $sanitizeHtml (html) {
          return sanitize(html)
        },
      },
    })
  },
}
