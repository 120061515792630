import Vue from 'vue'
import * as Sentry from '@sentry/vue'

const isDevelop = process.env.NODE_ENV === 'development'

// Sentry configuration
if (!isDevelop) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_URL,
    environment: process.env.NODE_ENV,
    release: process.env.VUE_APP_VERSION,
    autoSessionTracking: false,
  })
}
