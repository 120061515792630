/** Get Object with useful view data to render a working link, if the domain is supported.
 *
 * @returns `Object` if domain is supported; `null` if not supported
 *  - `branch` {string}
 *  - `domain` {string}
 *  - `organization` {string}
 *  - `repo` {string}
 *  - `text` {string}
 *  - `provider` {object}
 *     - `name` {string}
 *     - `icon` {string}

 */
export default class RepoUrl {
  constructor ({ url = '', branch } = {}) {
    const [, domain, organization = '', repo = ''] = url.replace('.git', '').split(/[@:/]+/)

    if (domain !== 'github.com') return

    Object.assign(this, {
      branch,
      domain,
      organization,
      repo,
      provider: {
        name: 'Github',
        icon: 'fab fa-github',
      },
      text: branch
        ? `${organization}/${repo}@${branch}`
        : `${organization}/${repo}`
      ,
    })
  }

  getLink (dir = '') {
    if (!this) return null

    const directory = _.trimStart(dir, '/')
    const { branch, domain, organization, repo } = this
    const baseUrl = `https://${domain}/${organization}/${repo}`

    if (_.every([branch, directory])) return `${baseUrl}/tree/${branch}/${directory}`
    return branch
      ? `${baseUrl}/tree/${branch}`
      : baseUrl
  }
}
