import Vue from 'vue'
import { vuexMixin } from '@/utils/helpers'
import i18n from '@/utils/plugins/i18n'

export const initialState = {
  detail: null,
  member: null,
  errors: {
    assignMembers: [],
    team: [],
    teamMember: [],
    unassignMembers: [],
  },
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  team: (state) => state.detail,
}

const {
  actions: { GET_OWNER_OBJECT },
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  GET_OWNER_OBJECT,

  async ASSIGN_TEAM_MEMBERS ({ commit, dispatch, state, rootGetters: { orgCanonical } }, { teamCanonical, usernames }) {
    commit('CLEAR_TEAM_ERRORS', 'assignMembers')
    const allErrors = []
    const successfullyAssignedUsernames = []

    const promises = usernames.map(async (username) => {
      const { errors } = await Vue.prototype.$cycloid.ydAPI.assignMemberToTeam(orgCanonical, teamCanonical, username) || {}
      if (errors) allErrors.push(...errors)
      else successfullyAssignedUsernames.push(username)
    })
    await Promise.allSettled(promises)

    if (!_.isEmpty(successfullyAssignedUsernames)) {
      const userNames = _.$getListFromArray(successfullyAssignedUsernames, { boldItems: true })
      const content = i18n.tc('alerts.success.team.members.assigned', successfullyAssignedUsernames.length, { teamName: state.detail.name, userNames })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    if (!_.isEmpty(allErrors)) commit('SET_ERRORS', { key: 'assignMembers', errors: allErrors })
  },

  async CREATE_TEAM ({ commit, dispatch, rootGetters: { orgCanonical } }, { team, $router }) {
    commit('CLEAR_TEAM_ERRORS', 'team')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.createTeam(orgCanonical, team) || {}
    if (data) {
      commit('SET_TEAM', data)
      const content = i18n.t('alerts.success.team.created', { teamName: team.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
      if ($router) $router.replace({ name: 'team', params: { orgCanonical, teamCanonical: data.canonical } })
    }
    if (errors) commit(`SET_ERRORS`, { key: 'team', errors })
  },

  async DELETE_TEAM ({ commit, dispatch, rootGetters: { orgCanonical } }, { team, $router }) {
    commit('CLEAR_TEAM_ERRORS', 'team')
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteTeam(orgCanonical, team.canonical) || {}
    if (errors) commit('SET_ERRORS', { key: 'team', errors })
    else {
      if ($router) $router.push({ name: 'teams' })
      commit('RESET_TEAM_STATE')
      const content = i18n.t('alerts.success.team.deleted', { teamName: team.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
  },

  async GET_TEAM ({ commit, rootGetters: { orgCanonical } }, { teamCanonical }) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getTeam(orgCanonical, teamCanonical) || {}
    if (data) commit('SET_TEAM', data)
    if (errors) commit('SET_ERRORS', { key: 'team', errors })
  },

  async GET_TEAM_MEMBER ({ commit, rootGetters: { orgCanonical } }, { teamCanonical, id }) {
    commit('CLEAR_TEAM_ERRORS', 'teamMember')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getTeamMember(orgCanonical, teamCanonical, id) || {}
    if (data) commit('SET_TEAM_MEMBER', data)
    if (errors) commit('SET_ERRORS', { key: 'teamMember', errors })
  },

  async UNASSIGN_TEAM_MEMBERS ({ commit, dispatch, state, rootGetters: { orgCanonical } }, { teamCanonical, members }) {
    commit('CLEAR_TEAM_ERRORS', 'unassignMembers')
    const allErrors = []
    const successfullyRemovedUsernames = []

    const promises = members.map(async ({ id, username }) => {
      const { errors } = await Vue.prototype.$cycloid.ydAPI.unassignMemberFromTeam(orgCanonical, teamCanonical, id) || {}
      if (errors) allErrors.push(...errors)
      else successfullyRemovedUsernames.push(username)
    })
    await Promise.allSettled(promises)

    const userNames = _.$getListFromArray(successfullyRemovedUsernames, { boldItems: true })
    const content = i18n.tc('alerts.success.team.members.unassigned', successfullyRemovedUsernames.length, { teamName: state.detail?.name, userNames })
    if (!_.isEmpty(successfullyRemovedUsernames)) dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    if (!_.isEmpty(allErrors)) commit('SET_ERRORS', { key: 'unassignMembers', errors: allErrors })
  },

  async UPDATE_TEAM ({ commit, dispatch, rootGetters: { orgCanonical } }, { team, successMessage }) {
    commit('CLEAR_TEAM_ERRORS', 'team')
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.updateTeam(orgCanonical, team) || {}
    if (data) {
      commit('SET_TEAM', await dispatch('GET_OWNER_OBJECT', { data, oldVal: team }))
      const content = successMessage || i18n.t('alerts.success.team.updated', { teamName: team.name })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
    }
    if (errors) commit(`SET_ERRORS`, { key: 'team', errors })
  },
}

export const mutations = {
  CLEAR_TEAM_ERRORS: CLEAR_ERRORS,
  RESET_TEAM_STATE: RESET_STATE,
  SET_ERRORS,

  SET_TEAM (state, team) {
    Vue.set(state, 'detail', team)
  },

  SET_TEAM_MEMBER (state, member) {
    Vue.set(state, 'member', member)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
