import Vue from 'vue'
import { vuexMixin } from '@/utils/helpers'
import i18n from '@/utils/plugins/i18n'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment

export const initialState = {
  profile: null,
  errors: {
    profile: [],
    resetPassword: [],
  },
  guide: {
    presentationCompleted: false,
    tour: {
      currentStep: 0,
      completed: true,
    },
  },
}
const STATE = _.cloneDeep(initialState)

const GETTERS = {
  email: (state) => _.$get(state.profile, 'emails', []).find(({ purpose }) => purpose === 'primary')?.email || null,
  isCycloidEmployee: (state) => /^[A-Za-z0-9._%+-]+@cycloid\.io$/.test(_.find(state.profile.emails, ['purpose', 'primary'])?.email),
  isTourInProgress: (state) => !state.guide.tour.completed && state.guide.tour.currentStep > 0,
}

const {
  mutations: { CLEAR_ERRORS, RESET_STATE, SET_ERRORS },
} = vuexMixin(initialState)

export const actions = {
  async GET_PROFILE ({ commit, dispatch, getters }, jwtUser = {}) {
    const { data, errors } = await Vue.prototype.$cycloid.ydAPI.getUserAccount() || {}
    if (data) {
      const { guide, ...profile } = data
      commit('SET_PROFILE', { ...jwtUser, ...profile })
      commit('i18n/CHANGE_LOCALE', profile.locale, { root: true })
      if (!getters.isTourInProgress) commit('SET_GUIDE', guide)
    }
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
  },

  async UPDATE_PROFILE ({ commit, dispatch, rootState: { customers }, rootGetters: { orgCanonical } }, { profile, i18nProp = 'updated' }) {
    commit('CLEAR_USER_ERRORS', 'profile')
    const { data = {}, errors } = await Vue.prototype.$cycloid.ydAPI.updateUserAccount(profile) || {}
    const { guide, ...returnedProfile } = data
    if (!_.isEmpty(returnedProfile)) {
      const organizationCanonical = _.$get(customers, 'scopes[0].canonical', orgCanonical)
      const query = {
        ...(organizationCanonical ? { organization_canonical: organizationCanonical } : {}),
        ...(_.get(customers, 'scopes[1].canonical') ? { child_canonical: _.get(customers, 'scopes[1].canonical') } : {}),
      }
      await dispatch('auth/REFRESH_TOKEN', { query }, { root: true })
      commit('SET_PROFILE', { ...profile, ...returnedProfile })
      dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t(`alerts.success.profile.${i18nProp}`) }, { root: true })
    }
    if (errors) commit('SET_USER_ERRORS', { key: 'profile', errors })
  },

  async DELETE_ACCOUNT ({ dispatch, getters: { email } }, { $router }) {
    const { errors } = await Vue.prototype.$cycloid.ydAPI.deleteUserAccount() || {}
    if (!_.isEmpty(errors)) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
    else {
      dispatch('auth/LOGOUT', null, { root: true })
      $router.replace({ name: 'login', query: { action: 'accountDeleted', email } })
    }
  },

  async EMAIL_VERIFICATION_RESEND ({ dispatch, commit }, { email }) {
    const { errors } = await Vue.prototype.$cycloid.ydAPI.resendVerifyEmail({ email }) || {}
    if (errors) return commit('SET_USER_ERRORS', { key: 'emailVerificationResend', errors })

    dispatch('alerts/SHOW_ALERT', { type: 'success', content: i18n.t(`alerts.success.profile.email.verification.sent`) }, { root: true })
  },

  async RESET_PASSWORD ({ commit }, { email }) {
    commit('CLEAR_USER_ERRORS', 'resetPassword')

    const { errors } = await Vue.prototype.$cycloid.ydAPI.passwordResetReq(email) || {}
    if (errors) commit('SET_ERRORS', { key: 'resetPassword', errors })
  },

  async COMPLETE_PRESENTATION ({ commit, dispatch, state }) {
    commit('SET_PRESENTATION_COMPLETE')

    const { errors } = await Vue.prototype.$cycloid.ydAPI.updateUserGuide(state.guide) || {}
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
  },

  async COMPLETE_TOUR ({ commit, dispatch, state }) {
    commit('SET_TOUR_COMPLETE')

    const { errors } = await Vue.prototype.$cycloid.ydAPI.updateUserGuide(state.guide) || {}
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
  },

  async RESET_TOUR ({ commit, dispatch, state }) {
    commit('SET_TOUR_STEP', 0)
    commit('CLEAR_TOUR_COMPLETE')

    const { errors } = await Vue.prototype.$cycloid.ydAPI.updateUserGuide(state.guide) || {}
    if (errors) dispatch('alerts/SHOW_ALERT', { type: 'error', content: errors }, { root: true })
  },
}

export const mutations = {
  CLEAR_USER_ERRORS: CLEAR_ERRORS,
  RESET_USER_STATE: RESET_STATE,
  SET_USER_ERRORS: SET_ERRORS,

  SET_PROFILE (state, profile) {
    if (_.isEmpty(profile.locale)) profile.locale = i18n.locale
    if (_.$get(profile, 'locale', 'en') === 'en') moment.locale('en_gb')
    if (_.has(profile, 'country')) {
      profile.country_code = profile.country.code
      delete profile.country
    }
    window.dataLayer?.push({ userLang: profile.locale })
    Vue.set(state, 'profile', _.cloneDeep(profile))
    localStorage.setItem(LSK.PROFILE, JSON.stringify(profile))
  },

  SET_GUIDE (state, guide) {
    delete guide.tutorial
    delete guide.stackcraftPresentationCompleted
    Vue.set(state, 'guide', { ...state.guide, ...guide })
  },

  RESTORE_PROFILE (state) {
    if (!_.isEqual(state.profile, initialState.profile)) {
      window.dataLayer?.push({ userLang: state.profile.locale })
      return localStorage.setItem(LSK.PROFILE, JSON.stringify(state.profile))
    }
    const profile = localStorage.getItem(LSK.PROFILE)
    if (_.isEmpty(profile)) return
    Vue.set(state, 'profile', JSON.parse(profile))
    window.dataLayer?.push({ userLang: state.profile.locale })
  },

  CLEAR_PROFILE (state) {
    window.dataLayer?.push({ userLang: null })
    Vue.set(state, 'profile', _.cloneDeep(initialState.profile))
    localStorage.removeItem(LSK.PROFILE)
  },

  SET_PRESENTATION_COMPLETE (state) {
    Vue.set(state.guide, 'presentationCompleted', true)
  },

  CLEAR_PRESENTATION_COMPLETE (state) {
    Vue.set(state.guide, 'presentationCompleted', false)
  },

  SET_TOUR_STEP (state, step) {
    Vue.set(state.guide.tour, 'currentStep', step)
  },

  SET_TOUR_COMPLETE (state) {
    Vue.set(state.guide.tour, 'completed', true)
  },

  CLEAR_TOUR_COMPLETE (state) {
    Vue.set(state.guide.tour, 'completed', false)
  },
}

export {
  GETTERS as getters,
  STATE as state,
}

export default {
  namespaced: true,

  state: STATE,
  getters: GETTERS,
  actions,
  mutations,
}
