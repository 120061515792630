const size = 10
const rect = {
  fill: '#ffffff',
  stroke: '#8f8f8f',
  strokeWidth: 1,
  refWidth: size,
  refHeight: size,
  refX: size / -2,
  refY: size / -2,
}
const line = {
  stroke: 'rgb(37,52,73)',
  strokeDasharray: '0',
  strokeWidth: 2,
  fill: 'none',
  sourceMarker: {
    type: 'path',
    d: 'M 0 0 0 0',
    stroke: 'none',
  },
  targetMarker: {
    type: 'path',
    d: 'M 10 -5 0 0 10 5 z',
    stroke: 'none',
  },
}
const connector = {
  name: 'rounded',
}
const labels = []

export const meta = {
  rect,
  line,
  connector,
  labels,
}

export function defineCustomLink (joint) {
  joint.shapes.standard.Link.define('custom.Link', {
    connector,
    labels,
    attrs: {
      line,
    },
  }, {
    defaultLabel: {
      attrs: {
        rect,
      },
    },
  })
}

export default function defineCustomLinks ({ $joint }) {
  defineCustomLink($joint)
}
