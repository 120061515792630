import Vue from 'vue'
import './storageKeys'

// vendor plugins
import VueClipboard from './vue-clipboard2'
import Vue2Crumbs from 'vue-2-crumbs'
import BackToTop from 'vue-backtotop'
import VirtualList from 'vue-virtual-scroll-list'
import Vuelidate from 'vuelidate'
import './sentry'
import './fontawesome-setup'

// vendor styles
import 'rappid/dist/rappid.min.css'
import 'devicon/devicon.min.css'

// cycloid plugins
import browserTabTitle from './browser-tab-title'
import clickaway from './clickaway'
import DateFns from './date-fns' // eslint-disable-line no-restricted-imports
import docsUrl from './docs-url'
import ECharts from './echarts'
import evaluateUserActions from './evaluate-user-actions'
import findTranslation from './find-translation'
import fixAutofillRendering from './fix-autofill-rendering'
import getSlug from './get-slug'
import globalComponents from './global-components'
import globalMapGetters from './global-mapgetters'
import GoogleTagManager from './google-tag-manager'
import hasRightsTo from './has-rights-to'
import header from './header'
import hoverSiblings from './hover-siblings'
import isCreationRoute from './is-creation-route'
import isDevMode from './is-dev-mode'
import Lodash from './lodash'
import Rappid from './rappid'
import resetData from './reset-data'
import resizeObserver from './resize-observer'
import ResizeSplitPane from './resize-split-pane'
import sanitizeHtml from './sanitize-html'
import sanitizeUrl from './sanitize-url'
import shortid from './shortid'
import SimpleBar from './simplebar-vue'
import toggle from './toggle'
import vmdEditor from './vmd-editor'
import cycloidPlugin from './vue-cycloid-plugin'
import VueShowdown from './vue-showdown'
import wrapChildrenContentsInLink from './wrap-children-contents-in-link'

/** Plugins to be applied before any others */
const priorityPlugins = [
  isDevMode,
]

const plugins = [
  ...priorityPlugins,
  BackToTop,
  ECharts,
  VueClipboard,
  Vuelidate,
  VirtualList,
  Rappid,
  ResizeSplitPane,
  Lodash,
  SimpleBar,
  Vue2Crumbs,
  VueShowdown,
  GoogleTagManager,
  DateFns,
  // cycloid plugins
  browserTabTitle,
  clickaway,
  cycloidPlugin,
  fixAutofillRendering,
  docsUrl,
  findTranslation,
  getSlug,
  globalComponents,
  globalMapGetters,
  hasRightsTo,
  header,
  isCreationRoute,
  resetData,
  sanitizeHtml,
  sanitizeUrl,
  shortid,
  toggle,
  resizeObserver,
  evaluateUserActions,
  hoverSiblings,
  vmdEditor,
  wrapChildrenContentsInLink,
]

plugins.forEach((plugin) => {
  const { name, options } = plugin
  if (!!name && !!options) Vue.use(name, options)
  else Vue.use(plugin)
})
